import React from 'react';
import { getTableRowCheckBox } from '../../../utils/table';
import {
  returnUserReadableDate,
  returnUserReadableDateTime,
} from '../../../utils/datetime';
import { Image } from '@themesberg/react-bootstrap';

export const productInventoryColumns = (onRowSelect) => {
  return [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'isEnabled',
      text: 'store Enabled',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'storeCode',
      text: 'storeCode',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'totalStock',
      text: 'total',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'safety',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inProcess',
      text: 'inProcess',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inBlocked',
      text: 'inBlocked',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'onHold',
      text: 'onHold',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'categorySafetyStock',
      text: 'categorySafety',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'available',
      text: 'available',
      formatter: (cell, row) => {
        // const totalStock = row?.totalStock ?? 0;
        // const onlineStock = row?.onlineStock ?? 0;
        // const safetyStock = row?.safetyStock ?? 0;
        // const inProcess = row?.inProcess ?? 0;
        // const onHold = row?.onHold ?? 0;
        // const blockedStock = row?.inBlocked ?? 0;
        return <div className="d-block">{cell}</div>;
      },
    },
  ];
};

export const inventoryColumnsStoreGuy = () => {
  return [
    {
      dataField: 'images',
      text: 'image',
      formatter: (cell, row) => {
        return (
          <img
            style={{
              width: 50,
              height: 30,
              objectFit: 'cover',
            }}
            className="d-block"
            src={cell}
          />
        );
      },
    },
    {
      dataField: 'productTitle',
      text: 'Product Title',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'productCategory',
      text: 'Category',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'vanNo',
      text: 'vanNo',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'totalStock',
      text: 'total',
      formatter: (cell, row) => {
        return <div className="d-block">{row?.totalStock}</div>;
      },
    },
    {
      dataField: 'safetyStock',
      text: 'safety',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inProcess',
      text: 'inProcess',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'inBlocked',
      text: 'inBlocked',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'onHold',
      text: 'onHold',
      formatter: (cell, row) => {
        return <div className="d-block">{cell || 0}</div>;
      },
    },
    {
      dataField: 'available',
      text: 'available',
      formatter: (cell, row) => {
        // const totalStock = row?.totalStock ?? 0;
        // const onlineStock = row?.onlineStock ?? 0;
        // const safetyStock = row?.safetyStock ?? 0;
        // const inProcess = row?.inProcess ?? 0;
        // const onHold = row?.onHold ?? 0;
        // const blockedStock = row?.inBlocked ?? 0;
        return <div className="d-block">{cell}</div>;
      },
    },
  ];
};

export const cardDataColumns = () => {
  const columns = [
    {
      dataField: 'storeCode',
      text: 'storeCode',
      formatter: (cell, row) => {
        return <div className="d-block">{cell}</div>;
      },
    },
    {
      dataField: 'distance',
      text: 'Distance',
      formatter: (cell, row) => {
        return <div className="d-block">{cell.slice(1)}</div>;
      },
    },
    {
      dataField: 'maxQuantity',
      text: 'StockLevel',
      formatter: (cell, row) => {
        return <div className="d-block">{cell + ' units'}</div>;
      },
    },
  ];
  return columns;
};

export const inventorySyncColumns = (handleShowInfoModal) => {
  const columns = [
    {
      dataField: 'updatedTime',
      text: 'Date',
      formatter: (cell) => <div>{returnUserReadableDate(cell)}</div>,
    },
    {
      dataField: 'updatedTime',
      text: 'Time',
      formatter: (cell) => (
        <div>{returnUserReadableDateTime(cell).split(',')[2]}</div>
      ),
    },
    {
      dataField: 'storeUpdated',
      text: 'Success Stores',
      formatter: (cell, row) => (
        <div className="d-block text-center">
          {row?.updateType === 'Full Update' ||
          row?.updateType === 'Full Manual Update' ? (
            <>
              {cell?.length > 0 && (
                <div className="fw-bold">{`${cell.length} success`}</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'storeNotUpdated',
      text: 'Failed Stores',
      formatter: (cell, row) => (
        <div className="d-block text-center">
          {row?.updateType === 'Full Update' ||
          row?.updateType === 'Full Manual Update' ? (
            <>
              {cell?.length > 0 && (
                <div
                  className="fw-bold"
                  style={{ cursor: 'pointer', border: '2px solid red' }}
                  onClick={() => handleShowInfoModal(cell, 'Failed Stores')}
                >{`${cell.length} failed`}</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'requestedBrands',
      text: 'Requested Brands',
      formatter: (cell, row) => {
        const allBrands = [
          ...(row?.successBrand || []),
          ...(row?.failedBrands || []),
        ];

        return (
          <div className="d-block text-center">
            {row?.updateType === 'Full Manual Update' ||
            row?.updateType === 'Partial Manual update' ? (
              <>
                {allBrands?.length > 0 && (
                  <div className="fw-bold">{`${allBrands.length} requested`}</div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'successBrand',
      text: 'Received Brands',
      formatter: (cell, row) => (
        <div className="d-block text-center">
          {row?.updateType === 'Full Manual Update' ||
          row?.updateType === 'Partial Manual update' ? (
            <>
              {cell?.length > 0 && (
                <div className="fw-bold">{`${cell.length} received`}</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'failedBrands',
      text: 'Failed Brands',
      formatter: (cell, row) => (
        <div className="d-block text-center">
          {row?.updateType === 'Full Manual Update' ||
          row?.updateType === 'Partial Manual update' ? (
            <>
              {cell?.length > 0 && (
                <div
                  className="fw-bold"
                  style={{ cursor: 'pointer', border: '2px solid red' }}
                  onClick={() => handleShowInfoModal(cell, 'Failed Brands')}
                >{`${cell.length} failed`}</div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'updateType',
      text: 'Sync Type',
    },
    {
      dataField: 'status',
      text: 'Sync Status',
      formatter: (cell, row) => {
        const status =
          row?.updateType === 'Full Manual Update' ||
          row?.updateType === 'Partial Manual update'
            ? cell
            : 'success';
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              width: 'fit-content',
              minWidth: '90px',
              height: '25px',
              padding: '10px',
              backgroundColor:
                cell === 'failed'
                  ? '#FDDDDD'
                  : cell === 'started'
                  ? '#FDEDD9'
                  : '#D4F7E8',
            }}
          >
            <div className="fw-bold">{status}</div>
          </div>
        );
      },
    },
    {
      dataField: 'count',
      text: 'Count',
    },
  ];
  return columns;
};

export const infoModalColumns = (columnName) => {
  const colums = [
    {
      dataField: columnName,
      text: columnName,
    },
  ];
  return colums;
};
