import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableOnly from '../scheme-management/Components/IndividualScheme/TableOnly';
import ActionButtonWithIcons from '../../components/common/ActionButtonWithIcons';
import {
  getDeliveryRateList,
  updateDeliveryRate,
} from '../../parse-functions/payments';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../components/common/confirmationModal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import BreadCrumbTitle from '../scheme-management/Components/common/BreadCrumbTitle';

const PerDeliveryRateEdit = () => {
  const [tableData, setTableData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const options = [{ label: 'Edit', icon: faEdit }];
  const columns = [
    {
      dataField: 'zone',
      text: 'Zone',
      style: { display: 'flex', justifyContent: 'start', paddingLeft: '3rem' },
      headerClasses: 'ps-5',
    },
    {
      dataField: 'rate',
      text: 'Rate',
    },
    {
      dataField: 'action',
      text: 'action',
      style: { display: 'flex', justifyContent: 'end', paddingRight: '3rem' },
      headerClasses: 'text-end pe-5',
      formatter: (cell, row) => {
        return (
          <ActionButtonWithIcons
            options={options}
            onActionClick={(option) => handleSelectedRowUpdate(option, row)}
          />
        );
      },
    },
  ];

  const handleSelectedRowUpdate = (option, row) => {
    setShowEditModal(true);
    setSelectedRow(row);
    setConfirmationModalProps([
      {
        type: 'text',
        title: t('Delivery Rate'),
        value: '',
        placeholder: 'Enter Rate',
      },

      {
        headerIcon: <FontAwesomeIcon icon={faEdit} />,
      },
    ]);
  };

  const handleOnConfirmClick = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await updateDeliveryRate({
        id: selectedRow.objectId,
        rate: parseInt(confirmationModalProps[0].value),
      });
      await fetchData();
      setShowEditModal(false);
      setSelectedRow({});
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Succesfully Updated!'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to update rate!'),
        })
      );
    }
  };

  const fetchData = async () => {
    const data = await getDeliveryRateList();
    setTableData(data);
  };

  useEffect(() => {
    if (tableData.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div>
      <div className="d-block mb-4 md-0">
        <BreadCrumbTitle breadcrumbs={[{ title: 'Edit Delivery Rate' }]} />
      </div>
      <TableOnly
        data={tableData || []}
        columns={columns}
        id={'editDeliveryRateTable'}
      />
      <ConfirmationModal
        showModal={showEditModal}
        onConfirm={handleOnConfirmClick}
        closeModal={() => setShowEditModal(false)}
        modalBody={t('Edit delivery Rate for the Zone')}
        modalTitle={t('Edit delivery Rate')}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </div>
  );
};

export default PerDeliveryRateEdit;
