import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';

const AlreadyModal = ({ show, handleClose, onCancel, apiDownloadUrl,onSuccess }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      // Simulate API call to get the download URL
      const response = await fetch(apiDownloadUrl); // Replace with your actual API endpoint
      if (!response.ok) throw new Error('Failed to fetch download link');

      const { downloadUrl, fileName } = await response.json(); // Assuming API returns { downloadUrl, fileName }

      // Trigger file download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName || 'data-download'; // Fallback file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
      handleClose(); // Close modal after download
    } catch (error) {
      console.error('Error during download:', error);
      setIsDownloading(false);
      alert('Failed to download the file. Please try again.');
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      style={{
        textAlign: 'center',
      }}
    >
      <Modal.Body
        style={{
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={'xl'}
          style={{ marginBlock: '10px', color: '#FF9900' }}
        />
        <h5
          style={{
            fontWeight: '600',
            fontSize: '22px',
            color: '#333',
            marginBottom: '15px',
            marginBlock: '10px',
          }}
        >
          Request to download already exists
        </h5>
        <p
          style={{
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.6',
            marginBottom: '20px',
          }}
        >
          We found a request to download the following report already exist . Would you like to download it.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '50px',
            width: '90%',
          }}
        >
          <Button
            variant="link"
            className="text-gray"
            style={{
              padding: '10px 0',
              width: '30%',
              fontSize: '14px',
              fontWeight: '500',
              backgroundColor: '#F0F0F0',
              borderRadius: '4px',
            }}
            onClick={onCancel || handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="dark"
            style={{
                padding: '10px 0',
                width: '30%',
                fontSize: '14px',
                fontWeight: '500',
                color: '#fff',
                backgroundColor: !isDownloading ? '#444444' : '#ccc', // Gray background when inactive
                borderRadius: '4px',
              cursor: isDownloading ? 'not-allowed' : 'pointer',
            }}
            onClick={onSuccess}
            disabled={isDownloading}
          >
            {isDownloading ? 'Downloading...' : 'Go To Downloads'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AlreadyModal;

