import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faCheck, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

const InvoiceDataModal = ({
  showModal,
  closeModal,
  modalTitle,
  invoices = [],
  initialData = [],
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(() =>
    initialData.length > 0
      ? initialData
      : [
          {
            id: Date.now(),
            key: '',
            paymentType: 'deliveryPay',
            amount: '',
            remarks: '',
          },
        ]
  );

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (showModal) {
      setFormData(
        initialData.length > 0
          ? initialData
          : [
              {
                id: Date.now(),
                key: '',
                paymentType: 'deliveryPay',
                amount: '',
                remarks: '',
              },
            ]
      );
      setErrors({});
    }
  }, [showModal, initialData]);

  const handleAddRow = () => {
    setFormData((prev) => [
      ...prev,
      {
        id: Date.now(),
        key: '',
        paymentType: 'deliveryPay',
        amount: '',
        remarks: '',
      },
    ]);
  };

  const getAvailableInvoices = (currentRowId) => {
    const selectedKeys = formData
      .filter((item) => item.id !== currentRowId)
      .map((item) => item.key)
      .filter(Boolean);
    // console.log(selectedKeys, formData);
    // console.log(invoices);
    return invoices.filter(
      (invoice) => !selectedKeys.includes(invoice) // Exclude keys already selected in other rows
    );
  };

  const handleInputChange = (id, field, value) => {
    setFormData((prev) =>
      prev.map((item) =>
        item.id === id
          ? {
              ...item,
              [field]: field === 'amount' ? Number(value) || '' : value,
            }
          : item
      )
    );

    setErrors((prev) => {
      const { [`${field}_${id}`]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleDeleteRow = (id) => {
    if (formData.length > 1) {
      setFormData((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const validate = () => {
    const newErrors = {};

    formData.forEach((row) => {
      if (!row.key) {
        newErrors[`key_${row.id}`] = t('Key is required');
      }
      if (!row.paymentType) {
        newErrors[`paymentType_${row.id}`] = t('Payment Type is required');
      }
      if (!row.amount || Number(row.amount) <= 0) {
        newErrors[`amount_${row.id}`] = t('Amount must be greater than 0');
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = () => {
    if (!validate()) return;
    handleSubmit(formData);
    closeModal();
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Body>
        <div className="mb-5 mt-2">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="fs-5 text-center flex-grow-1">{t(modalTitle)}</div>
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </div>

          {formData.map((row) => (
            <Row key={row.id} className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>{t('Customer')}</Form.Label>
                  <EditableDropDown
                    options={arrayToDropdownOptions(
                      getAvailableInvoices(row.id)
                    )}
                    value={row.key}
                    onChange={(option) =>
                      handleInputChange(row.id, 'key', option.value)
                    }
                    placeholder={t('Select Customer')}
                    backgroundColor={'#FFF'}
                    optionColor={'rgba(240, 240, 240, 1)'}
                  />
                  {errors[`key_${row.id}`] && (
                    <div className="text-danger">{errors[`key_${row.id}`]}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>{t('Payment Type')}</Form.Label>
                  <EditableDropDown
                    onChange={(option) =>
                      handleInputChange(row.id, 'paymentType', option.value)
                    }
                    value={row.paymentType}
                    options={[
                      {
                        label: 'Delivery Pay',
                        value: 'deliveryPay',
                      },
                    ]}
                    placeholder={t('Select Payment Type')}
                    backgroundColor={'#FFF'}
                    optionColor={'rgba(240, 240, 240, 1)'}
                  />
                  {errors[`paymentType_${row.id}`] && (
                    <div className="text-danger">
                      {errors[`paymentType_${row.id}`]}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{t('Amount')}</Form.Label>
                  <Form.Control
                    type="number"
                    value={row.amount}
                    onChange={(e) =>
                      handleInputChange(row.id, 'amount', e.target.value)
                    }
                    placeholder={t('Enter Amount')}
                  />
                  {errors[`amount_${row.id}`] && (
                    <div className="text-danger">
                      {errors[`amount_${row.id}`]}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group>
                  <Form.Label>{t('Remarks')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={row.remarks}
                    onChange={(e) =>
                      handleInputChange(row.id, 'remarks', e.target.value)
                    }
                    placeholder={t('Enter remarks')}
                  />
                </Form.Group>
              </Col>
              <Col md={1} className="d-flex align-items-center mt-4">
                <Button
                  variant="danger"
                  onClick={() => handleDeleteRow(row.id)}
                  disabled={formData.length === 1}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Button>
              </Col>
            </Row>
          ))}

          <Button onClick={handleAddRow} className="mt-3">
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp; {t('Add More')}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={closeModal}>
          <FontAwesomeIcon icon={faXmark} />
          &nbsp;&nbsp; {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={submitForm}>
          <FontAwesomeIcon icon={faCheck} />
          &nbsp;&nbsp; {t('Submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceDataModal;
