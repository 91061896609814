import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Form,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faRefresh,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import DateRangeSelector from '../../../components/common/DateRangeSelector';
import { returnRowBGColor } from '../../../utils/parcels';
import EmptyTable from '../../../components/common/EmptyTableView';
import Table from 'react-bootstrap-table-next';
import DropdownComponent from '../../../components/common/Dropdown';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { updateToastInfo } from '../../../actions/settings';
import { getColumns, orderTableColumns } from './utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { isBelongsToSameInvoice } from './components/utils';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { orderStatus, returnOrderStatus } from '../../../constants/orderStatus';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import ReplacementModal from './components/ReplacementModal';
import OrderListPage from '../../../components/common/OrderListPage';
import DynamicTabs from './components/DyanamicTabs';

const Delivered = () => {
  const type = orderStatus.delivered;
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const storeName = useSelector(
    (state) => state.user.preferences.storeName || ''
  );
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone
  );
  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [queryObject, setQueryObject] = useState({});
  const [queryParam , setQueryParam] = useState({})
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breadCrumbItems = arrayToBreadCrumbs([[t('Orders')], [t('delivered')]]);
  const [data, setData] = useState([]);
  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);

    const onRefreshClick = () => {}
    const handleTimeOut = async (id) => {
      try {
        const data = {
          id: [id],
          status: orderStatus.autoRejected,
        };
        // await updateOrders(data);
        // setOrdersData((prev) =>
        //   prev.filter(
        //     (order) =>
        //       order.isBiker || order.isHyperLocal || order.objectId !== id
        //   )
        // );
        // await fetchOrders();
      } catch (error) {
        messageDisplayer('danger', 'Failed to handle timeout', error.message);
      }
    };
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [showReplacementModal, setShowReplacementModal] = useState(false);
  const bulkActionOptions = [
    {
      label: 'Return Request',
      value: 'Requested',
    },
    {
      label: 'Replacement Request',
      value: 'Replace',
    },
  ];

  useEffect(() => {
    const newQueryObject = {}
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });
  
    if (Object.keys(newQueryObject).length > 0) {
      setQueryObject(newQueryObject);
    }
  }, [location.search]);
  useEffect(() => {
    if (Object.keys(queryObject).length > 0) {
      setQueryParam({ ...queryObject, status: type ,  });
    }
  }, [queryObject, isAdmin]);

  useEffect(() => {

    const toSearch = Object.values(queryParam).filter((each) => each && each != type)
    if (!isAdmin || (toSearch?.length > 0 &&
  queryParam && Object.keys(queryParam || {}).length > 0)) {
      fetchOrders();
    }else{
      setData([])
    }
  }, [queryParam]);

  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order`,{...queryParam, storeCode : isAdmin ? queryParam?.storeCode || '' : storeName , zone : isAdmin ?  Array.isArray(queryParam?.zone) ? queryParam?.zone?.join(',') : queryParam?.zone || '' : zone,status:type}
      );
      setData(res);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Orders fetched successfully'),
          message: t('Orders fetched successfully'),
        })
      );
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };


  const getWarehouseDropdownOptions = () => {
    return returnWarehouseDropdownOptions(otherWarehouses, false);
  };

  const handleSubmit = async (startDate, endDate) => {
    try {
      setInputs((prev) => ({ ...prev, startDate, endDate }));
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.ORDER_DATE_URL,
        {
          storeCode:
            isAdmin || inputs?.storeName ? inputs?.storeName : storeName,
          status: orderStatus.delivered,
          startDate,
          endDate,
        }
      );
      setData(res);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to fetch Orders'),
          message: t(error.message),
        })
      );
    }
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = data.find((item) => item.objectId === objectId);
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = (bulkActionSelectedOption) => {
    if (selectedOrders?.length > 0) {
      const ans = isBelongsToSameInvoice(selectedOrders);
      if (!ans) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed to update Orders'),
            message: t('please select same invoiced orders'),
          })
        );
        return;
      }
      switch (bulkActionSelectedOption) {
        case bulkActionOptions[0].value: {
          setConfirmationModalProps([
            {
              type: 'textArea',
              title: 'Remarks',
              value: '',
            },
          ]);
          setShowModal(true);
          break;
        }
        case bulkActionOptions[1].value: {
          setShowReplacementModal(true);
          break;
        }
        default: {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'danger',
              title: t('Select an Action'),
              message: t('Please select an action from the dropdown'),
            })
          );
          break;
        }
      }
    }
  };

  const handleModalOnConfirmClick = async ({ data }) => {
    console.log(data);
    const selectedOrdersData = {
      id: selectedOrders.map((order) => order.objectId),
      status: returnOrderStatus.REQUESETED,
      serviceType: returnOrderStatus.SERVICETYPE,
    };
    if (data) {
      selectedOrdersData.isReplacement = true;
      selectedOrdersData.replacementProduct = data;
    } else {
      selectedOrdersData.remarks = confirmationModalProps[0].value;
    }
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        apiCallConsts.ORDER_UPDATE_URL,
        selectedOrdersData
      );
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Update Successful!`,
          message: '',
        })
      );
      setShowModal(false);
      setShowReplacementModal(false);
      setSelectedOrders([]);
      setConfirmationModalProps([]);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: error.message,
        })
      );
    }
  };

  return (
    <>
      {/* <ToolkitProvider
        keyField="objectId"
        sort
        search={true}
        data={data || []}
        columns={orderTableColumns(
          onRowSelect,
          navigate,
          isAdmin ? bulkActionOptions : null,
          isAdmin
        )}
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: data?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                  <div className="d-block mb-4 mb-md-0">
                    <BreadCrumbComponent items={breadCrumbItems} />
                    <h4>{t(`${orderStatus.delivered} Orders`)}</h4>
                  </div>
                  <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                      <Button
                        onClick={fetchOrders}
                        variant="outline-primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faRefresh} />
                        &nbsp;&nbsp;{t('Refresh')}
                      </Button>
                      <ExcelDownloadReport
                        data={data || []}
                        fileName={`Delivered orders`}
                      />
                    </ButtonGroup>
                  </div>
                </div>
                <div className="table-settings mt-2 mb-2">
                  <Row className="justify-content-start">
                    <Col xs={12} md={6}>
                      <DateRangeSelector
                        onSubmit={handleSubmit}
                        filtersValue={{}}
                      />
                    </Col>
                    {isAdmin && (
                      <Col
                        className="d-flex justify-content-end"
                        xs={12}
                        md={6}
                      >
                        <Row className="align-items-center">
                          <Col xs={9}>
                            <DropdownComponent
                              width={300}
                              onChange={(option) =>
                                setInputs((prev) => ({
                                  ...prev,
                                  storeName: option.value,
                                }))
                              }
                              placeholder={t('Select/search store')}
                              options={getWarehouseDropdownOptions()}
                            />
                          </Col>
                          <Col xs={3}>
                            <Button
                              onClick={() =>
                                handleSubmit(inputs.startDate, inputs.endDate)
                              }
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </div>
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row
                        className={`d-flex ${
                          isAdmin
                            ? 'justify-content-between'
                            : 'justify-content-end'
                        }`}
                      >
                        {isAdmin && (
                          <Col xs={8} md={6} className="d-flex py-1">
                            <>
                              <Form.Group
                                id="substatus"
                                className="d-flex align-items-center flex-column"
                              >
                                <DropdownComponent
                                  onChange={(option) => {
                                    setBulkActionSelectedOption(option.value);
                                  }}
                                  width={300}
                                  disabled={selectedOrders?.length === 0}
                                  options={bulkActionOptions}
                                  placeholder={t('Select Action')}
                                />
                              </Form.Group>
                              <Button
                                style={{
                                  height: 37,
                                }}
                                size="sm"
                                onClick={handleSelectedRowsUpdate}
                                disabled={selectedOrders?.length === 0}
                                className="ms-3 btn btn-secondary"
                                variant="secondary"
                              >
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;&nbsp;{t('Update')}
                              </Button>
                            </>
                          </Col>
                        )}
                        <Col
                          xs={4}
                          md={4}
                          className="d-flex justify-content-md-end align-items-end py-1"
                        >
                          <Search.SearchBar
                            {...searchProps}
                            placeholder={t('Search any value in table here')}
                          />
                          <Search.ClearSearchButton {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>

                    <Table
                      id={'deliveredTable'}
                      rowClasses={(row) => {
                        const rowBGColor = returnRowBGColor(
                          row?.dashboardStatus ||
                            row?.mobileStatus ||
                            row?.status
                        );
                        return `${rowBGColor} border-bottom`;
                      }}
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                      sort={{
                        sortCaret: (order, column) => {
                          if (!order)
                            return (
                              <span>
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faSort} />
                              </span>
                            );
                          else if (order === 'asc')
                            return (
                              <span>
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faSortUp} />
                              </span>
                            );
                          else if (order === 'desc')
                            return (
                              <span>
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faSortDown} />
                              </span>
                            );
                          return null;
                        },
                      }}
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider> */}
      <DynamicTabs  activeTab={activeTab}
  setActiveTab = {setActiveTab}
  selectedOrders={selectedOrders}
  type = {type}
  ordersData={data}
  getColumns={getColumns}
  onRowSelect={onRowSelect}
  navigate={navigate}
  handleTimeOut={handleTimeOut}
  bulkActionOptions={bulkActionOptions}
  isAdmin={isAdmin}
  onRefreshClick={onRefreshClick}
  t={t}
  setTabsToRender={setTabsToRender}
  breadCrumbItems={breadCrumbItems}
  handleSelectedRowsUpdate={handleSelectedRowsUpdate}
  setOrdersData={data}
  setQueryParam={setQueryParam}
  tabsToRender={tabsToRender} />

      <ConfirmationModal
        showModal={showModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowModal(false);
          setConfirmationModalProps([]);
        }}
        modalBody={t(
          `Are you sure you want to change the status to Returns Requested?`
        )}
        modalTitle={t('Returns Requested')}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <ReplacementModal
        showModal={showReplacementModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowReplacementModal(false);
        }}
        selectedOrders={selectedOrders}
        type={'item'}
        modalBody={t(`Are you sure you want to raise a Replacement Request?`)}
      />
    </>
  );
};

export default Delivered;
