import React, { useMemo, useState, useCallback } from 'react';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../utils/sms/arrayToBreadCrumbs';
import { Form, Row, Col, Button } from '@themesberg/react-bootstrap';
import AsyncDropdown from '../../components/AsyncDropdown';
import {
  getAllTripNumbers,
  getTripDetailsByTripNumberFromServer,
  getTripInfoByTemposheetNumber,
} from '../../parse-functions/trips';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { updateToastInfo } from '../../actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import EditableDropDown from '../../components/common/EditableDropdown';
import {
  faCheck,
  faPlus,
  faRefresh,
  faUndo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateSpinnerState } from '../../actions/spinner';
import { createPaymentVoucher } from '../../parse-functions/payments';
import { debounce } from '../../utils/debounce';
import InvoiceDataModal from './Components/InvoiceDataModal';

const CreatePaymentVoucher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const userName = useSelector((state) => state?.user?.user?.username || '');

  // Main state
  const [formData, setFormData] = useState({
    tripNumber: '',
    paymentType: 'deliveryPay',
    amount: 0,
  });
  const [eachLine, setEachLine] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [total, setTotal] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  // Invoice related state
  const [invoiceData, setInvoiceData] = useState({
    invoices: {}, // Available invoices for each trip
    finalData: {}, // Selected invoices and amounts for each trip
  });

  const breadCrumbItems = arrayToBreadCrumbs([['payments'], ['add']]);

  // Memoized selected temposheets
  const selectedTemposheets = useMemo(() => {
    const selected = new Set();
    if (formData.tripNumber) selected.add(formData.tripNumber);
    eachLine.forEach((line) => {
      if (line.data.tripNumber) selected.add(line.data.tripNumber);
    });
    return selected;
  }, [formData.tripNumber, eachLine]);

  // Fetch trip numbers with debounce
  const fetchOptions = useCallback(
    debounce(async (inputValue) => {
      if (inputValue.length <= 1) return [];
      try {
        const res = await getAllTripNumbers({
          filter: inputValue.toUpperCase(),
        });
        const filtered = res.filter(
          (option) => !selectedTemposheets.has(option)
        );
        return arrayToDropdownOptions(filtered);
      } catch (error) {
        dispatch(
          updateToastInfo({
            show: true,
            type: t('danger'),
            title: t('failed to get trips!'),
            message: t(error.message),
          })
        );
        return [];
      }
    }, 300),
    [selectedTemposheets]
  );

  const fetchTripDetailsByTripNumber = async (tripNumber) => {
    try {
      dispatch(updateSpinnerState(true));
      // const response = await getTripDetailsByTripNumberFromServer(
      //   tripNumber,
      //   true
      // );
      const response = await getTripInfoByTemposheetNumber(tripNumber, true);
      // console.log(response);
      // console.log(response?.tripInfo);
      // console.log(response?.tripInfo?.parcelCalculation);
      const parcelCalculation =
        JSON.parse(response?.tripInfo?.parcelCalculation) || {};
      // console.log(parcelCalculation);
      setInvoiceData((prev) => ({
        ...prev,
        invoices: {
          ...prev.invoices,
          [tripNumber]: parcelCalculation
            ? [...new Set(Object.keys(parcelCalculation))]
            : [],
        },
        finalData: {
          ...prev.finalData,
          [tripNumber]: prev.finalData[tripNumber] || {
            invoices: [],
            finalAmount: 0,
            isTransfer: response?.tripInfo?.isTransfer || false,
          },
        },
      }));

      setSelectedTrip(tripNumber);
      setShowModal(true);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed to fetch trip data',
          message: error?.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };
  // console.log(invoiceData);
  const handleChange = async (key, value) => {
    if (key === 'tripNumber') {
      // Clear old trip data when a new trip is selected
      setInvoiceData((prev) => {
        const updatedInvoices = { ...prev.invoices };
        const updatedFinalData = { ...prev.finalData };

        // Remove old trip data
        if (formData.tripNumber) {
          delete updatedInvoices[formData.tripNumber];
          delete updatedFinalData[formData.tripNumber];
        }

        return {
          invoices: updatedInvoices,
          finalData: updatedFinalData,
        };
      });
      await fetchTripDetailsByTripNumber(value);
    }

    setFormData((prev) => ({
      ...prev,
      [key]: key === 'amount' ? Number(value) || 0 : value,
    }));
    setErrors((prev) => ({ ...prev, [key]: undefined }));
  };

  const handlePlusClick = () => {
    setEachLine((prev) => [
      ...prev,
      {
        id: Date.now(),
        data: { paymentType: 'deliveryPay', amount: 0 },
      },
    ]);
  };

  const handleChildChange = async (id, key, value) => {
    if (key === 'tripNumber') {
      await fetchTripDetailsByTripNumber(value);
    }

    setEachLine((prev) =>
      prev.map((each) =>
        each.id === id
          ? {
              ...each,
              data: {
                ...each.data,
                [key]: key === 'amount' ? Number(value) || 0 : value,
              },
            }
          : each
      )
    );

    setErrors((prev) => {
      const { [`${key}_${id}`]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleDelete = (id) => {
    setEachLine((prev) => prev.filter((each) => each.id !== id));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.tripNumber) {
      newErrors.tripNumber = t('Trip Number is required');
    }
    if (!formData.paymentType) {
      newErrors.paymentType = t('Payment Type is required');
    }
    if (formData.amount <= 0) {
      newErrors.amount = t('Amount is required and must be greater than 0');
    }

    eachLine.forEach((each) => {
      if (!each.data.tripNumber) {
        newErrors[`tripNumber_${each.id}`] = t('Trip Number is required');
      }
      if (!each.data.paymentType) {
        newErrors[`paymentType_${each.id}`] = t('Payment Type is required');
      }
      if (!each.data.amount || each.data.amount <= 0) {
        newErrors[`amount_${each.id}`] = t(
          'Amount is required and must be greater than 0'
        );
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInvoiceSubmit = (modalData) => {
    const tripTotal = modalData.reduce((acc, invoice) => {
      return acc + (Number(invoice?.amount) || 0);
    }, 0);

    setInvoiceData((prev) => ({
      ...prev,
      finalData: {
        ...prev.finalData,
        [selectedTrip]: {
          invoices: modalData,
          finalAmount: tripTotal,
          isTransfer: prev.finalData[selectedTrip]?.isTransfer || false,
        },
      },
    }));

    // Update form data or line item with new amount
    if (formData.tripNumber === selectedTrip) {
      setFormData((prev) => ({ ...prev, amount: tripTotal }));
    } else {
      setEachLine((prev) =>
        prev.map((line) =>
          line.data.tripNumber === selectedTrip
            ? { ...line, data: { ...line.data, amount: tripTotal } }
            : line
        )
      );
    }
  };

  const handleCalculate = () => {
    if (!validate()) return;

    const lineTotal = eachLine.reduce((acc, curr) => {
      return acc + (Number(curr.data.amount) || 0);
    }, 0);

    const finalTotal = lineTotal + (Number(formData.amount) || 0);
    setTotal(finalTotal);
    setIsDisabled(true);
    setShowCreate(true);
  };

  const handleCreatePayment = async () => {
    try {
      dispatch(updateSpinnerState(true));

      // const paymentData = [...eachLine.map((each) => each.data), formData];
      // console.log(invoiceData);
      // console.log(invoiceData.finalData);
      const response = await createPaymentVoucher({
        data: invoiceData.finalData,
        warehouse,
        totalAmount: total,
        userName,
      });

      // Reset form
      setEachLine([]);
      setFormData({
        tripNumber: '',
        paymentType: 'deliveryPay',
        amount: 0,
      });
      setErrors({});
      setTotal(0);
      setIsDisabled(false);
      setShowCreate(false);
      setInvoiceData({ invoices: {}, finalData: {} });

      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'Success',
          message: 'Voucher created successfully!',
        })
      );
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Failed!',
          message: error?.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const handleReset = () => {
    setIsDisabled(false);
    setShowCreate(false);
    setTotal(0);
  };

  const closeModal = () => {
    setInvoiceData((prev) => {
      const updatedFinalData = { ...prev.finalData };
      if (
        !updatedFinalData[selectedTrip]?.invoices?.length &&
        updatedFinalData[selectedTrip]?.finalAmount === 0
      ) {
        delete updatedFinalData[selectedTrip];
      }
      return {
        ...prev,
        finalData: updatedFinalData,
      };
    });
    setShowModal(false);
    setSelectedTrip('');
  };

  return (
    <div className="mt-1">
      <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbComponent items={breadCrumbItems} />
          <h4>{t('Create New Payment Voucher')}</h4>
        </div>
      </div>

      {/* Main Form */}
      <Row>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{t('Temposheet No.')}</Form.Label>
            <AsyncDropdown
              onChange={(option) => handleChange('tripNumber', option.value)}
              disabled={isDisabled}
              fetchOptions={fetchOptions}
              placeholder={t('Search/Type TripNumber')}
              backgroundColor={'#FFF'}
              optionColor={'rgba(240, 240, 240, 1)'}
              cacheOptions={false}
              // value={formData.tripNumber}
            />
            {errors.tripNumber && (
              <div className="text-danger">{errors.tripNumber}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{t('Payment Type')}</Form.Label>
            <EditableDropDown
              onChange={(option) => handleChange('paymentType', option.value)}
              value={formData.paymentType}
              disabled={true}
              options={[
                {
                  label: 'Delivery Pay',
                  value: 'deliveryPay',
                },
              ]}
              placeholder={t('Select Payment Type')}
            />
            {errors.paymentType && (
              <div className="text-danger">{errors.paymentType}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{t('Amount')}</Form.Label>
            <Form.Control
              type="number"
              value={
                invoiceData.finalData[formData.tripNumber]?.finalAmount || ''
              }
              style={{ backgroundColor: '#FFF' }}
              disabled={true}
              onChange={(e) => handleChange('amount', e.target.value)}
              placeholder={t('Enter Amount')}
            />
            {errors.amount && (
              <div className="text-danger">{errors.amount}</div>
            )}
          </Form.Group>
        </Col>
        <Col md={1} className="mt-4">
          <Button
            onClick={() => {
              setSelectedTrip(formData.tripNumber);
              setShowModal(true);
            }}
            disabled={!formData.tripNumber || isDisabled}
          >
            {t('Edit')}
          </Button>
        </Col>
        {/* <Col className="mt-4">
          <Button onClick={handlePlusClick} disabled={isDisabled}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp; {t('Add More')}
          </Button>
        </Col> */}
      </Row>

      {/* Additional Lines */}
      {eachLine.map((each) => (
        <Row key={each.id}>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Temposheet No.')}</Form.Label>
              <AsyncDropdown
                onChange={(option) =>
                  handleChildChange(each.id, 'tripNumber', option.value)
                }
                disabled={isDisabled}
                fetchOptions={fetchOptions}
                placeholder={t('Search/Type TripNumber')}
                backgroundColor={'#FFF'}
                optionColor={'rgba(240, 240, 240, 1)'}
                // value={each?.tripNumber}
              />
              {errors[`tripNumber_${each.id}`] && (
                <div className="text-danger">
                  {errors[`tripNumber_${each.id}`]}
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Payment Type')}</Form.Label>
              <EditableDropDown
                onChange={(option) =>
                  handleChildChange(each.id, 'paymentType', option.value)
                }
                value={each.data.paymentType}
                disabled={true}
                options={[
                  {
                    label: 'Delivery Pay',
                    value: 'deliveryPay',
                  },
                ]}
                placeholder={t('Select Payment Type')}
              />
              {errors[`paymentType_${each.id}`] && (
                <div className="text-danger">
                  {errors[`paymentType_${each.id}`]}
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>{t('Amount')}</Form.Label>
              <Form.Control
                type="number"
                value={
                  invoiceData.finalData[each.data.tripNumber]?.finalAmount || ''
                }
                disabled={true}
                style={{ backgroundColor: '#FFF' }}
                onChange={(e) =>
                  handleChildChange(each.id, 'amount', e.target.value)
                }
                placeholder={t('Enter Amount')}
              />
              {errors[`amount_${each.id}`] && (
                <div className="text-danger">{errors[`amount_${each.id}`]}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={1} className="mt-4">
            <Button
              onClick={() => {
                setSelectedTrip(each.data.tripNumber);
                setShowModal(true);
              }}
              disabled={!each.data.tripNumber}
            >
              {t('Edit')}
            </Button>
          </Col>
          <Col md={1} className="mt-4">
            <Button
              variant="danger"
              onClick={() => handleDelete(each.id)}
              disabled={isDisabled}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </Col>
        </Row>
      ))}

      {/* Total and Actions */}
      <Row className="mt-4">
        <Col className="fw-bold fs-4">
          {t(`Total Amount: ${total.toFixed(2)}`)}
        </Col>
        {isDisabled ? (
          <Col className="me-5 d-flex justify-content-end">
            <Button onClick={handleReset} variant="danger">
              <FontAwesomeIcon icon={faUndo} />
              &nbsp;&nbsp; {t('Reset')}
            </Button>
          </Col>
        ) : (
          <Col className="me-5 d-flex justify-content-end">
            <Button onClick={handleCalculate}>
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp; {t('Calculate')}
            </Button>
          </Col>
        )}
      </Row>

      {/* Create Voucher Button */}
      {showCreate && (
        <Col className="d-flex justify-content-center mt-4">
          <Button onClick={handleCreatePayment}>
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp; {t('Create Voucher')}
          </Button>
        </Col>
      )}

      {/* Invoice Modal */}
      {showModal && (
        <InvoiceDataModal
          showModal={showModal}
          closeModal={closeModal}
          modalTitle={t('Add Invoice Details')}
          invoices={invoiceData.invoices[selectedTrip] || []}
          initialData={invoiceData.finalData[selectedTrip]?.invoices || []}
          handleSubmit={handleInvoiceSubmit}
        />
      )}
    </div>
  );
};
export default CreatePaymentVoucher;
