import React, { useEffect, useState } from 'react';
import TableOnly from '../../scheme-management/Components/IndividualScheme/TableOnly';
// import { infoModalColumns, productsColums, receivedColumns } from './utils';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBan,
  faBroom,
  faRotateRight,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import { useTranslation } from 'react-i18next';

// import { IBTdropDownStatus, IBTStatuses, statusMap } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '../interBranchTransfers/components/ActionButton';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import SwipeMachineModal from './SwipeModal';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { setPageNumberInStore } from '../../../actions/tablePage';
import InfoModal from '../inventory/components/InfoModal';
import {
  convertExcelDateToJSDate,
  getColumnsForExcelUpload,
} from '../orders/utils';
import UploadFile from '../products/components/UploadFile';

const Authorization = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [orginalData, setOrginalData] = useState([]);
  const [swipeMachineType, setSwipeMachineType] = useState([]);
  const [swipeType, setSwipeType] = useState([]);
  const [selectedId, setSelecedId] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [orderNo, setOrderNo] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [transactionId, setTrasactionId] = useState('');
  const [showUploadDataModal, setShowUploadDataModal] = useState(false);
  const [uploadedData, setUploadedData] = useState(false);
  const username = useSelector((state) => state?.user?.user?.username || '');

  const zone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleBulkUploadSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      const toCallData = data.map((each) => {
        return {
          orderNo: each?.orderNo?.toString(),
          swipeMachineType: each?.swipeMachineType,
          swipeType: each?.swipeType,
          cardNumber: each?.cardNumber?.toString(),
          approvalCode: each?.approvalCode?.toString(),
        };
      });
      await apiCall('put', `/internal/authorization/bulk`, {
        data: toCallData,
        username,
      });

      await fetchData();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: 'success',
          message: 'successfully updated',
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };

  const [selectedAction, setSelectedAction] = useState('');
  const [confirmationModalProps, setConfirmationModalProps] = useState([
    {
      type: 'text',
      title: 'Remark',
      value: '',
    },
  ]);

  const fetchData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/authorization',
        {}
      );

      setData(response?.data);
      setOrginalData(response?.data);
      setSwipeMachineType(response?.Swipe_Machine_type);
      setSwipeType(response?.Swipe);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };

  useEffect(() => {
    const all = async () => {
      await fetchData();
    };
    all();
  }, []);
  const actionClicked = (action, id) => {
    setShow(true);
    setSelecedId(id);
  };
  const closeClicked = () => {
    setShow((show) => !show);
  };

  const filterObj = [
    { label: 'ORDER NO', value: orderNo, func: setOrderNo },

    {
      label: 'payment Type',
      value: paymentType,
      func: setPaymentType,
    },
    { label: 'paymentMode', value: paymentMode, func: setPaymentMode },
    { label: 'transactionId', value: transactionId, func: setTrasactionId },
  ];
  const handleUploadClicked = async (fileData) => {
    dispatch(updateSpinnerState(true));
    setUploadedData(fileData);
    setShowUploadDataModal(true);
    dispatch(updateSpinnerState(false));
  };
  const handleSubmit = async (dataInfo) => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        apiCallConsts.PUT_METHOD,
        'internal/authorization',
        { ...dataInfo, userName }
      );

      const filter = data?.filter((each) => each.objectId != selectedId);
      setData(filter);

      dispatch(updateSpinnerState(false));

      setShow(false);
      updateToastInfo({
        show: true,
        type: 'success',
        title: 'success',
        message: 'successfully updated',
      });
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed',
          message: error.message,
        })
      );
    }
  };
  const handleReset = () => {
    setOrderNo((each) => '');
    setPaymentMode((each) => '');
    setPaymentType((each) => '');
    setTrasactionId((each) => '');
  };

  const handleFilterSubmit = () => {
    dispatch(setPageNumberInStore({ id: 'paymentAuth', page: 1 }));
    const filterData = orginalData
      .filter((each) => {
        if (orderNo) {
          return each?.orderNo?.toLowerCase()?.includes(orderNo?.toLowerCase());
        } else {
          return true;
        }
      })
      .filter((each) => {
        if (paymentMode) {
          return each?.paymentMode
            ?.toLowerCase()
            ?.includes(paymentMode?.toLowerCase());
        } else {
          return true;
        }
      })
      .filter((each) => {
        if (paymentType) {
          return each?.paymentType
            ?.toLowerCase()
            ?.includes(paymentType?.toLowerCase());
        } else {
          return true;
        }
      })
      .filter((each) => {
        if (transactionId) {
          return each?.transactionId
            ?.toLowerCase()
            ?.includes(transactionId?.toLowerCase());
        } else {
          return true;
        }
      });
    setData(filterData);
  };

  useEffect(() => {
    handleFilterSubmit();
  }, [flag]);

  const handleEmpty = async (fun) => {
    fun(() => '');
    // await fetchData();
    setFlag((flag) => !flag);
  };

  return (
    <>
      <h4 className="mt-2">{t('Authorization Payment')}</h4>
      <BreadCrumbComponent items={['orders', 'Authorizatiion']} />
      <div className="d-flex justify-content-end gap-2 mb-3">
        {true && (
          <UploadFile
            uploadedData={handleUploadClicked}
            sampleFileUrl={
              'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Bulk+Authorization+(4).xlsx'
            }
            buttonText={'Authorization Bulk'}
            buttonSize="md"
          />
        )}
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={() => setOpenFilter(!openFilter)}
        >
          <FontAwesomeIcon icon={faSliders} />
          &nbsp;&nbsp;{t('Filter')}
        </Button>
        <Button
          variant="white"
          size="md"
          className="btn-export"
          onClick={fetchData}
        >
          <FontAwesomeIcon icon={faRotateRight} />
          &nbsp;&nbsp; {t('Refresh')}
        </Button>
        <ExcelDownloadReport data={data || []} fileName="ReceivedIBT" />
      </div>
      <>
        <div
          className="table-settings mt-2 mb-4"
          style={{
            background: '#EAEDF2',
            maxHeight: openFilter ? '700px' : '0px',
            overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
            transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
          }}
        >
          <div style={{ gap: 8, display: 'flex' }}>
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)', // Three columns
                gap: '20px', // Gap between elements
                width: '100%',
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <div>
                <Form.Label>order No</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  required
                  type="text"
                  value={orderNo}
                  onChange={(event) => {
                    setOrderNo(event.target.value);
                  }}
                  placeholder="Enter the Order NO."
                />
              </div>
              <div>
                <Form.Label>Payment Mode</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  required
                  type="text"
                  value={paymentMode}
                  onChange={(event) => {
                    setPaymentMode(event.target.value);
                  }}
                  placeholder="Enter the Payment Mode."
                />
              </div>

              <div>
                <Form.Label>Payment Type</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  required
                  type="text"
                  value={paymentType}
                  onChange={(event) => {
                    setPaymentType(event.target.value);
                  }}
                  placeholder="Enter the Payment Type"
                />
              </div>

              <div>
                <Form.Label>Transaction No</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  required
                  type="text"
                  value={transactionId}
                  onChange={(event) => {
                    setTrasactionId(event.target.value);
                  }}
                  placeholder="Enter the Transaction No."
                />
              </div>

              <div
                style={{
                  gridColumn: 'span 3', // Span across all columns
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="white"
                  className="fa-export"
                  onClick={handleReset}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} />
                  &nbsp;&nbsp;{t('Reset')}
                </Button>

                <Button
                  variant="white"
                  className="fa-export"
                  onClick={() => {
                    setFlag(!flag);
                  }}
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  &nbsp;&nbsp;{t('Apply Filter')}
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>
        {!openFilter &&
          (orderNo != '' ||
            paymentMode != '' ||
            paymentType != '' ||
            transactionId != '') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="gap-2"
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#F5F8FB',
                  padding: 20,
                }}
              >
                {filterObj.map((each) => {
                  if (each.value != '') {
                    return (
                      <div>
                        <Form.Label style={{ color: '#262B4099' }}>
                          {each.label}
                        </Form.Label>
                        <Badge
                          variant="light"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '188px',
                            height: '41px',
                            background: 'white',
                            borderRadius: '2px',
                            cursor: 'pointer',
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          {each.value}
                          <span
                            onClick={() => handleEmpty(each.func)}
                            style={{
                              color: '#262B40',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '16.41px',
                            }}
                          >
                            &times;
                          </span>
                        </Badge>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                variant="primary"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={faBroom} />
                &nbsp;&nbsp;{t('Clear')}
              </Button>
            </div>
          )}
      </>

      <TableOnly
        id="paymentAuth"
        data={data}
        columns={[
          {
            dataField: 'orderNo',
            text: 'orderNo',
          },
          {
            dataField: 'paymentMode',
            text: 'Payment Mode',
          },
          {
            dataField: 'paymentType',
            text: 'Payment Type',
          },
          {
            dataField: 'selectedPaymentGateway',
            text: 'Payment Gateway',
          },
          {
            dataField: 'transactionId',
            text: 'transactionId',
          },
          {
            dataField: 'billAmount',
            text: 'amount',
          },
          {
            dataField: 'action',
            text: 'action',
            formatter: (cell, row) => {
              let options = ['Update'];

              return (
                <>
                  <ActionButton
                    options={options}
                    onActionClick={(option) =>
                      actionClicked(option, row?.objectId)
                    }
                  />
                </>
              );
            },
          },
        ]}
        isFooterRequired={true}
      />
      <SwipeMachineModal
        showModal={show}
        closeModal={closeClicked}
        swipeMachineTypeOptions={swipeMachineType}
        swipeTypeOptions={swipeType}
        selectedId={selectedId}
        handleSubmit={handleSubmit}
      />
      <InfoModal
        showModal={showUploadDataModal}
        closeModal={() => {
          setShowUploadDataModal(false);
        }}
        modalTitile={'Authorization Orders'}
        data={uploadedData}
        columns={getColumnsForExcelUpload(uploadedData)}
        size={'xl'}
        showSubmit={true}
        handleSubmit={handleBulkUploadSubmit}
      />
    </>
  );
};

export default Authorization;
