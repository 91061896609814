import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { getVendorsListInRegion } from '../../parse-functions/vendors';
import { convertArrayToObj } from '../../utils/json';
import { setVendorsListInStore } from '../../actions/vendors';
import { updateToastInfo } from '../../actions/settings';

const useFetchVendors = (vendorsList) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchVendor = async () => {
      if (Object.keys(vendorsList).length > 0) {
        return;
      }
      dispatch(updateSpinnerState(true));
      try {
        const filters = {
          status: 'active',
          getAllVendors: true,
        };
        let vendors = await getVendorsListInRegion(filters);
        vendors = JSON.parse(JSON.stringify(vendors));
        dispatch(setVendorsListInStore(convertArrayToObj(vendors, 'objectId')));
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Error'),
            message: t(e.message),
          })
        );
      }
    };

    fetchVendor();
  }, []);
};

export default useFetchVendors;
