import React, { useEffect, useState } from 'react';
import {
  individualSchemeTable,
  sellOutTable,
} from '../../../../utils/sms/individualSchemeTable';
import PurchaseDataModal from './PurchaseDataModal';
import { getDetailedPurchase } from '../../../../parse-functions/sms/scheme';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import TableOnly from './TableOnly';

const PurchaseData = ({ orders, data, setData }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [detailedPurchaseData, setDetailedPurchaseData] = useState([]);

  const HandleCloseModal = () => {
    setShowModal(false);
  };

  const fetchDetailedPurchase = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const { vanList, validFrom, validTill, warehouseList, regions, type } =
        orders;
      const data = await getDetailedPurchase({
        vanList,
        validFrom,
        validTill,
        warehouseList,
        regions,
        type,
      });
      setData(data);
      dispatch(updateSpinnerState(false));
      setShowModal(true);
      return data;
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Data fetch Failed',
          message: error.message,
        })
      );
    }
  };
  const handleRowClick = async (row) => {
    if (data.length === 0) {
      const purchaseData = await fetchDetailedPurchase();
      setDetailedPurchaseData(
        purchaseData.filter((item) => (item.VanNo || item.vanNo) == row.vanNo)
      );
    } else {
      setDetailedPurchaseData(
        data.filter((item) => (item.VanNo || item.vanNo) == row.vanNo)
      );
    }
    setShowModal(true);
  };
  return (
    <div>
      <TableOnly
        id="purchaseData"
        data={Object.values(orders?.calculationReference || {})}
        columns={
          orders?.type === 'Sell-in'
            ? sellOutTable().slice(0, 8)
            : sellOutTable()
        }
        handleRowClick={handleRowClick}
        rowStyle={{ cursor: 'pointer' }}
        showRowBgColor={true}
      />
      <PurchaseDataModal
        showModal={showModal}
        closeModal={HandleCloseModal}
        data={detailedPurchaseData}
        columns={individualSchemeTable(orders?.type)}
      />
    </div>
  );
};

export default PurchaseData;
