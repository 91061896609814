import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import {
  cardDataColumns,
  inventoryColumnsStoreGuy,
  productInventoryColumns,
} from './utils';
import { apiCall } from '../../../middlewares/api';
import { updateSpinnerState } from '../../../actions/spinner';
import InventoryModal from './components/InvenoryModal';
import { updateToastInfo } from '../../../actions/settings';
import CategoryUpdateModal from './components/CategoryUpdateModal';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
} from '@themesberg/react-bootstrap';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faLocationDot,
  faStoreAlt,
} from '@fortawesome/free-solid-svg-icons';
import EmptyTable from '../../../components/common/EmptyTableView';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import DropdownComponent from '../../../components/common/Dropdown';
import Table from 'react-bootstrap-table-next';
import { styles } from './style';
import CardDropdown from './components/CardDropDown';
import TableList from './components/TableList';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import india from '../../../assets/img/flags/squareIndia.svg';
import { returnZoneDropdownOptions } from '../../../utils/zone';
import InventoryFilters from './components/InventoryFilters';
import { setFiltersInStore } from '../../../actions/filters';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { setPageNumberInStore } from '../../../actions/tablePage';

const ProductInventory = ({
  showCategoryModal,
  setShowCategoryModal,
  setExportData,
}) => {
  const { t } = useTranslation();
  const id = 'inventoryStore';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const ownZone = useSelector((state) => state?.user?.warehouse?.zone || '');
  const zone = useSelector((state) => state?.user?.preferences?.zone || '');
  const ownCity = useSelector((state) => state?.user?.warehouse?.city || '');
  const otherWarehouses = useSelector(
    (state) => state?.user?.otherWarehousesInZone || {}
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);

  const dispatch = useDispatch();

  const [products, setProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [inputsData, setInputsData] = useState({});
  const [selectedProductData, setSelectedProductData] = useState({});
  const [type, setType] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);
  const [bulkActionSelectedOption, setBulkActionSelectedOption] =
    useState(null);
  const [cardFilters, setCardFilters] = useState({
    store: storeName,
    city: ownCity?.toUpperCase(),
    region: ownZone?.length > 0 ? ownZone : zone,
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const totalPages = Math.ceil((filteredProducts?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[id] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );

  useFetchStores();

  const citiesAndStoreMap = storeList?.reduce((accu, warehouse) => {
    if (warehouse?.city) {
      if (!accu[warehouse?.city]) {
        accu[warehouse?.city] = [];
      }
      accu[warehouse?.city].push(warehouse?.shortCode || '');
    }
    return accu;
  }, {});

  const regionAndStoreMap = storeList?.reduce((accu, warehouse) => {
    if (warehouse?.zone) {
      if (!accu[warehouse?.zone]) {
        accu[warehouse?.zone] = [];
      }
      accu[warehouse?.zone].push(warehouse?.shortCode || '');
    }
    return accu;
  }, {});

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const handleCardClick = (cardName) => {
    setSelectedCard((prevCard) => (prevCard === cardName ? null : cardName));
  };
  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedProducts((prev) => {
        // console.log(products, products?.data);
        const selectedItem = filteredProducts?.find(
          (item) => item?.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedProducts((prev) =>
        prev.filter((item) => item?.objectId !== objectId)
      );
    }
    setFilteredProducts((prev) =>
      prev?.map((item) =>
        item?.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const getWarehouseDropdownOptions = () => {
    return returnWarehouseDropdownOptions(storeList, true);
  };

  const fetchInventory = async (data) => {
    const res = await apiCall(
      apiCallConsts.GET_METHOD,
      apiCallConsts.INVENTORY_URL,
      {
        ...data,
        zone: zone,
        region: ownZone,
        city: ownCity?.toUpperCase(),
      }
    );
    if (res?.message) {
      messageDisplayer('success', '', res?.message);
      return {};
    }
    return res;
  };

  const getWarehouseShortCode = (warehouseName) => {
    let options = getWarehouseDropdownOptions();
    const res = options?.filter(
      (option) => option?.data?.shortCode === warehouseName
    );
    const shortCode = res?.length > 0 ? res[0]?.data?.warehouseName : '';
    return shortCode;
  };

  const handleApiCall = async (params) => {
    try {
      dispatch(updateSpinnerState(true));
      // const storeCode = getWarehouseShortCode(selectedWarehouse);
      let filteredProducts = await fetchInventory(params);

      if (params?.shortCode) {
        const store = getWarehouseShortCode(params?.store);
        handleStoreFilter(store);
      } else {
        setFilteredProducts(filteredProducts?.data);
      }
      setProducts(filteredProducts);
      setExportData(filteredProducts?.data);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'inventory filtered successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'failed to fetch inventory', error.message);
    }
  };

  const handleItemBulkUpdate = () => {
    if (bulkActionSelectedOption === 'block' && selectedProducts?.length > 1) {
      messageDisplayer(
        'danger',
        'Only 1 item allowed',
        'Please select only one item'
      );
      return;
    }
    switch (bulkActionSelectedOption) {
      case 'onHold': {
        setType(bulkActionSelectedOption);
        setSelectedProductData(selectedProducts[0]);
        setShowInventoryModal(true);
        break;
      }
      case 'safetyStockLimit': {
        setType(bulkActionSelectedOption);
        setShowInventoryModal(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      const id = selectedProducts.map((product) => product?.objectId);
      if (id?.length === 0) {
        messageDisplayer('danger', 'Failed!', 'Please select atleast one item');
      }
      dispatch(updateSpinnerState(true));
      // if (type === 'safetyStockLimit') {
      let params = {
        ...data,
        id: id,
      };
      await apiCall(
        apiCallConsts.PUT_METHOD,
        `${apiCallConsts.INVENTORY_UPDATE_URL}/safetyStock`,
        params
      );
      // }
      // else {
      //   await apiCall(
      //     apiCallConsts.PUT_METHOD,
      //     `${apiCallConsts.INVENTORY_BASE}/${id}`,
      //     data
      //   );
      // }
      handleApiCall(inputsData);
      setShowInventoryModal(false);
      setSelectedProducts([]);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'inventory updated successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'inventory updated Failed', error.message);
    }
  };

  const handleCategoryModalSubmit = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        `${apiCallConsts.INVENTORY_UPDATE_URL}/categorySafetyStock`,
        data
      );
      handleApiCall(inputsData);
      setShowCategoryModal(false);
      setSelectedProducts([]);
      // setProducts(products.length ? products : []);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'inventory updated successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'inventory updated Failed', error.message);
    }
  };

  const pendingOrdersCount = filteredProducts?.reduce((accu, curr) => {
    if (curr?.shortCode === 'PNDORD') {
      accu += curr?.inProcess || 0;
    }
    return accu;
  }, 0) || 0;

  const baseCardStyle = {
    padding: '10px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    transition: 'border 0.3s ease',
  };

  const getCardStyle = (cardName) => {
    return selectedCard === cardName
      ? { ...baseCardStyle, border: '1px solid #262B40' }
      : { ...baseCardStyle, border: '1px solid #E5E7EB' };
  };
  const handleStoreFilter = (selectedStore) => {
    setCardFilters({
      store: selectedStore,
      city: products?.storeMapping
        ? products?.storeMapping?.[selectedStore]?.city
        : ownCity,
      region: products?.storeMapping
        ? products?.storeMapping?.[selectedStore]?.region
        : ownZone,
    });

    const filteredData = products?.data?.filter(
      (item) => item.storeCode === selectedStore
    );
    setFilteredProducts(() => {
      return filteredData || [];
    });
  };

  const handleCityFilter = (selectedCity) => {
    setCardFilters({
      store: null,
      city: selectedCity,
      region: null,
    });
    const filteredData = products?.data?.filter((each) =>
      citiesAndStoreMap[selectedCity?.toUpperCase()]?.includes(each?.shortCode)
    );
    setFilteredProducts(filteredData || []);
  };

  const handleRegionFilter = (selectedRegion) => {
    setCardFilters({
      store: null,
      city: null,
      region: selectedRegion,
    });
    const filteredData = products?.data?.filter((each) =>
      regionAndStoreMap[selectedRegion]?.includes(each?.shortCode)
    );
    setFilteredProducts(filteredData || []);
  };

  const handlePageChange = (page) => {
    if (id) {
      dispatch(setPageNumberInStore({ id, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);

  return (
    <div>
      <InventoryFilters
        handleSubmit={(data) => {
          setInputsData(data);
          handleApiCall(data);
          handleStoreFilter(getWarehouseShortCode(data.storeCode || ''));
        }}
      />
      <ToolkitProvider
        exportCSV
        keyField="inventoryStore"
        search={true}
        sort
        data={filteredProducts || []}
        columns={
          roles?.includes('inchargeOMS')
            ? inventoryColumnsStoreGuy()
            : productInventoryColumns(onRowSelect)
        }
        wrapperClasses="table-responsive"
      >
        {({ baseProps, searchProps, csvProps }) => (
          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: products?.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
              onPageChange: handlePageChange,
              page: currentPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <>
                <div className="row g-2 mb-2">
                  <div className="pi-column">
                    <div style={getCardStyle('store')}>
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: '100%' }}
                        >
                          <FontAwesomeIcon icon={faStoreAlt} />
                          <CardDropdown
                            placeholder={t('store')}
                            options={
                              roles?.includes('inchargeOMS')
                                ? arrayToDropdownOptions([cardFilters?.store])
                                : returnWarehouseDropdownOptions(
                                    storeList,
                                    false,
                                    zone
                                  )
                            }
                            disabled={
                              roles.includes('inchargeOMS') ||
                              Object.keys(products)?.length <= 0
                            }
                            width={'100%'}
                            value={cardFilters?.store}
                            onChange={(option) => {
                              setCardFilters((prev) => ({
                                ...prev,
                                store: option?.value,
                              }));
                              handleStoreFilter(option?.value);
                            }}
                          />
                        </div>
                      </div>
                      <div style={styles.seperator}></div>
                      <div className="d-flex flex-column ms-2">
                        <p className="mb-0 font-size-sm">
                          Total Stock in Store
                        </p>
                        <p className="mb-0 font-size-lg">
                          {filteredProducts?.length > 0
                            ? filteredProducts?.reduce(
                                (accu, curr) => accu + curr?.totalStock,
                                0
                              )
                            : 0}
                        </p>
                        <p className="mb-0">
                          {`Status: `}
                          {Object.keys(products)?.length > 0 &&
                          products?.data?.[0]?.totalStock > 0 ? (
                            <span>
                              In Stock{' '}
                              <FontAwesomeIcon color="green" icon={faCheck} />
                            </span>
                          ) : (
                            'Out Of Stock'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pi-column">
                    <div
                      style={getCardStyle('city')}
                      onClick={() => handleCardClick('city')}
                    >
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: '100%' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FontAwesomeIcon icon={faBuilding} />
                          <CardDropdown
                            placeholder={t('City')}
                            options={
                              roles?.includes('inchargeOMS')
                                ? arrayToDropdownOptions([cardFilters?.city])
                                : arrayToDropdownOptions(
                                    Object.keys(citiesAndStoreMap)
                                  )
                            }
                            disabled={
                              roles.includes('inchargeOMS') ||
                              Object.keys(products)?.length <= 0
                            }
                            width={'100%'}
                            value={cardFilters?.city}
                            onChange={(option) => {
                              setCardFilters((prev) => ({
                                ...prev,
                                city: option.value,
                              }));
                              handleCityFilter(option.value);
                            }}
                          />
                        </div>
                      </div>
                      <div style={styles.seperator}></div>
                      <div className="d-flex flex-column ms-2">
                        <p className="mb-0 font-size-sm">
                          Total Stock in Cities
                        </p>
                        <p className="mb-0 font-size-lg">
                          {(Object.keys(products)?.length > 0 &&
                            cardFilters?.city &&
                            products?.city[cardFilters?.city?.toUpperCase()]
                              ?.count) ||
                            0}
                        </p>
                        <p className="mb-0">{`Total no. of city `}</p>
                      </div>
                    </div>
                  </div>
                  <div className="pi-column">
                    <div
                      style={getCardStyle('region')}
                      onClick={() => handleCardClick('region')}
                    >
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: '100%' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <FontAwesomeIcon icon={faLocationDot} />
                          <CardDropdown
                            placeholder={t('Region')}
                            value={cardFilters?.region}
                            options={
                              roles?.includes('admin')
                                ? returnZoneDropdownOptions(zones, false)
                                : roles?.includes('inchargeOMS')
                                ? arrayToDropdownOptions([ownZone])
                                : arrayToDropdownOptions([zone])
                            }
                            disabled={
                              roles.includes('inchargeOMS') ||
                              Object.keys(products).length <= 0
                            }
                            width={'100%'}
                            onChange={(option) => {
                              setCardFilters((prev) => ({
                                ...prev,
                                region: option.label,
                              }));
                              handleRegionFilter(option.label);
                            }}
                          />
                        </div>
                      </div>
                      <div style={styles.seperator}></div>
                      <div className="d-flex flex-column ms-2">
                        <p className="mb-0 font-size-sm">
                          Total Stock in Region
                        </p>
                        <p className="mb-0 font-size-lg">
                          {(Object.keys(products)?.length > 0 &&
                            cardFilters?.region &&
                            products?.region[cardFilters?.region]?.count) ||
                            0}
                        </p>
                        <p className="mb-0">{`Total no. of Regions: ${
                          returnZoneDropdownOptions(zones, false)?.length
                        }`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="  pi-column">
                    <div style={getCardStyle('country')}>
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ width: '100%' }}
                        >
                          <Image
                            src={india}
                            width={32}
                            height={32}
                            className="sidebar-icon svg-icon"
                            style={{ borderRadius: '0px' }}
                          />
                          <CardDropdown
                            placeholder={t('India')}
                            options={returnWarehouseDropdownOptions(storeList)}
                            disabled={true}
                            width={'100%'}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                      <div style={styles.seperator}></div>
                      <div className="d-flex flex-column ms-2">
                        <p className="mb-0 font-size-sm">
                          Total Stock in country
                        </p>
                        <p className="mb-0 font-size-lg">
                          {products?.country || 0}
                        </p>
                        <p className="mb-0">Sales Volume:</p>
                      </div>
                    </div>
                  </div>
                </div>
                {selectedCard &&
                  Object.keys(products)?.length > 0 &&
                  (cardFilters?.city || cardFilters?.region) && (
                    <div className="mb-2">
                      <TableList
                        data={
                          selectedCard === 'city'
                            ? products?.city[cardFilters?.city?.toUpperCase()]
                                ?.data
                            : products?.region[cardFilters?.region]?.data
                        }
                        columns={cardDataColumns()}
                        title={selectedCard}
                      />
                    </div>
                  )}
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header
                      className="d-flex justify-content-between fs-4 lh-sm align-items-center"
                      style={{ color: '#262B40', fontWeight: 500 }}
                    >
                      <div >       
                        {products?.productData?.images && (
                          <img
                            style={{ width: '50px', height: '50px' }}
                            src={products?.productData?.images}
                          />
                        )}
                        &nbsp;&nbsp; {products?.productData?.productTitle || ''}                   
                      </div>
                      <div style={{display : "flex", alignItems: "center", justifyContent:"space-between", width:"45%"}}>
{pendingOrdersCount > 0 ? (
  <div style={{cursor : "pointer"}}
    onClick={() => {
      window.open(`/oms/orders/pendingaction?vanNo=${inputsData?.vanNo}`, '_blank');
    }}
  >
    {`The following are Pending orders: ${pendingOrdersCount}`}
  </div>
) : <div></div>}
                      <div>{`Stock Available:  ${
                        filteredProducts?.length > 0
                          ? filteredProducts?.reduce(
                              (accu, curr) => {
                                
                                if(curr?.shortCode == "PNDORD")
                                {
                                  accu -= curr?.inProcess
                                }else{
                                  accu += curr?.available
                                }

                                if(accu > 0)
                                {
                                  return accu
                                }else{
                                  return 0
                                }
                              
                              },
                              0
                            )
                          : 0
                      }`}</div>
                      </div>
                    </Card.Header>
                    <Card.Body
                      style={{
                        padding: '8px 12px',
                      }}
                    >
                      <Row className="d-flex justify-content-between">
                        {isAdmin && (
                          <Col xs={8} md={6} className="d-flex py-1">
                            <Form.Group
                              id="substatus"
                              className="d-flex align-items-center flex-column"
                            >
                              <DropdownComponent
                                onChange={(option) => {
                                  setBulkActionSelectedOption(option.value);
                                }}
                                width={300}
                                disabled={setSelectedProducts.length === 0}
                                options={[
                                  { label: 'Hold', value: 'onHold' },
                                  {
                                    label: 'Update safetyStock',
                                    value: 'safetyStockLimit',
                                  },
                                ]}
                                placeholder={t('Select Action')}
                              />
                            </Form.Group>
                            <Button
                              style={{
                                height: 37,
                              }}
                              size="sm"
                              onClick={handleItemBulkUpdate}
                              disabled={!bulkActionSelectedOption}
                              className="ms-3 btn btn-primary"
                              variant="primary"
                            >
                              <FontAwesomeIcon icon={faCheck} />
                              &nbsp;&nbsp;{t('Update')}
                            </Button>
                          </Col>
                        )}
                        {isAdmin && (
                          <Col
                            xs={12}
                            md={4}
                            className="d-flex justify-content-md-end"
                          >
                            <Search.SearchBar
                              {...searchProps}
                              placeholder={t('Search any value in table here')}
                            />
                            <Search.ClearSearchButton {...searchProps} />
                          </Col>
                        )}
                      </Row>
                    </Card.Body>
                    <Table
                      noDataIndication={<EmptyTable />}
                      {...baseProps}
                      {...paginationTableProps}
                      bodyClasses="border-0"
                      classes="table-flush dataTable-table"
                    />

                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={4} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center py-1"
                        >
                          <Paginator.PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              </>
            )}
          </Paginator.PaginationProvider>
        )}
      </ToolkitProvider>

      <InventoryModal
        showModal={showInventoryModal}
        closeModal={() => setShowInventoryModal(false)}
        modalTitile={'Updating Product Inventory'}
        handleSubmit={handleSubmit}
        modalData={selectedProductData}
        type={type}
      />

      {showCategoryModal && (
        <CategoryUpdateModal
          showModal={showCategoryModal}
          closeModal={() => setShowCategoryModal(false)}
          handleSubmit={handleCategoryModalSubmit}
        />
      )}
    </div>
  );
};

export default ProductInventory;
