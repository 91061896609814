import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

const ToggleButton = ({ title, options, value, setValue, onChange }) => {
  const toggleChange = (e) => {
    setValue(e?.target?.checked);
    onChange(e?.target?.checked);
  };
  return (
    <>
      <div className="d-flex justify-content-center align-content-center px-2 py-3 gap-3">
        <p>{value ? options[0] : options[1]}</p>
        <Form.Check
          type="switch"
          name={title}
          checked={value}
          onChange={toggleChange}
        />
      </div>
    </>
  );
};

export default ToggleButton;
