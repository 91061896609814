import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';

const ManualInvoice = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const bulkActionOptions = [
    {
      label: 'Invoiced',
      value: 'invoiced',
    },
  ];

  const bulkActionOptionsAdmin = [
    {
      label: 'Invoiced',
      value: 'invoiced',
    },
    {
      label: 'On Hold',
      value: 'On Hold',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Assign',
      value: 'assign',
    },
    {
      label: 'Out Of Stock',
      value: 'Out Of Stock',
    },
  ];

  const defaultConfirmationProps = {
    invoiced: [
      {
        type: 'text',
        title: 'Invoice No.',
        value: '',
      },
    ],
    cancelled: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <div>
      <OrderPage
        type={'To Be Invoiced'}
        bulkActionOptions={isAdmin ? bulkActionOptionsAdmin : bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </div>
  );
};

export default ManualInvoice;
