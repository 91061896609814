import React from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import ParcelStatusPage from '../../../../components/common/parcelStatusPages';
import { generateRandomId } from '../../../../utils/string';
import TableOnly from '../IndividualScheme/TableOnly';
import NoPaginationTable from './NoPaginationTable';

const EditProducts = (props) => {
  const { showModal, closeModal, data, columns, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <div>
      <>
        <Modal
          id={generateRandomId()}
          as={Modal.Dialog}
          centered
          show={showModal}
          onHide={closeModal}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title className="h6">
              {t(`All Mismatched Products`)}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={closeModal} />
          </Modal.Header>
          <Modal.Body>
            {Object.keys(data).length > 0 && (
              <NoPaginationTable
                data={data}
                columns={columns}
                isFooterRequired={false}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onSubmit} className="mb-3">
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default EditProducts;
