import React, { useEffect, useState } from 'react';
import { Row, Col, Button, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faSliders,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import TableOnly from '../../scheme-management/Components/IndividualScheme/TableOnly';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { returnUserReadableDateTime } from '../../../utils/datetime';
import { returnProductInfo } from '../../../utils/sms/tableUtils';
import { updateToastInfo } from '../../../actions/settings';
import { t } from 'i18next';

const UserReports = () => {
  // Example user and reports data
  const [userReport, setUserReport] = useState([]);
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const dispatch = useDispatch();
  const handleFilter = () => {
    console.log('Filter toggled');
  };

  const retryAttempt = async (fileName, filtersApply) => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/order/filter',
        { ...filtersApply, isRetry: true }
      );

      const updated = userReport?.map((each) => {
        if (each?.fileName == fileName) {
          return {
            ...each,
            status: 'pending',
          };
        }
        return each;
      });

      setUserReport(updated);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
      console.log(error);
    }
  };

  const downloadFileFromUrl = (downloadUrl, name) => {
    try {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = name || 'downloaded-file';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      console.log(`File download started: ${name}`);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
      console.error('Error downloading the file via URL:', error);
    }
  };

  const handleRefresh = async () => {
    try {
      await fetchReports();
    } catch (error) {}
  };

  const fetchReports = async () => {
    try {
      dispatch(updateSpinnerState(true));

      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/reports?username=${userName}`
      );
      console.log(response);
      setUserReport(response);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      console.log(error);
      return '';
    }
  };
  const onActionClick = (row) => {
    console.log('Action clicked for:', row);
  };

  const getColumnsForUserReports = () => [
    {
      text: 'Report Name',
      dataField: 'fileName',
      formatter: (cell, row) => {
        return returnProductInfo([cell]);
      },
    },
    { text: 'Type', dataField: 'type' },
    { text: 'rows', dataField: 'rows' },
    { text: 'remarks', dataField: 'remarks' },
    {
      text: 'createdAt',
      dataField: 'createdAt',
      formatter: (cell, row) => {
        let date = new Date(new Date(cell).getTime());
        return returnUserReadableDateTime(date);
      },
    },
    {
      text: 'Status',
      dataField: 'status',
      formatter: (cell) => {
        const badgeClass =
          cell === 'success'
            ? 'bg-success'
            : cell === 'pending'
            ? 'bg-warning'
            : 'bg-danger';
        return (
          <span
            className={`badge ${badgeClass}`}
            style={{ padding: '10px', borderRadius: '5px', margin: '0px auto' }}
          >
            {cell}
          </span>
        );
      },
    },
    {
      text: 'Action',
      dataField: '',
      formatter: (cell, row) =>
        row.status === 'success' ? (
          <FontAwesomeIcon
            icon={faDownload}
            className="text-primary cursor-pointer"
            onClick={() => {
              downloadFileFromUrl(row?.url, row?.fileName);
            }}
          />
        ) : row.status === 'failed' ? (
          <FontAwesomeIcon
            icon={faRefresh}
            className="text-primary cursor-pointer"
            onClick={() => {
              retryAttempt(row?.fileName, row?.filtersApplyed);
            }}
          />
        ) : null,
    },
  ];

  useEffect((each) => {
    try {
      fetchReports();
    } catch (error) {}
  }, []);

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: '100%',
        height: '100vh',
        padding: '20px',
        backgroundColor: '#f8f9fa',
        boxSizing: 'border-box',
      }}
    >
      {/* Header */}
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <h4 className="fw-bold mb-1">User Reports</h4>
          <p className="mb-0 text-muted">
            All the report will be only Available for 24 hours from creation
          </p>
        </Col>
        <Col md={4} className="text-end">
          <ButtonGroup className="gap-2">
            <Button
              variant="white"
              onClick={handleRefresh}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
              size="sm"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;Refresh
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      {/* Table */}
      <TableOnly
        id="USER_REPORTS_TABLE"
        data={userReport}
        columns={getColumnsForUserReports()}
      />
    </div>
  );
};

export default UserReports;
