import {
  faChartPie,
  faTruckLoading,
  faTruckArrowRight,
  faSheetPlastic,
  faCodePullRequest,
  faMapMarked,
  faAdd,
  faFileImport,
  faFileExport,
  faArrowRotateBack,
  faCheck,
  faFile,
  faClock,
  faBackwardFast,
  faReplyAll,
  faBusinessTime,
  faFileInvoice,
  faHourglass,
  faIndianRupeeSign,
  faFillDrip,
  faPlugCircleXmark,
  faTruck,
  faTruckFront,
  faIdCard,
  faUsersRectangle,
  faChartSimple,
  faMagnifyingGlassChart,
  faUserTie,
  faStore,
  faPeopleCarryBox,
  faTruckFast,
  faCirclePlus,
  faChartArea,
  faChartBar,
  faPeopleRoof,
  faRightLeft,
  faArrowsRotate,
  faPersonShelter,
  faClipboardUser,
  faTruckRampBox,
  faSchool,
  faSchoolCircleCheck,
  faList,
  faChartColumn,
  faUserTag,
  faUserShield,
  faMoneyBill,
  faPlus,
  faEdit,
  faBoxOpen,
  faMoneyCheckDollar,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/brand/vijay-sales-logo.svg';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { Routes as AppRoutes } from '../../router/routes';

const navigationConfig = {
  Dashboard: {
    title: 'Dashboard',
    link: AppRoutes.PlanningPage.path,
    image: logo,
  },

  Trips: {
    title: 'Temposheet',
    icon: faSheetPlastic,
    eventKey: 'trips/',
    children: [
      {
        title: 'Dispatch',
        link: AppRoutes.PlanningPage.path,
        icon: faAdd,
      },
      {
        title: 'Temporary Trips',
        link: AppRoutes.Trips.Temporary.path,
        icon: faTruckLoading,
      },
      {
        title: 'Dispatched Trips',
        link: AppRoutes.Trips.TripsList.path,
        icon: faTruckArrowRight,
      },
      {
        title: 'Receive Temposheet',
        link: AppRoutes.Trips.ReceiveStock.path,
        icon: faCirclePlus,
      },
      {
        title: 'Open Temposheet',
        link: AppRoutes.Trips.openTrips.path,
        icon: faBoxOpen,
      },{
        title: 'Per Delivery Unpaid',
        link: AppRoutes.Payments.TripPayment.PerDeliveryPayments.path,
        icon: faCirclePlus,
      },
    ],
  },
  Analysis: {
    title: 'Analysis',
    icon: faChartSimple,
    eventKey: 'analysis/',
    children: [
      {
        title: 'Delivery Data',
        link: AppRoutes.Analysis.DeliveryData.path,
        icon: faMagnifyingGlassChart,
      },
      {
        title: 'Delivery Analysis',
        link: AppRoutes.Analysis.DeliveryAnalysis.path,
        icon: faChartPie,
      },
      {
        title: 'Priority Delivery',
        link: AppRoutes.Analysis.PriorityDelivery.path,
        icon: faClock,
      },
      {
        title: 'Branch Pickup',
        link: AppRoutes.Analysis.BranchPickup.path,
        icon: faPeopleCarryBox,
      },
    ],
  },
  Reports: {
    title: 'Reports',
    icon: faChartArea,
    eventKey: 'reports/',
    children: [
      {
        title: 'Daily Delivery',
        link: AppRoutes.Reports.DailyDelivery.path,
        icon: faChartBar,
      },
      {
        title: 'Vehicle Attendance',
        link: AppRoutes.Reports.VehicleAttendance.path,
        icon: faClipboardUser,
      },
      {
        title: 'Monthly Delivery',
        link: AppRoutes.Reports.MonthlyDelivery.path,
        icon: faTruckRampBox,
      },
      {
        title: 'Vehicle Payments',
        link: AppRoutes.Reports.VehiclePayments.path,
        icon: faMoneyBill,
      },
    ],
  },
  Requisitions: {
    title: 'Requisitions',
    icon: faCodePullRequest,
    eventKey: 'requisitions/',
    children: [
      {
        title: 'Received',
        link: AppRoutes.Requisitions.Received.path,
        icon: faFileImport,
      },
      {
        title: 'Sent',
        link: AppRoutes.Requisitions.Sent.path,
        icon: faFileExport,
      },
      {
        title: 'Create New',
        link: AppRoutes.Requisitions.Add.path,
        icon: faAdd,
      },
    ],
  },
  Returns: {
    title: 'Returns',
    icon: faArrowRotateBack,
    eventKey: 'returns/',
    children: [
      {
        title: 'Return OK',
        link: AppRoutes.Returns.ReturnOK.path,
        icon: faCheck,
      },
      {
        title: 'Register',
        link: AppRoutes.Returns.ReturnRegister.path,
        icon: faFile,
      },
      {
        title: 'Return Scheduled',
        link: AppRoutes.Returns.ReturnScheduled.path,
        icon: faClock,
      },
    ],
  },
  Admin: {
    title: 'Admin',
    icon: faUserTie,
    eventKey: 'admin/',
    children: [
      {
        title: 'Update Temposheet',
        link: AppRoutes.Admin.Temposheet.path,
        icon: faTruck,
      },
    ],
  },
  Buyback: {
    title: 'Buyback',
    icon: faBackwardFast,
    eventKey: 'buyback/',
    children: [
      {
        title: 'Pending',
        link: AppRoutes.BuyBack.Pending.path,
        icon: faBusinessTime,
      },
      {
        title: 'Received',
        link: AppRoutes.BuyBack.Received.path,
        icon: faFileInvoice,
      },
      {
        title: 'Register',
        link: AppRoutes.BuyBack.Register.path,
        icon: faReplyAll,
      },
      {
        title: 'Scheduled',
        link: AppRoutes.BuyBack.Scheduled.path,
        icon: faHourglass,
      },
      {
        title: 'Cash Info',
        link: AppRoutes.BuyBack.CashRegister.path,
        icon: faIndianRupeeSign,
      },
    ],
  },
  ModelChange: {
    title: 'Model Change',
    icon: faArrowsRotate,
    eventKey: 'modelChange/',
    children: [
      {
        title: 'Pending',
        link: AppRoutes.ModelChange.Pending.path,
        icon: faRightLeft,
      },
      {
        title: 'Register',
        link: AppRoutes.ModelChange.Register.path,
        icon: faReplyAll,
      },
      {
        title: 'Scheduled',
        link: AppRoutes.ModelChange.Scheduled.path,
        icon: faBusinessTime,
      },
    ],
  },
  Replacement: {
    title: 'Replacement',
    icon: faCodePullRequest,
    eventKey: 'replacement/',
    children: [
      {
        title: 'Def. Del. / Repl. Pend',
        link: AppRoutes.Replacement.PickupPending.path,
        icon: faFillDrip,
      },
      {
        title: 'Register',
        link: AppRoutes.Replacement.Register.path,
        icon: faReplyAll,
      },
      {
        title: 'Scheduled',
        link: AppRoutes.Replacement.Scheduled.path,
        icon: faHourglass,
      },
    ],
  },
  Defective: {
    title: 'Defective',
    icon: faPlugCircleXmark,
    eventKey: 'defective/',
    children: [
      {
        title: 'Pickup Pending',
        link: AppRoutes.Defective.PickupPending.path,
        icon: faBusinessTime,
      },
      {
        title: 'Def. Received',
        link: AppRoutes.Defective.PickupReceived.path,
        icon: faFileInvoice,
      },
      {
        title: 'All',
        link: AppRoutes.Defective.Register.path,
        icon: faReplyAll,
      },
      {
        title: 'Scheduled',
        link: AppRoutes.Defective.PickupScheduled.path,
        icon: faHourglass,
      },
    ],
  },
  Vehicles: {
    title: 'Vehicles',
    icon: faTruck,
    eventKey: 'vehicles/',
    children: [
      {
        title: 'List Vehicles',
        link: AppRoutes.Vehicles.List.path,
        icon: faFile,
      },
      {
        title: 'Add New',
        link: AppRoutes.Vehicles.Add.path,
        icon: faTruckFront,
      },
    ],
  },
  Vendors: {
    title: 'Vendors',
    icon: faIdCard,
    eventKey: 'vendors/',
    children: [
      {
        title: 'List Vendors',
        link: AppRoutes.Vendors.List.path,
        icon: faUsersRectangle,
      },
      {
        title: 'Add New',
        link: AppRoutes.Vendors.Add.path,
        icon: faAdd,
      },
    ],
  },
  Products: {
    title: 'Products',
    icon: faProductHunt,
    eventKey: 'products/',
    children: [
      {
        title: 'Add Product',
        link: AppRoutes.Admin.Product.Add.path,
        icon: faAdd,
      },
    ],
  },
  Loaders: {
    title: 'Loaders',
    icon: faPeopleRoof,
    eventKey: 'loaders/',
    children: [
      {
        title: 'Add Loader',
        link: AppRoutes.Loader.Add.path,
        icon: faAdd,
      },
      {
        title: 'Loaders List',
        link: AppRoutes.Loader.List.path,
        icon: faList,
      },
    ],
  },
  Users: {
    title: 'Users',
    icon: faUserTie,
    eventKey: 'add-users/',
    children: [
      {
        title: 'List',
        icon: faList,
        link: AppRoutes.Incharge.List.path,
      },
      {
        title: 'Add Warehouse',
        icon: faStore,
        link: AppRoutes.Warehouse.Add.path,
      },
      {
        title: 'Add Incharge',
        icon: faPeopleCarryBox,
        link: AppRoutes.Incharge.Add.path,
      },
      {
        title: 'Add Security Guard',
        icon: faUserShield,
        link: AppRoutes.SecurityGuard.Add.path,
      },
      {
        title: 'Add CC User',
        icon: faPersonShelter,
        link: AppRoutes.CustomerCare.Add.path,
      },
      {
        title: 'Add CC Manager',
        icon: faPersonShelter,
        link: AppRoutes.CustomerCare.Manager.Add.path,
      },
      {
        title: 'Add Admin',
        icon: faUserTie,
        link: AppRoutes.Admin.User.Add.path,
      },
      {
        title: 'Role Management',
        icon: faUserTag,
        link: AppRoutes.Admin.User.Role.path,
      },
    ],
  },
  ExecReports: {
    title: 'Exec. Reports',
    icon: faChartColumn,
    eventKey: 'executive-report/',
    children: [
      {
        title: 'Pending Delivery',
        link: AppRoutes.ExecutiveReports.PendingDelivery.path,
        icon: faBusinessTime,
      },
    ],
  },
  Payments: {
    title: 'Payments',
    icon: faMoneyBill,
    eventKey: 'payments/',
    children: [
      {
        title: 'Sent',
        link: AppRoutes.Payments.Sent.path,
        icon: faFileExport,
      },
      // {
      //   title: 'Received',
      //   link: AppRoutes.Payments.Received.path,
      //   icon: faFileImport,
      // },
      {
        title: 'Create Voucher',
        link: AppRoutes.Payments.Add.path,
        icon: faPlus,
      },
      {
        title: 'Change Delivery Rate',
        link: AppRoutes.Payments.TripPayment.PerDeliveryRate.path,
        icon: faEdit,
      },
      {
        title: 'Unpaid Trips',
        link: AppRoutes.Payments.TripPayment.Unpaid.path,
        icon: faPlus,
      },
      {
        title: 'Invoiced Trips',
        link: AppRoutes.Payments.TripPayment.Invoiced.path,
        icon: faFileInvoice,
      },
      {
        title: 'Paid Trips',
        link: AppRoutes.Payments.TripPayment.Paid.path,
        icon: faMoneyCheckDollar,
      },
    ],
  },
  PendingApproval: {
    title: 'Pending Approval',
    link: AppRoutes.Requisitions.PendingApproval.path,
    icon: faSchoolCircleCheck,
  },
  EntryList: {
    title: 'EntryList',
    link: AppRoutes.SecurityGuard.EntryList.path,
    icon: faList,
  },
  // PayChallans: {
  //   title: 'Pay Challans',
  //   link: AppRoutes.Payments.Pay.path,
  //   icon: faMoneyBill,
  // },
};

export default navigationConfig;
