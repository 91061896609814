import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { useSelector } from 'react-redux';
import {
  orderStatus,
  reasonForRejection,
} from '../../../constants/orderStatus';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';

const AssignedOrders = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const bulkActionOptions = [
    {
      label: 'Accept',
      value: 'accept',
    },
    {
      label: 'Reject',
      value: 'reject',
    },
  ];

  const bulkActionOptionsAdmin = [
    {
      label: 'Assign',
      value: 'assign',
    },
    {
      label: 'On Hold',
      value: 'On Hold',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Out Of Stock',
      value: 'Out Of Stock',
    },
  ];

  const defaultConfirmationProps = {
    rejected: [
      {
        type: 'rejectComponent',
        title: 'Reject',
        options: arrayToDropdownOptions(reasonForRejection),
        value: '',
        remarks: '',
        position: 'bottom',
      },
    ],
    cancelled: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <div>
      <OrderPage
        type={orderStatus.assigned}
        bulkActionOptions={isAdmin ? bulkActionOptionsAdmin : bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </div>
  );
};

export default AssignedOrders;
