import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';

const Invoiced = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const bulkActionOptions = [
    {
      label: 'Ready For PickUp',
      value: 'readyForPickup',
    },
  ];

  const bulkActionOptionsAdmin = [
    {
      label: 'Ready For PickUp',
      value: 'readyForPickup',
    },
    {
      label: 'Cancel',
      value: 'cancelOrder',
    },
    {
      label: 'Out Of Stock',
      value: 'Out Of Stock',
    },
    {
      label: 'Reassign',
      value: 'assign',
    },
  ];

  const defaultConfirmationProps = {
    cancelled: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <div>
      <OrderPage
        type={orderStatus.invoiced}
        bulkActionOptions={isAdmin ? bulkActionOptionsAdmin : bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </div>
  );
};

export default Invoiced;
