import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import React, { useState } from 'react';

const ImageGallery = ({ images, title, capturedDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [lensStyle, setLensStyle] = useState({});


  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    setLensStyle({
      backgroundImage: `url(${modalImage})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: `${width * 3}px ${height * 3}px`,
      left: `${e.clientX - left - 50}px`,
      top: `${e.clientY - top - 50}px`,
    });
  };

  const getBase64ImageFromURL = async (url) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/jpeg');
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
    });
  };

  const handleDownload = async (url) => {
    const doc = new jsPDF();
    const images = url;

    await Promise.all(
      images.map(async (each) => {
        const base64Image = await getBase64ImageFromURL(each);

        const imgWidth = 180;
        const imgHeight = 150;
        const position = 10;

        doc.addImage(base64Image, 'JPEG', 10, position, imgWidth, imgHeight);

        doc.addPage();
      })
    );

    doc.save(`POD.pdf`);
  };

  return (
    <div
      style={{
        margin: '10px',
        padding: '10px',
        backgroundColor: '#f8f9fa',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            {title}
          </span>
        </div>

        <button
          style={{
            width: '30px',
            height: '30px',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: !isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          ▶
        </button>
      </div>

      {isOpen && (
        <>
          <div
            style={{
              display: 'flex',
              overflowX: 'scroll',
              marginTop: '10px',
              scrollbarWidth: 'none',
            }}
          >
            {images.map((image, index) => (
              <div
                key={index}
                onClick={() => {
                  handleImageClick(image);
                }}
                style={{
                  flex: '0 0 auto',
                  width: '200px',
                  height: '190px',
                  marginRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fff',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '5px',
            }}
          >
            <div></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {isOpen && (
                <button
                  style={{
                    padding: '6px 12px',
                    marginRight: '10px',
                    backgroundColor: 'white',
                    color: 'black',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleDownload(images);
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} size="xl" />
                </button>
              )}
              {capturedDate && (
                <div
                  style={{
                    backgroundColor: '#F0F0F0',
                    color: 'rgba(68, 68, 68, 0.6)',
                    padding: '5px',
                    textAlign: 'right',
                  }}
                >
                  Captured on: {capturedDate}
                </div>
              )}
            </div>
          </div>
        </>
      )}

  {modalImage && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              position: 'relative',
              width: '80%',
              height: '80%',
            }}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setLensStyle({})}
          >
            <img
              src={modalImage}
              alt="Modal View"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            <div
              style={{
                position: 'absolute',
                width: '250px',
                height: '250px',
                borderRadius: '50%',
                border: '2px solid #fff',
                pointerEvents: 'none',
                backgroundRepeat: 'no-repeat',
                ...lensStyle,
              }}
            ></div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ImageGallery;
