import { Tab, Tabs } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../../components/common/OrderListPage';
import OrderDetails from './OrderDetails';
// import { Tabs, Tab } from 'react-bootstrap';

const DynamicTabs = ({
  activeTab,
  setActiveTab,
  type,
  showFilter,
  ordersData,
  getColumns,
  onRowSelect,
  navigate,
  handleTimeOut,
  bulkActionOptions,
  isAdmin,
  onRefreshClick,
  t,
  breadCrumbItems,
  handleSelectedRowsUpdate,
  setOrdersData,
  setQueryParam,
  uploadButtonProps,
  handleBulkUploadSubmit,
  tabsToRender,
  selectedOrders,setTabsToRender
}) => {
  const [tabs, setTabs] = useState([
    { key: 'Orders List', title: 'Order Listing', content: 'orders' },
    ...tabsToRender.map((each) => ({
      key: `Details-${each.objectId}`,
      title: `Details - ${each.orderNo}`,
      content: 'details',
      objectId: each.objectId,
    })),
  ]);
  useEffect(() => {

    setTabs([
      { key: 'Orders List', title: 'Order List', content: 'orders' },
      ...tabsToRender.map((each) => ({
        key: `Details-${each.objectId}`,
        title: `Details - ${each.orderNo}`,
        content: 'details',
        objectId: each.objectId,
      })),
    ])

  },[tabsToRender])
  const handleTabClose = (key) => {
    const updatedTabs = tabsToRender.filter((tab) => key !== `Details-${tab.objectId}`)
    
    setTabsToRender(updatedTabs);

    if (activeTab === key && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].key);
    }
  };

  return (
    <>
<Tabs
  activeKey={activeTab}
  onSelect={(key) => setActiveTab(key)}
  className="mb-3"
  style={{
    display: 'flex',
    flexWrap: 'nowrap', // Prevent wrapping of tabs
    gap: '10px', // Adds spacing between tabs
    overflowX: 'auto', // Enables horizontal scrolling
    scrollbarWidth: 'thin', // For Firefox, makes the scrollbar thinner
    marginTop:"20px",
    background:"white"
  }}
>
  {tabs.map((tab) => (
    <Tab
      key={tab.key}
      eventKey={tab.key}
      title={
        <span style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
          {tab.title}
          {tab.title !== 'Order List' && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleTabClose(tab.key);
              }}
              style={{
                border: 'none',
                background: 'none',
                color: 'red',
                cursor: 'pointer',
                marginLeft: '5px',
              }}
            >
              ✖
            </button>
          )}
        </span>
      }
      style={{
        flexShrink: 0, // Prevents the tab from shrinking
      }}
    >
      {tab.content === 'orders' && (
        <OrderListPage
          pageType={`${type}orders`}
          showDateFilters={showFilter}
          tableData={ordersData}
          tableColumns={getColumns(
            type,
            onRowSelect,
            navigate,
            handleTimeOut,
            bulkActionOptions,
            isAdmin,
            setTabsToRender,
            setActiveTab
          )}
          handleRefresh={onRefreshClick}
          pageTitle={t(`${type} Orders`)}
          breadCrumbItems={breadCrumbItems}
          enableBulkActions={selectedOrders?.length > 0}
          hideBulkActions={!bulkActionOptions}
          bulkActionOptions={bulkActionOptions}
          handleItemBulkUpdate={handleSelectedRowsUpdate}
          handleSetOrders={setOrdersData}
          setQueryParam={setQueryParam}
          uploadButtonProps={uploadButtonProps}
          handleBulkUploadSubmit={handleBulkUploadSubmit}
        />
      )}
      {tab.content === 'details' && <OrderDetails id={tab.objectId} />}
    </Tab>
  ))}
</Tabs>

<style>
{`
    /* Add inline styles for scrollbars using CSS */ 
    .mb-3::-webkit-scrollbar {
      height: 6px;
    }
    .mb-3::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
    .mb-3::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
</style>



    </>
  );
};

export default DynamicTabs;
