import React, { useState } from 'react';
import { generateRandomId } from '../../utils/string';
import { StyledSearchBar } from '../trips/styles';
import { unpaidTripsColumns } from './utils';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from '@themesberg/react-bootstrap';
import NewTable from '../../components/common/NewTable';
import { useDispatch } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import TripViewModal from './TripViewModal';

const PaidTripsModal = (props) => {
  const { tripsData, showModal, closeModal, invoiceData } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState([]);
  const [showTripsModal, setShowTripsModal] = useState(false);

  const handleViewClick = (id) => {
    const trips = tripsData.find((item) => item.objectId === id)?.trips;
    if (trips.length > 0) {
      setSelectedData(trips);
      setShowTripsModal(true);
    } else {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(`No Trips Closed for this vehicle!`),
        })
      );
    }
  };
  return (
    <>
      <Modal
        id={generateRandomId()}
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={closeModal}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="h6 d-flex justify-content-between w-100">
            <div>{t('Paid Trips Details')}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <Form.Group>
              <Form.Label>Total Trips</Form.Label>
              <StyledSearchBar
                width={'250px'}
                type="number"
                height={'35px'}
                placeholder={tripsData?.length}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Amount</Form.Label>
              <StyledSearchBar
                width={'250px'}
                type="number"
                height={'35px'}
                placeholder={invoiceData?.totalAmount || 0}
                disabled={true}
              />
            </Form.Group>
          </div>
          <NewTable
            data={tripsData}
            columns={unpaidTripsColumns({ handleViewClick })}
            id={'unpaidTripsColumns'}
          />
        </Modal.Body>
      </Modal>
      {selectedData.length > 0 && (
        <TripViewModal
          show={showTripsModal}
          handleClose={() => {
            setShowTripsModal(false);
            setSelectedData([]);
          }}
          tripsData={selectedData}
        />
      )}
    </>
  );
};

export default PaidTripsModal;
