import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import Timeline from './Timeline';
import { apiCall } from '../../../../middlewares/api';
import TableOnly from '../../../scheme-management/Components/IndividualScheme/TableOnly';
import {
  getBreadCrumbRoute,
  orderDetailsColumns,
  showShippingLabelDownload,
} from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../../actions/spinner';
import { updateToastInfo } from '../../../../actions/settings';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Nav,
  Row,
  Tab,
} from '@themesberg/react-bootstrap';
import { orderStatus } from '../../../../constants/orderStatus';
import {
  faBox,
  faClapperboard,
  faDownload,
  faFileInvoice,
  faPerson,
  faReceipt,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayToBreadCrumbs from '../../../../utils/sms/arrayToBreadCrumbs';
import BreadCrumbComponent from '../../../../components/common/Breadcrumb';
import { addTwoHoursOrNextDay, remainingTime } from '../utils';
import CountdownTimer from './CountdownTimer';
import { apiCallConsts } from '../../../../constants/apiCallConsts';
import { Routes } from '../../../../router/routes';
import { returnUserReadableDateTime } from '../../../../utils/datetime';
import {
  faCreditCard,
  faRectangleList,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import PaymentDetailsModal from './PaymentDetailsModal';
import ImageGallery from './ImagesModal';
const getBase64ImageFromURL = async (url) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // Resolve CORS issues if any
  img.src = url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/jpeg'); // Convert the image to base64 (JPEG format)
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
  });
};
const OrderDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('currentStatus');
  const [showModal, setShowModal] = useState(false);
  const [maxCardHeight, setMaxCardHeight] = useState(0);
  const params  = useParams()
  let { id } = props;
  if(!id)
  {
    id = params?.id
  }
  const currStatus = orderDetails[0]?.status;
  const isReadyForPickup = currStatus === orderStatus.readyForPickup || false;
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const navigate = useNavigate();
  const isReverse = orderDetails[0]?.serviceType === 'reverse' || false;
  const breadCrumbItems =
    orderDetails[0]?.isHyperLocal &&
    (currStatus === orderStatus.invoiced ||
      currStatus === orderStatus.readyForPickup)
      ? arrayToBreadCrumbs([
          ['Manual Assign', Routes.OMS.DeliveryAgents.ManualAssign.path],
          [`${id}`],
        ])
      : arrayToBreadCrumbs([[currStatus, -1], [`${id}`]]);

  const handleDownload = async (url) => {
    const doc = new jsPDF();
    const images = [
      ...orderDetails[0]?.podImages,
      orderDetails[0]?.customerSignature,
    ];

    await Promise.all(
      images.map(async (each) => {
        const base64Image = await getBase64ImageFromURL(each);

        const imgWidth = 180;
        const imgHeight = 150;
        const position = 10;

        doc.addImage(base64Image, 'JPEG', 10, position, imgWidth, imgHeight);

        doc.addPage();
      })
    );

    doc.save(`POD${orderDetails}.pdf`);
  };

  const fetchOrderDetails = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const data = await apiCall(
        apiCallConsts.GET_METHOD,
        `${apiCallConsts.ORDER_DETAILS_URL}${id}`,
        { isAdmin: isAdmin }
      );
      setOrderDetails(data);
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to fetch order details'),
          message: t(`${error.message}`),
        })
      );
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll('.detail-card');
    let maxHeight = 0;
    cards.forEach((card) => {
      const height = card.scrollHeight;
      maxHeight = Math.max(maxHeight, height);
    });
    setMaxCardHeight(maxHeight);
  }, [orderDetails]);

  const handleClick = async (type) => {
    let url = '';
    try {
      if (type == 'invoice') {
        dispatch(updateSpinnerState(true));
        const res = await apiCall(
          apiCallConsts.GET_METHOD,
          `${apiCallConsts.INVOICE_DOWNLOAD_URL}`,
          {
            invoiceNo: orderDetails[0]?.invoiceNo,
          }
        );
        dispatch(updateSpinnerState(false));

        url = res?.url;
        // console.log(url);
      } else if (type == 'ewaybill') {
        dispatch(updateSpinnerState(true));
        const res = await apiCall(
          apiCallConsts.GET_METHOD,
          `${apiCallConsts.EWAYBILL_DOWNLOAD_URL}`,
          {
            ewaybill: orderDetails[0]?.ewayBillNo,
            storeCode: orderDetails[0]?.storeCode,
          }
        );
        url = res?.url;
        dispatch(updateSpinnerState(false));
      } else {
        url = orderDetails[0]?.shippingLabelURL;
      }
      if (!url) {
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t(''),
            message: t(`failed to Print ${type}`),
          })
        );
        return;
      }
      if (type === 'shippingLabel') {
        const response = await fetch(url);
        const labelBlob = await response.blob();
        url = URL.createObjectURL(labelBlob);

        await apiCall(
          apiCallConsts.PUT_METHOD,
          `${apiCallConsts.ORDER_UPDATE_URL}/shippingLabel`,
          {
            invoiceNo: orderDetails[0]?.invoiceNo,
          }
        );
      }
      const printWindow = window.open(url, '__blank');
      printWindow.onload = () => {
        printWindow.print();
      };

      // const printWindow = window.open(url, '_blank');
      // printWindow.onload = () => {
      //   printWindow.print();
      // };

      // setTimeout(() => {
      //   printWindow.close();
      // }, 5000);

      // window.open(orderDetails[0]?.shippingLabelURL, "_blank");

      fetchOrderDetails();
    } catch (error) {
      console.error(error);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('failed to Print shipping label'),
          message: t(`${error.message}`),
        })
      );
    }
  };

  let time = remainingTime(orderDetails[0]?.expiresAt?.iso);

  return (
    <>
      <>
        {orderDetails[0] && (
          <>
            <div className="mt-3">
              <BreadCrumbComponent items={breadCrumbItems} />
            </div>
            <div className="d-flex justify-content-between  align-items-center">
              <div className="d-flex gap-3  align-items-center">
                <span className="font-weigth-medium font-size-xl">
                  Order No :{' '}
                  {t(orderDetails[0]?.orderNo ? orderDetails[0]?.orderNo : '')}
                </span>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 text-center my-2">
              <div
                className="bg-white px-3 py-1"
                style={{ width: 'fit-content' }}
              >
                <span className="fw-bold fs-6">Placed On : </span>
                <span className="font-size-sm">
                  {returnUserReadableDateTime(
                    new Date(
                      new Date(orderDetails[0]?.orderDateTime)?.getTime() +
                        5.5 * 60 * 60 * 1000
                    )
                  )}
                </span>
              </div>
              <div
                className="bg-white px-3 py-1"
                style={{ width: 'fit-content' }}
              >
                <span className="fw-bold">Last Updated : </span>
                <span className="font-size-sm">
                  {returnUserReadableDateTime(orderDetails[0]?.updatedAt)}
                </span>
              </div>
              {orderDetails[0]?.courierStatus && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">Courier Status : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.courierStatus || ''}
                  </span>
                </div>
              )}
              {!isReverse && orderDetails[0]?.serialNo && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">Serial No : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.serialNo}
                  </span>
                </div>
              )}

              {orderDetails[0]?.state == 'Cancelled' && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">Remarks : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.remarks}
                  </span>
                </div>
              )}
              {orderDetails[0]?.invoiceNo && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">Invoice No : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.invoiceNo}
                  </span>
                </div>
              )}
              {orderDetails[0]?.ewayBillNo && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">Ewaybill No : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.ewayBillNo}
                  </span>
                </div>
              )}
              {orderDetails[0]?.awbNumber && (
                <div
                  className="bg-white px-3 py-1"
                  style={{ width: 'fit-content' }}
                >
                  <span className="fw-bold">AWB Number : </span>
                  <span className="font-size-sm">
                    {orderDetails[0]?.awbNumber}
                  </span>
                </div>
              )}
            </div>
            <Tab.Container
              activeKey={activeTab}
              onSelect={(selectedTab) => setActiveTab(selectedTab)}
            >
              <Row className="d-flex justify-content-between align-items-center text-center mt-3">
                <Col>
                  <Nav className=" nav-tabs gap-2">
                    <Nav.Item
                      style={{
                        backgroundColor:
                          activeTab === 'currentStatus' ? '#2E3650' : '#FFFFFF',
                        color:
                          activeTab === 'currentStatus' ? '#FFFFFF' : '#2E3650',
                        borderRadius: '4px',
                        width: '180px',
                      }}
                    >
                      <Nav.Link
                        eventKey="currentStatus"
                        className="mb-sm-3 mb-md-0"
                      >
                        {t('Current Status')}
                      </Nav.Link>
                    </Nav.Item>
                    {isAdmin && (
                      <Nav.Item
                        style={{
                          backgroundColor:
                            activeTab === 'rejectionHistory'
                              ? '#2E3650'
                              : '#FFFFFF',
                          color:
                            activeTab === 'rejectionHistory'
                              ? '#FFFFFF'
                              : '#2E3650',
                          borderRadius: '4px',
                          width: '180px',
                        }}
                      >
                        <Nav.Link
                          eventKey="rejectionHistory"
                          className="mb-sm-3 mb-md-0"
                        >
                          {t('Rejection History')}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </Col>
                <Col className="d-flex justify-content-end text-center align-content-center align-items-center gap-4 mt-2">
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: '#F4921B',
                      height: '40px',
                      width: '158px',
                      fontWeight: '500',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    className=" text-uppercase font-size-sm"
                  >
                    <span>{`${currStatus ? currStatus : 'currStatus'}`}</span>
                  </div>
                  {currStatus === orderStatus.assigned ||
                  currStatus === orderStatus.accepted ? (
                    <span className="fs-4" style={{ width: '158px' }}>
                      {time?.mins > 0 &&
                        !(
                          orderDetails[0]?.isLarge ||
                          orderDetails[0]?.isMedium ||
                          orderDetails[0]?.isEndlessAisle
                        ) && (
                          <CountdownTimer
                            hours={time?.hours}
                            mins={time?.mins}
                            callback={() =>
                              navigate(Routes.OMS.Orders.Assigned.path)
                            }
                          />
                        )}
                    </span>
                  ) : (
                    (currStatus === orderStatus?.invoiced ||
                      currStatus === orderStatus?.readyForPickup ||
                      currStatus === orderStatus?.pickedUp ||
                      currStatus === orderStatus?.delivered) &&
                    showShippingLabelDownload(orderDetails[0]) && (
                      <ButtonGroup className="gap-2">
                        {orderDetails[0]?.items?.reduce(
                          (total, items) =>
                            total + items.vsPrice * items.quantity,
                          0
                        ) > 50000 && (
                          <Button
                            variant="white"
                            onClick={() => handleClick('ewaybill')}
                            style={{
                              height: '40px',
                              width: '158px',
                              fontWeight: '500',
                              borderRadius: 0,
                            }}
                            size="sm"
                            disabled={orderDetails?.[0]?.isManualAwb}
                          >
                            <FontAwesomeIcon icon={faReceipt} />
                            &nbsp;&nbsp; ewaybill
                          </Button>
                        )}
                        <Button
                          variant="white"
                          onClick={() => handleClick('invoice')}
                          style={{
                            height: '40px',
                            width: '158px',
                            fontWeight: '500',
                            borderRadius: 0,
                          }}
                          size="sm"
                        >
                          <FontAwesomeIcon icon={faFileInvoice} />
                          &nbsp;&nbsp; Invoice
                        </Button>
                        <Button
                          variant={
                            orderDetails[0]?.isShippingLabelDownloaded
                              ? 'white'
                              : 'primary'
                          }
                          onClick={() => handleClick('shippingLabel')}
                          style={{
                            height: '40px',
                            width: '158px',
                            fontWeight: '500',
                            borderRadius: 0,
                          }}
                          size="sm"
                          disabled={orderDetails?.[0]?.isManualAwb}
                        >
                          <FontAwesomeIcon icon={faBox} />
                          &nbsp;&nbsp; shipping label
                        </Button>
                      </ButtonGroup>
                    )
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="currentStatus" className="py-4">
                      <Card>
                        <Timeline
                          events={orderDetails[0]?.actionLog}
                          rejectedHistory={
                            [
                              'On Hold',
                              'Rejected',
                              'Auto Rejected',
                              'Cancelled',
                              "Received",
                              "QC passed",
                              'Refund Initiated',
                              'Refunded',
                              'RTO In-Transit',"Out Of Stock"
                            ].indexOf(orderDetails[0]?.status) >= 0
                          }
                          authorizationLog={
                            orderDetails[0]?.authorizationLog || []
                          }
                        />
                      </Card>
                    </Tab.Pane>
                    <Tab.Pane eventKey="rejectionHistory" className="py-4">
                      <Card>
                        {orderDetails?.length > 0 && (
                          <Timeline
                            events={
                              orderDetails[0]?.rejectionHistory?.length > 0
                                ? orderDetails[0]?.rejectionHistory
                                : orderDetails[0]?.actionLog || []
                            }
                            authorizationLog={
                              orderDetails[0]?.authorizationLog || []
                            }
                            rejectedHistory={true}
                          />
                        )}
                      </Card>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <Row className="g-3">
              <Col>
                <Card
                  className="p-2 detail-card h-100"
                  style={{
                    height: maxCardHeight ? `${maxCardHeight}px` : 'auto',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      CUSTOMER DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                  <Card.Body className="pt-1 flex-grow-1">
                    <span className="fw-bold">FirstName : </span>
                    {orderDetails[0]?.customer?.firstName == 'guest'
                      ? orderDetails[0]?.shippingAddress?.shippingName ||
                        'guest'
                      : orderDetails[0]?.customer?.firstName || 'guest'}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>
                    {orderDetails[0]?.customer?.mobileNumber || ''}
                    <br />
                    <span className="fw-bold">Email : </span>
                    {orderDetails[0]?.customer?.email || ''}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="p-2 detail-card h-100"
                  style={{
                    height: maxCardHeight ? `${maxCardHeight}px` : 'auto',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      BILLING DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faRectangleList} />
                    </div>
                  </div>
                  <Card.Body className="pt-1 flex-grow-1">
                    <span className="fw-bold">Name : </span>
                    {orderDetails[0]?.shippingAddress?.billingName ||
                      'billingName'}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>{' '}
                    {orderDetails[0]?.shippingAddress?.billingMobileNumber ||
                      ''}
                    <br />
                    <span className="fw-bold">GSTNumber : </span>
                    {orderDetails[0]?.shippingAddress?.gstin || ''}
                    <br />
                    <span className="fw-bold text-wrap">Address : </span>
                    {`${orderDetails[0]?.shippingAddress?.billingAddress1}, ${orderDetails[0]?.shippingAddress?.billingAddress2}, ${orderDetails[0]?.shippingAddress?.billingAddress3}, ${orderDetails[0]?.shippingAddress?.billingCity}, ${orderDetails[0]?.shippingAddress?.billingState}, ${orderDetails[0]?.shippingAddress?.billingPincode} `}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="p-2 detail-card h-100"
                  style={{
                    height: maxCardHeight ? `${maxCardHeight}px` : 'auto',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      SHIPPING DETAILS
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faTruckFast} />
                    </div>
                  </div>
                  <Card.Body className="pt-1 flex-grow-1">
                    <span className="fw-bold">Name : </span>
                    {orderDetails[0]?.shippingAddress?.shippingName || ''}
                    <br />
                    <span className="fw-bold">MobileNumber : </span>{' '}
                    {orderDetails[0]?.shippingAddress?.shippingMobileNumber ||
                      ''}
                    <br />
                    <span className="fw-bold">Address : </span>
                    {`${orderDetails[0]?.shippingAddress?.shippingAddress1}, ${orderDetails[0]?.shippingAddress?.shippingAddress2}, ${orderDetails[0]?.shippingAddress?.shippingAddress3}, ${orderDetails[0]?.shippingAddress?.shippingCity}, ${orderDetails[0]?.shippingAddress?.shippingState}, ${orderDetails[0]?.shippingAddress?.shippingPincode} `}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  border="primary"
                  className="p-2 detail-card h-100"
                  style={{
                    height: maxCardHeight ? `${maxCardHeight}px` : 'auto',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <Card.Subtitle className="pt-2 ps-2">
                      PAYMENT INFO
                    </Card.Subtitle>
                    <div
                      className="bg-light rounded-circle p-2 d-flex justify-content-center align-content-center"
                      style={{ width: 33, height: 33 }}
                    >
                      <FontAwesomeIcon icon={faCreditCard} />
                    </div>
                  </div>
                  <Card.Body className="pt-1 flex-grow-1">
                    <span className="fw-bold">Method : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.method || ''}
                    <br />
                    <span className="fw-bold">Mode : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.paymentMode || ''}
                    <br />
                    <span className="fw-bold">Amount : </span>{' '}
                    {orderDetails[0]?.payment?.[0]?.billAmount || ''}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Card className="p-2 mt-2">
              <Card.Title>Items Ordered</Card.Title>
              <TableOnly
                id="OrderDetails"
                data={orderDetails[0]?.items}
                isFooterRequired={true}
                columns={orderDetailsColumns()}
              />
            </Card>
            {/* {console.log(
              orderDetails[0]?.actionLog?.filter(
                (each) => each?.action == orderStatus?.awaitingInvoice
              )
            )} */}
            {orderDetails[0]?.QcPassedImages?.length && (
              <ImageGallery
                images={orderDetails[0]?.QcPassedImages}
                title={'Proof of QC Pass'}
                capturedDate={returnUserReadableDateTime(
                  orderDetails[0]?.actionLog?.filter(
                    (each) => each?.action == orderStatus?.awaitingInvoice
                  )?.[0]?.time?.iso || new Date()
                )}
              />
            )}
            {orderDetails[0]?.ReadyForPickupImages?.length > 0 && (
              <ImageGallery
                images={orderDetails[0]?.ReadyForPickupImages}
                title={'Proof of Pickup'}
                capturedDate={returnUserReadableDateTime(
                  orderDetails[0]?.actionLog?.filter(
                    (each) => each?.action == orderStatus?.pickedUp
                  )?.[0]?.time?.iso || new Date()
                )}
              />
            )}
            {orderDetails[0]?.podImages?.length > 0 && (
              <ImageGallery
                images={[
                  ...orderDetails[0]?.podImages,
                  orderDetails[0]?.customerSignature,
                ]}
                title={'Proof of Delivery'}
                capturedDate={returnUserReadableDateTime(
                  orderDetails[0]?.actionLog?.filter(
                    (each) => each?.action == orderStatus?.delivered
                  )?.[0]?.time?.iso || new Date()
                )}
              />
            )}
          </>
        )}
      </>
      <PaymentDetailsModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        data={orderDetails[0]}
      />
    </>
  );
};

export default OrderDetails;
