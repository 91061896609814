import { returnUserReadableLocalDate } from '../datetime';

export const exportSchemeDataSummary = (data) => {
  const result = data.map((item) => {
    const time = new Date(item?.validFrom);
    const obj = {
      Company: item?.company?.join(', '),
      ProductCategory: item?.productCategory?.join(', '),
      SubCategory: item?.subCategory.join(', '),
      SchemeCircularNo: item.schemeCircularNo,
      SchemeCircularDate: returnUserReadableLocalDate(item?.schemeCircularDate),
      ValidFrom: returnUserReadableLocalDate(item?.validFrom),
      ValidTill: returnUserReadableLocalDate(item?.validTill),
      Status: item.status,
      Remakrs: item.remarks,
      TotalValue: item?.schemeSummary?.totalValue,
      totalQuantity: item?.schemeSummary?.totalQuantity,
      SchemeType: item.type[0],
    };
    return obj;
  });
  return result;
};

export const exportSchemeData = (data) => {
  return data;
};
