import React, { useEffect, useRef, useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
  Tab,
  Tabs,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { orderTableColumns, ordersDataColumns, searchByFields } from './utils';
import {
  customSizePerPage,
  customTotal,
} from '../../../components/common/parcelStatusPages';
import BreadCrumbComponent from '../../../components/common/Breadcrumb';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import {
  faArrowRotateLeft,
  faBroom,
  faFilter,
  faRefresh,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../../components/common/excel/download';
import DropdownComponent from '../../../components/common/Dropdown';
import { returnRowBGColor } from '../../../utils/parcels';
import EmptyTable from '../../../components/common/EmptyTableView';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useLocation, useNavigate } from 'react-router-dom';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import AsyncDropdown from '../../../components/AsyncDropdown';
import {
  setCompanyAndCategoryDataInStore,
  // setProductListDropdownInStore,
} from '../../../actions/products';
import {
  returnCategoriesDropdownList,
  returnCompaniesDropdownList,
} from '../../../utils/products';
import { getAllCompaniesAndCategoriesData } from '../../../parse-functions/products';
import { setStoresInStore } from '../../../actions/oms/stores';
import { setProductListDropdownInStore } from '../../../actions/oms/allProducts';
import { useFetchOMSProducts } from '../custom-hooks/useFetchOMSProducts';
import { setPageNumberInStore } from '../../../actions/tablePage';
import ExportButton from './components/ExportButton';
import NotAbleToDownload from './components/notAbleToDownload';
import AlreadyModal from './components/AlreayExists';
import OrderDetails from './components/OrderDetails';

const AllOrders = () => {
  const { t } = useTranslation();
  const pageType = 'allOrdersPage';
  const [data, setData] = useState([]);
  const [searchByFieldName, setSearchByFieldName] = useState('');
  const breadCrumbItems = arrayToBreadCrumbs([['orders'], ['data']]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );
  const userName = useSelector((state) => state?.user?.user?.username || '');
  const [searchValue, setSearchValue] = useState('');
  const [isValidInput, setIsValidInput] = useState(true);
  const zones = useSelector((state) => state?.user?.config?.zones || {});
  const companies = useSelector((state) => state?.allProducts?.companies || {});
  const categories = useSelector(
    (state) => state?.allProducts?.categories || {}
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [region, setRegion] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [vanNo, setVanNo] = useState('');
  const [city, setCity] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [serialNo, setSerialNo] = useState('');
  const [deliveryType,setDeliveryType] = useState('')
  const [customerName, setCustomerName] = useState('');
  const [email, setEmail] = useState('');
  const [awbNumber, setAwbNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pgRefNo, setPgRefNo] = useState('');
  const [openFilter, setOpenFilter] = useState(true);
  const [size, setSize] = useState('');
  const [url, setUrl] = useState('');
  const [flag, setFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlreadyModal, setShowAlreadyModal] = useState(false);

  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])

  const [tabs, setTabs] = useState([
    { key: 'Orders List', title: 'Order Listing', content: 'orders' },
    ...tabsToRender.map((each) => ({
      key: `Details-${each.objectId}`,
      title: `Details - ${each.orderNo}`,
      content: 'details',
      objectId: each.objectId,
    })),
  ]);
  useEffect(() => {
    setTabs([
      { key: 'Orders List', title: 'Order List', content: 'orders' },
      ...tabsToRender.map((each) => ({
        key: `Details-${each.objectId}`,
        title: `Details - ${each.orderNo}`,
        content: 'details',
        objectId: each.objectId,
      })),
    ])

  },[tabsToRender])
  const handleTabClose = (key) => {
    const updatedTabs = tabsToRender.filter((tab) => key !== `Details-${tab.objectId}`)
    
    setTabsToRender(updatedTabs);

    if (activeTab === key && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].key);
    }
  };


  const targetRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const totalPages = Math.ceil((data?.length || 0) / 10);
  const storedPage = useSelector((state) => state?.pageNumber?.[pageType] || 1);
  const [currentPage, setCurrentPage] = useState(
    storedPage > totalPages ? 1 : storedPage
  );

  const handlePageChange = (page) => {
    if (pageType) {
      dispatch(setPageNumberInStore({ id: pageType, page }));
    }
  };

  useEffect(() => {
    setCurrentPage(storedPage > totalPages ? 1 : storedPage);
  }, [storedPage, totalPages]);

  const downloadFileFromUrl = (downloadUrl, name) => {
    try {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = name || 'downloaded-file';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      setShowModal(false);
      console.log(`File download started: ${name}`);
    } catch (error) {
      console.error('Error downloading the file via URL:', error);
    }
  };

  const zoneDropDownOptions = (zones = {}) => {
    const returnVal = [{ label: 'All', value: 'all' },{ label: 'Delhi NCR', value: 'ncr' }];
    Object.keys(zones).forEach((zone) => {
      returnVal.push({ label: zone, value: zone });
    });
    return returnVal;
  };
  const [searchData, setSearchData] = useState('');
  const dispatch = useDispatch();
  // useFetchOMSProducts();
  useEffect(() => {
    // getAllCompaniesAndCategories();
    // handleSearchClicked();
    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/products/all?isOnlyVanNo=true'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('vanNo')) {
      setVanNo(queryParams.get('vanNo'));
    }
    if (queryParams.get('orderNo')) {
      setOrderNo(queryParams.get('orderNo'));
    }
    if (queryParams.get('storeCode')) {
      setStoreCode(queryParams.get('storeCode'));
    }
    if (queryParams.get('city')) {
      setCity(queryParams.get('city'));
    }
    if(queryParams.get("deliveryType")){
      setDeliveryType(queryParams.get("deliveryType"))
    }
    if (queryParams.get('serialNo')) {
      setSerialNo(queryParams.get('serailNo'));
    }
    if (queryParams.get('awbNumber')) {
      setAwbNumber(queryParams.get('awbNumber'));
    }
    if (queryParams.get('pgRefNo')) {
      setPgRefNo(queryParams.get('pgRefNo'));
    }
    if (queryParams.get('startDate')) {
      setStartDate(queryParams.get('startDate'));
    }

    if (queryParams.get('endDate')) {
      setEndDate(queryParams.get('endDate'));
    }

    if (queryParams.get('region')) {
      setRegion(queryParams.get('region')?.split(','));
    }

    if (queryParams.get('invoiceNo')) {
      setInvoiceNo(queryParams.get('invoiceNo'));
    }
    if (queryParams.get('contactNo')) {
      setContactNo(queryParams.get('contactNo'));
    }
    if (queryParams.get('size')) {
      setSize(queryParams.get('size'));
    }
    setFlag(!flag);
  }, [location.search]);

  useEffect(() => {
    dispatch(updateSpinnerState(true));
    const data = async () => {
      if (
        orderNo != '' ||
        vanNo != '' ||
        storeCode != '' ||
        startDate != '' ||
        endDate != '' ||
        status != '' ||
        categoryName != '' ||
        companyName != '' ||
        city != '' ||
        contactNo != '' ||
        serialNo != '' ||
        customerName != '' ||
        email != '' ||
        awbNumber != '' ||
        region != '' ||
        pgRefNo != '' ||
        size != ''
      ) {
        await handleSearchClicked(100);
      }
    };
    data();
    dispatch(updateSpinnerState(false));
  }, []);
  const getAllCompaniesAndCategories = async () => {
    if (
      Object.keys(companies).length > 0 &&
      Object.keys(categories).length > 0
    ) {
      return;
    }
    dispatch(updateSpinnerState(true));
    try {
      const data = await getAllCompaniesAndCategoriesData();
      dispatch(setCompanyAndCategoryDataInStore(data));
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Company & Category data'),
          message: t(
            'Please refresh the page to try getting this data again or contact support'
          ),
        })
      );
    }
  };
  const handleSearchClicked = async (limit, isDownload) => {
    dispatch(setPageNumberInStore({ id: pageType, page: 1 }));
    setIsValidInput(true);
    try {
      const queryParams = new URLSearchParams(location.search);

      if (vanNo) {
        queryParams.set('vanNo', vanNo);
      } else {
        queryParams.delete('vanNo');
      }

      if(deliveryType)
      {
        queryParams.set('deliveryType', deliveryType);
      } else {
        queryParams.delete('deliveryType');
      }
      if (orderNo) {
        queryParams.set('orderNo', orderNo);
      } else {
        queryParams.delete('orderNo');
      }
      if (storeCode) {
        queryParams.set('storeCode', storeCode);
      } else {
        queryParams.delete('storeCode');
      }
      if (city) {
        queryParams.set('city', city);
      } else {
        queryParams.delete('city');
      }
      if (serialNo) {
        queryParams.set('serialNo', serialNo);
      } else {
        queryParams.delete('serialNo');
      }
      if (awbNumber) {
        queryParams.set('awbNumber', awbNumber);
      } else {
        queryParams.delete('awbNumber');
      }
      if (pgRefNo) {
        queryParams.set('pgRefNo', pgRefNo);
      } else {
        queryParams.delete('pgRefNo');
      }
      if (status) {
        const statusArray = status?.length > 0 ? status?.join(',') : status;
        queryParams.set('status', statusArray);
      } else {
        queryParams.delete('status');
      }
      if (startDate) {
        queryParams.set('startDate', startDate);
      } else {
        queryParams.delete('startDate');
      }
      if (region) {
        const regionData = region?.length > 0 ? region?.join(',') : region;
        queryParams.set('region', regionData);
      } else {
        queryParams.delete('region');
      }
      if (invoiceNo) {
        queryParams.set('invoiceNo', invoiceNo);
      } else {
        queryParams.delete('invoiceNo');
      }
      if (contactNo) {
        queryParams.set('contactNo', contactNo);
      } else {
        queryParams.delete('contactNo');
      }
      if (endDate) {
        queryParams.set('endDate', endDate);
      } else {
        queryParams.delete('endDate');
      }
      if (size) {
        queryParams.set('size', size);
      } else {
        queryParams.delete('size');
      }
      navigate(`${location.pathname}?${queryParams.toString()}`);

      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        'internal/order/filter',
        {
          vanNo,
          orderNo,
          city,
          categoryName,
          companyName,
          startDate,
          endDate,
          status: status?.length > 0 ? status?.join(',') : status,
          region: region?.length > 0 ? region?.join(',') : region,
          storeCode,
          contactNo,
          invoiceNo,
          serialNo,
          customerName,
          email,
          awbNumber,
          pgRefNo,
          size,
          limit,
          isDownload,
          username: userName,deliveryType
        }
      );

      if (res?.isAlreadyExists) {
        setShowAlreadyModal(true);
      }
      if (isDownload) {
        navigate('/oms/reports/download');
        return '';
      }

      if (res?.data?.length == 0 && res?.isLargeData) {
        setShowModal(true);
      }

      setData(res?.data);
      setUrl(res?.url);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('orders fetched successfully!'),
        })
      );
      dispatch(updateSpinnerState(false));
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } catch (error) {
      if (isDownload) {
        navigate('/oms/reports/download');
        return '';
      }

      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
    }
  };

  const debounce = (func, delay = 500) => {
    let timeoutId;
    return (...args) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    };
  };

  const onDownloadClick = async () => {
    await handleSearchClicked(100, true);
  };

  const handleFilter = () => {
    setOpenFilter((each) => !each);
  };
  const handleReset = () => {
    setVanNo((each) => '');
    setRegion((each) => '');
    setCategoryName((each) => '');
    setCompanyName((each) => '');
    setCity((each) => '');
    setStartDate((each) => '');
    setEndDate((each) => '');
    setStatus((each) => '');
    setStoreCode((each) => '');
    setOrderNo((each) => '');
    setContactNo((each) => '');
    setInvoiceNo((each) => '');
    setSerialNo((each) => '');
    setCustomerName((each) => '');
    setEmail((each) => '');
    setAwbNumber((each) => '');
    setPgRefNo((each) => '');
    setSize((each) => '');
    setDeliveryType("")
    setData([]);

    const queryParams = new URLSearchParams(location.search);

    if (vanNo) {
      queryParams.delete('vanNo');
    }
    if (orderNo) {
      queryParams.delete('orderNo');
    }
    if (storeCode) {
      queryParams.delete('storeCode');
    }
    if (city) {
      queryParams.delete('city');
    }
    if (serialNo) {
      queryParams.delete('serialNo');
    }
    if (awbNumber) {
      queryParams.delete('awbNumber');
    }
    if (pgRefNo) {
      queryParams.delete('pgRefNo');
    }
    if (startDate) {
      queryParams.delete('startDate');
    }
    if (region) {
      queryParams.delete('region');
    }
    if (invoiceNo) {
      queryParams.delete('invoiceNo');
    }
    if (contactNo) {
      queryParams.delete('contactNo');
    }
    if (endDate) {
      queryParams.delete('endDate');
    }
    if (size) {
      queryParams.delete('size');
    }
    if(deliveryType)
    {
      queryParams.delete("deliveryType")
    }
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const fetchOptions = debounce(async (inputValue) => {
    setIsLoadingOptions(true);
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } finally {
      setIsLoadingOptions(false);
    }
  });

  const [storeCode, setStoreCode] = useState('');
  const [status, setStatus] = useState([]);
  const [options, setOptions] = useState(storeList);
  const [orderNo, setOrderNo] = useState('');
  const [statusArray, setStatusArray] = useState([
    { label: 'Assigned', value: 'Assigned' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Awaiting Invoice', value: 'Awaiting Invoice' },
    { label: 'Invoiced', value: 'Invoiced' },
    { label: 'Ready For Pickup', value: 'Ready For Pickup' },
    { label: 'Picked Up', value: 'Picked Up' },
    { label: 'Delivered', value: 'Delivered' },
    {label:"Pending Action" , value : "Pending Action"}
  ]);

  const [deliveryTypeArray, SetDeliveryTypeArray] = useState([
    { label: 'DMS Delivery', value: 'DMSDelivery' },
    { label: 'HyperLocal', value: 'hyperLocal' },
    { label: 'Courier', value: 'courier' },
    { label: 'StorePickup', value: 'storePickup' },
  ]);

  const sizeArray = [
    { label: 'Large', value: 'Large' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Small', value: 'Small' },
  ];

  useEffect(() => {
    const fetchStore = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall('get', 'internal/store/all', {});
        dispatch(setStoresInStore(data));
        console.log(returnWarehouseDropdownOptions(data, false));
        setOptions(returnWarehouseDropdownOptions(data, false));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };
    if (storeList.length === 0) {
      fetchStore();
    }
  }, []);

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },
    { label: 'ORDER NO', value: orderNo, func: setOrderNo },
    {
      label: 'PRODUCT NAME',
      value: vanNo,
      func: setVanNo,
    },
    { label: 'PRODUCT CATEGORY', value: categoryName, func: setCategoryName },
    { label: 'COMPANY', value: companyName, func: setCompanyName },
    {
      label: 'REGION',
      value: region,
      func: setRegion,
    },
    {
      label: 'CITY',
      value: city,
      func: setCity,
    },
    {
      label: 'PRODUCT TYPE',
      value: size,
      func: setSize,
    },
    {
      label: 'STATUS',
      value: status,
      func: setStatus,
    },
    {
      label: 'PHONE NO',
      value: contactNo,
      func: setContactNo,
    },
    { label: 'INVOICE NO', value: invoiceNo, func: setInvoiceNo },
    { label: 'PGREF NO', value: pgRefNo, func: setPgRefNo },
    { label: 'SERIAL NO', value: serialNo, func: setSerialNo },
    {
      label: 'CUSTOMER NAME',
      value: customerName,
      func: setCustomerName,
    },
    {
      label: 'EMAIL',
      value: email,
      func: setEmail,
    },
    {
      label: 'AWB NUMBER',
      value: awbNumber,
      func: setAwbNumber,
    },
    { label: 'STORE NAME', value: storeCode, func: setStoreCode },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
  ];
  const handleEmpty = async (fun) => {
    fun(() => '');
    setFlag((flag) => !flag);
  };

  return (

    <>
    <Tabs
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
      className="mb-3"
      style={{
        display: 'flex',
        flexWrap: 'nowrap', // Prevent wrapping of tabs
        gap: '10px', // Adds spacing between tabs
        overflowX: 'auto', // Enables horizontal scrolling
        scrollbarWidth: 'thin', // For Firefox, makes the scrollbar thinner
        marginTop:"20px",
        background:"white"
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={
            <span style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
              {tab.title}
              {tab.title !== 'Order List' && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTabClose(tab.key);
                  }}
                  style={{
                    border: 'none',
                    background: 'none',
                    color: 'red',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                >
                  ✖
                </button>
              )}
            </span>
          }
          style={{
            flexShrink: 0, // Prevents the tab from shrinking
          }}
        >
          {tab.content === 'orders' && (
            <ToolkitProvider
            exportCSV
            keyField="AllOrdersFetchData"
            search={true}
            sort
            data={data}
            columns={ordersDataColumns(navigate,setTabsToRender, setActiveTab)}
            wrapperClasses="table-responsive"
          >
            {({ baseProps, searchProps, csvProps }) => (
              <Paginator.PaginationProvider
                pagination={Pagination({
                  custom: true,
                  showTotal: true,
                  alwaysShowAllBtns: true,
                  totalSize: data?.length,
                  paginationTotalRenderer: customTotal,
                  sizePerPageRenderer: customSizePerPage,
                  onPageChange: handlePageChange,
                  page: currentPage,
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                      <div className="d-block mb-4 mb-md-0">
                        <BreadCrumbComponent items={breadCrumbItems} />
                        <h4>{t('Orders Data')}</h4>
                        <p className="mb-0">
                          {t('Search for Orders By all Filters')}
                        </p>
                      </div>
                      <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup className="gap-2">
                          <Button
                            variant={openFilter ? 'primary' : 'white'}
                            onClick={() => {
                              handleFilter();
                            }}
                            style={{
                              width: '150px',
                              height: '38px',
                              borderRadius: 0,
                            }}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faSliders} />
                            &nbsp;&nbsp;{t('Filter')}
                          </Button>
                          <Button
                            variant="white"
                            onClick={() => {
                              handleSearchClicked(100000);
                            }}
                            style={{
                              width: '150px',
                              height: '38px',
                              borderRadius: 0,
                            }}
                            size="sm"
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                            &nbsp;&nbsp;{t('Refresh')}
                          </Button>
                          <ExportButton
                            className="btn-export"
                            data={data}
                            columns={[
                              {
                                field: 'Order Date ',
                                key: 'orderDate',
                              },
                              {
                                field: 'Order Time',
                                key: 'orderTime',
                              },
                              { field: 'Order Number', key: 'orderNo' },
                              { field: 'Item Line No', key: 'itemLineNo' },
                              { field: 'CustomerId', key: 'customerId' },
                              { field: 'Internal Status', key: 'status' },
                              { field: 'Customer Status', key: 'orderStatus' },
                              { field: 'Customer Name', key: 'customerName' },
                              { field: 'Mobile Number', key: 'mobileNumber' },
                              { field: 'Email', key: 'email' },
                              {
                                field: 'Billing Address Line 1',
                                key: 'billingAddress1',
                              },
                              {
                                field: 'Billing Address Line 2',
                                key: 'billingAddress2',
                              },
                              { field: 'Billing City', key: 'billingCity' },
                              { field: 'Billing Pincode', key: 'billingPincode' },
                              { field: 'Billing State', key: 'billingState' },
                              {
                                field: 'Shipping Address Line 1',
                                key: 'shippingAddress1',
                              },
                              {
                                field: 'Shipping Address Line 2',
                                key: 'shippingAddress2',
                              },
                              { field: 'Shipping City', key: 'shippingCity' },
                              {
                                field: 'Shipping Pincode',
                                key: 'shippingPincode',
                              },
                              { field: 'Shipping State', key: 'shippingState' },
                              { field: 'VAN No', key: 'vanNo' },
                              { field: 'Product Title', key: 'title' },
                              { field: 'Category', key: 'category' },
                              { field: 'Sub Category', key: 'subCategory' },
                              { field: 'l1', key: 'l1' },
                              { field: 'l2', key: 'l2' },
                              { field: 'l3', key: 'l3' },
                              { field: 'l4', key: 'l4' },
                              { field: 'Brand', key: 'brand' },
                              { field: 'Quantity', key: 'quantity' },
                              { field: 'VSPrice', key: 'vsPrice' },
                              { field: 'Price', key: 'billingAmount' },
                              { field: 'MRP', key: 'mrp' },
                              { field: 'Order Amount', key: 'orderAmount' },
                              { field: 'Invoice No', key: 'invoiceNo' },
                              { field: 'Store Name', key: 'storeCode' },
                              { field: 'Region', key: 'zone' },
                              { field: 'GSTIN', key: 'gstin' },
                              { field: 'Ewaybill No', key: 'ewayBillNo' },
                              { field: 'Approval Code', key: 'approvalCode' },
                              { field: 'Card No', key: 'cardNo' },
                              { field: 'Product Type', key: 'size' },
                              { field: 'Transaction ID', key: 'paymentType' },
                              { field: 'Payment GateWay', key: 'paymentGateway' },
                              { field: 'Payment Status', key: 'paymentStatus' },
                              { field: 'GST', key: 'igst' },
                              {
                                field: 'Expected Delivery Date',
                                key: 'expectedDeliveryDate',
                              },
                              {
                                field : "Delivered Date",
                                key : "deliveredDate"
                              },
                                 {
                                field : "Order Type",
                                key : "orderType"
                              },
                              { field: 'awbNumber', key: 'awbNumber' },
                              { field: 'Bank Name', key: 'bankName' },
                              { field: 'HSN Number', key: 'hsn_number' },
                            ]}
                            fileName={'orderData'}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                    <>
                      <div
                        className="table-settings mt-2 mb-4"
                        style={{
                          background: '#EAEDF2',
                          maxHeight: openFilter ? '700px' : '0px',
                          overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
                          transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
                        }}
                      >
                        <div style={{ gap: 8, display: 'flex' }}>
                          <Form.Group
                            style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(4, 1fr)', // Three columns
                              gap: '20px', // Gap between elements
                              width: '100%',
                              backgroundColor: 'white',
                              padding: 20,
                            }}
                          >
                            <div>
                              <Form.Label>VAN NO.</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={vanNo}
                                onChange={(event) => {
                                  setVanNo(event.target.value);
                                }}
                                placeholder="Enter the VAN no."
                              />
                            </div>
      
                            <div>
                              <Form.Label>PHONE NO</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={contactNo}
                                onChange={(event) => {
                                  setContactNo(event.target.value);
                                }}
                                placeholder="Enter the Phone Number"
                              />
                            </div>
      
                            <div>
                              <Form.Label>INVOICE NO</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={invoiceNo}
                                onChange={(event) => {
                                  setInvoiceNo(event.target.value);
                                }}
                                placeholder="Enter the Invoice No"
                              />
                            </div>
      
                            <div>
                              <Form.Label>PG REF NO</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={pgRefNo}
                                onChange={(event) => {
                                  setPgRefNo(event.target.value);
                                }}
                                placeholder="Enter the PgRef No"
                              />
                            </div>
                            <div>
                              <Form.Label>SERIAL NO</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={serialNo}
                                onChange={(event) => {
                                  setSerialNo(event.target.value);
                                }}
                                placeholder="Enter serial No"
                              />
                            </div>
                            <div>
                              <Form.Label>CUSTOMER NAME</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={customerName}
                                onChange={(event) => {
                                  setCustomerName(event.target.value);
                                }}
                                placeholder="Enter the Customer Name"
                              />
                            </div>
                            <div>
                              <Form.Label>EMAIL</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={email}
                                onChange={(event) => {
                                  setEmail(event.target.value);
                                }}
                                placeholder="Enter the Customer Email"
                              />
                            </div>
                            <div>
                              <Form.Label>AWB NUMBER</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={awbNumber}
                                onChange={(event) => {
                                  setAwbNumber(event.target.value);
                                }}
                                placeholder="Enter the AWB Number"
                              />
                            </div>
      
                            <div>
                              <Form.Label>ORDER NO</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="text"
                                value={orderNo}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    handleSearchClicked();
                                  }
                                }}
                                onChange={(event) => {
                                  setOrderNo(event.target.value);
                                }}
                                placeholder="Enter the Order no."
                              />
                            </div>
      
                            <div>
                              <Form.Label>PRODUCT NAME</Form.Label>
                              <AsyncDropdown
                                onChange={(option) => setVanNo(option.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                disabled={false}
                                fetchOptions={fetchOptions}
                                placeholder={t('Select field to Search by')}
                                isLoading={isLoadingOptions}
                              />
                            </div>
      
                            <div>
                              <Form.Label>STORE NAME</Form.Label>
                              <EditableDropDown
                                id={'StoreName'}
                                onChange={(e) => setStoreCode(e.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                value={storeCode}
                                options={options}
                                placeholder={'Enter the Store Name'}
                              />
                            </div>
      
                            <div>
                              <Form.Label>PRODUCT CATEGORY</Form.Label>
                              <EditableDropDown
                                id={'ProductCategory'}
                                onChange={(e) => setCategoryName(e.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                value={categoryName}
                                options={arrayToDropdownOptions(categories)}
                                placeholder={'Enter the Product Category'}
                              />
                            </div>
      
                            <div>
                              <Form.Label>COMPANY</Form.Label>
                              <EditableDropDown
                                id={'Company'}
                                onChange={(e) => setCompanyName(e.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                value={companyName}
                                options={arrayToDropdownOptions(companies)}
                                placeholder={'Enter the Company name'}
                              />
                            </div>
      
                            <div>
                              <Form.Label>PRODUCT TYPE</Form.Label>
                              <EditableDropDown
                                id={'productType'}
                                onChange={(e) => setSize(e.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                value={size}
                                options={sizeArray}
                                placeholder={'Choose the Product Type'}
                              />
                            </div>
      
                            <div>
                              <Form.Label>CURRENT STATUS</Form.Label>
                              <EditableDropDown
                                id={'CurrentStatus'}
                                onChange={(e) =>
                                  setStatus(e?.map((each) => each.value))
                                }
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isMultiSelect={true}
                                value={status}
                                options={statusArray}
                                placeholder={'Choose the Current Status'}
                              />
                            </div>

                            <div>
                              <Form.Label>DELIVERY TYPE</Form.Label>
                              <EditableDropDown
                                id={'deliveryType'}
                                onChange={(e) =>
                                  setDeliveryType(e?.map((each) => each.value))
                                }
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isMultiSelect={true}
                                value={deliveryType}
                                options={deliveryTypeArray}
                                placeholder={'Choose the Delivery Type'}
                              />
                            </div>
      
                            <div>
                              <Form.Label>REGION</Form.Label>
                              <EditableDropDown
                                id={'Region'}
                                onChange={(e) => {
                                  const values = e.find(
                                    (option) => option.value === 'all'
                                  )
                                    ? Object.keys(zones)?.map((each) => each)
                                    :e.find(option => option.value === 'ncr') ? ['Delhi','Haryana','UP']  :e?.map((each) => each.value);
                                  setRegion(values);
                                }}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isMultiSelect={true}
                                value={region}
                                options={zoneDropDownOptions(zones)}
                                placeholder={'Choose the Region'}
                              />
                            </div>
      
                            {/* <div>
                              <Form.Label>CITY</Form.Label>
                              <EditableDropDown
                                id={'City'}
                                onChange={(e) => setCity(e.value)}
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                value={city}
                                options={statusArray}
                                placeholder={'Choose the City'}
                              />
                            </div> */}
      
                            <div>
                              <Form.Label>START DATE</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                type="date"
                                value={startDate}
                                defaultValue={null}
                                onChange={(event) => {
                                  setIsValidInput(true);
                                  setStartDate(event.target.value);
                                }}
                                placeholder="Start Date"
                              />
                            </div>
      
                            <div>
                              <Form.Label>END DATE</Form.Label>
                              <Form.Control
                                style={{
                                  height: 38,
                                  width: '100%',
                                }}
                                isInvalid={!isValidInput}
                                required
                                value={endDate}
                                type="date"
                                defaultValue={searchData}
                                onChange={(event) => {
                                  setEndDate(event.target.value);
                                }}
                                placeholder="End Date"
                              />
                            </div>
                            <div
                              style={{
                                gridColumn: 'span 4', // Span across all columns
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px',
                              }}
                            >
                              <Button
                                variant="white"
                                className="fa-export"
                                onClick={handleReset}
                                style={{
                                  width: '150px',
                                  height: '38px',
                                  borderRadius: 0,
                                  background: '#EAEDF2',
                                }}
                              >
                                <FontAwesomeIcon icon={faArrowRotateLeft} />
                                &nbsp;&nbsp;{t('Reset')}
                              </Button>
      
                              <Button
                                variant="white"
                                className="fa-export"
                                onClick={() => {
                                  handleSearchClicked(100000);
                                }}
                                style={{
                                  width: '150px',
                                  height: '38px',
                                  borderRadius: 0,
                                  border: '1px solid #262B40',
                                }}
                              >
                                <FontAwesomeIcon icon={faSliders} />
                                &nbsp;&nbsp;{t('Apply Filter')}
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <NotAbleToDownload
                        show={showModal}
                        handleClose={() => setShowModal(false)}
                        onCancel={() => setShowModal(false)}
                        onSuccess={onDownloadClick}
                      />
      
                      <AlreadyModal
                        show={showAlreadyModal}
                        handleClose={() => setShowAlreadyModal(false)}
                        onCancel={() => setShowAlreadyModal(false)}
                        onSuccess={() => {
                          navigate('/oms/reports/download');
                        }}
                      />
                      {!openFilter &&
                        (orderNo != '' ||
                          vanNo != '' ||
                          storeCode != '' ||
                          startDate != '' ||
                          endDate != '' ||
                          status?.length > 0 ||
                          categoryName != '' ||
                          companyName != '' ||
                          city != '' ||
                          contactNo != '' ||
                          serialNo != '' ||
                          customerName != '' ||
                          email != '' ||
                          awbNumber != '' ||
                          region != '' ||
                          pgRefNo != '' ||
                          size != '') && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className="gap-2"
                              style={{
                                display: 'flex',
      
                                width: '100%',
                                background: '#F5F8FB',
      
                                padding: 20,
                              }}
                            >
                              {filterObj.map((each) => {
                                if (each.value != '') {
                                  return (
                                    <div>
                                      <Form.Label style={{ color: '#262B4099' }}>
                                        {each.label}
                                      </Form.Label>
                                      <Badge
                                        variant="light"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          width: '188px',
                                          height: '41px',
                                          background: 'white',
                                          borderRadius: '2px',
                                          cursor: 'pointer',
                                          color: '#262B40',
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '16.41px',
                                        }}
                                      >
                                        {each.value}
                                        <span
                                          onClick={() => {
                                            handleEmpty(each.func);
                                          }}
                                          style={{
                                            color: '#262B40',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '16.41px',
                                          }}
                                        >
                                          &times;
                                        </span>
                                      </Badge>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <Button
                              variant="primary"
                              className="fa-export"
                              onClick={() => {
                                handleReset();
                              }}
                              style={{
                                width: '150px',
                                height: '38px',
                                borderRadius: 0,
                              }}
                            >
                              <FontAwesomeIcon icon={faBroom} />
                              &nbsp;&nbsp;{t('Clear')}
                            </Button>
                          </div>
                        )}
                    </>
                    <Card ref={targetRef}>
                      <div className="table-responsive py-2">
                        <Card.Header
                          style={{
                            padding: '8px 12px',
                          }}
                        >
                          <Row className="d-flex justify-content-end">
                            <Col
                              xs={12}
                              md={4}
                              className="d-flex justify-content-md-end"
                            >
                              <Search.SearchBar
                                {...searchProps}
                                placeholder={t('Search any value in table here')}
                              />
                              <Search.ClearSearchButton {...searchProps} />
                            </Col>
                          </Row>
                        </Card.Header>
                        <Table
                          noDataIndication={<EmptyTable />}
                          {...baseProps}
                          {...paginationTableProps}
                          bodyClasses="border-0"
                          classes="table-flush dataTable-table"
                        />
      
                        <Card.Footer>
                          <Row>
                            <Col xs={12} md={4} className="py-1">
                              <Paginator.SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              className="d-flex align-items-center py-1"
                            >
                              <Paginator.PaginationTotalStandalone
                                {...paginationProps}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                            >
                              <Paginator.PaginationListStandalone
                                {...paginationProps}
                              />
                            </Col>
                          </Row>
                        </Card.Footer>
                      </div>
                    </Card>
                  </>
                )}
              </Paginator.PaginationProvider>
            )}
          </ToolkitProvider>
          )}
          {tab.content === 'details' && <OrderDetails id={tab.objectId} />}
        </Tab>
      ))}
    </Tabs>
    
    <style>
    {`
        /* Add inline styles for scrollbars using CSS */ 
        .mb-3::-webkit-scrollbar {
          height: 6px;
        }
        .mb-3::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }
        .mb-3::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
    </style>
    
    
    
        </>
   
  );
};

export default AllOrders;
