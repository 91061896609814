import React, { useState } from 'react';
import OrderReturnPage from './components/OrderReturnPage';
import { returnOrderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';

const QcCheck = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const bulkActionOptions = [
    {
      label: 'Refund Initiated',
      value: 'refundInitiated',
    },
    {
      label: 'Replacement Initiated',
      value: 'replacementInitiated',
    },
  ];

  const defaultConfirmationProps = {
    refundInitiated: [
      {
        type: 'text',
        title: 'SRT No',
        value: '',
      },
      {
        type: 'text',
        title: 'Remarks',
        value: '',
      },
    ],
  };
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);

  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.QCPASSED}
        hideBulkActions={!isAdmin}
        bulkActionOptions={isAdmin && bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default QcCheck;
