import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { orderTableColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { updateToastInfo } from '../../../actions/settings';
import { Routes } from '../../../router/routes';
import { returnEndOfDay, returnStartOfDay } from '../../../utils/datetime';
import { setFiltersInStore } from '../../../actions/filters';
import { Tab, Tabs } from '@themesberg/react-bootstrap';
import OrderDetails from '../orders/components/OrderDetails';

const Completed = () => {
  const { t } = useTranslation();
  const pageType = 'Completed';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const filtersValue = useSelector((state) => state?.filters?.[pageType] || {});
  const [ordersData, setOrdersData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breadCrumbItems = arrayToBreadCrumbs([
    ['Customer Pickup', Routes.OMS.CustomerPickup.Pending.path],
    ['Completed'],
  ]);

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const handleFiltersSubmit = (startDate, endDate) => {
    dispatch(
      setFiltersInStore({
        pageType,
        data: {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        },
      })
    );
    fetchOrders(startDate, endDate);
  };

  const onRefreshClick = () => {
    const currentStartDate = filtersValue?.startDate
      ? new Date(filtersValue?.startDate)
      : returnStartOfDay(new Date());
    const currentEndDate = filtersValue?.endDate
      ? new Date(filtersValue?.endDate)
      : returnEndOfDay(new Date());
    fetchOrders(currentStartDate, currentEndDate);
  };

  const fetchOrders = async (startDate, endDate) => {
    try {
      dispatch(updateSpinnerState(true));
      const data = {
        storeCode: storeName,
        isCustomerPickup: true,
        status: pageType,
        startDate,
        endDate,
      };
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        apiCallConsts.ORDER_DATE_URL,
        data
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };


  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])

  const [tabs, setTabs] = useState([
    { key: 'Orders List', title: 'Order Listing', content: 'orders' },
    ...tabsToRender.map((each) => ({
      key: `Details-${each.objectId}`,
      title: `Details - ${each.orderNo}`,
      content: 'details',
      objectId: each.objectId,
    })),
  ]);
  useEffect(() => {
    setTabs([
      { key: 'Orders List', title: 'Order List', content: 'orders' },
      ...tabsToRender.map((each) => ({
        key: `Details-${each.objectId}`,
        title: `Details - ${each.orderNo}`,
        content: 'details',
        objectId: each.objectId,
      })),
    ])

  },[tabsToRender])
  const handleTabClose = (key) => {
    const updatedTabs = tabsToRender.filter((tab) => key !== `Details-${tab.objectId}`)
    
    setTabsToRender(updatedTabs);

    if (activeTab === key && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].key);
    }
  };


  return (
    <>
<Tabs
  activeKey={activeTab}
  onSelect={(key) => setActiveTab(key)}
  className="mb-3"
  style={{
    display: 'flex',
    flexWrap: 'nowrap', // Prevent wrapping of tabs
    gap: '10px', // Adds spacing between tabs
    overflowX: 'auto', // Enables horizontal scrolling
    scrollbarWidth: 'thin', // For Firefox, makes the scrollbar thinner
    marginTop:"20px",
    background:"white"
  }}
>
  {tabs.map((tab) => (
    <Tab
      key={tab.key}
      eventKey={tab.key}
      title={
        <span style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
          {tab.title}
          {tab.title !== 'Order List' && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleTabClose(tab.key);
              }}
              style={{
                border: 'none',
                background: 'none',
                color: 'red',
                cursor: 'pointer',
                marginLeft: '5px',
              }}
            >
              ✖
            </button>
          )}
        </span>
      }
      style={{
        flexShrink: 0, // Prevents the tab from shrinking
      }}
    >
      {tab.content === 'orders' && (
       <OrderListPage
       pageType={`${pageType} orders`}
       tableData={ordersData}
       tableColumns={orderTableColumns(null, navigate, null, isAdmin,setTabsToRender, setActiveTab)}
       handleRefresh={onRefreshClick}
       showDateFilters={false}
       filterSubmit={handleFiltersSubmit}
       filtersValue={filtersValue}
       pageTitle={t(`${pageType} Orders`)}
       breadCrumbItems={breadCrumbItems}
       enableBulkActions={false}
       hideBulkActions={true}
     />
      )}
      {tab.content === 'details' && <OrderDetails id={tab.objectId} />}
    </Tab>
  ))}
</Tabs>

<style>
{`
    /* Add inline styles for scrollbars using CSS */ 
    .mb-3::-webkit-scrollbar {
      height: 6px;
    }
    .mb-3::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
    .mb-3::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
</style>

      
    </>
  );
};

export default Completed;
