import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab,
  Row,
  Col,
  Button,
  ButtonGroup,
  Badge,
} from '@themesberg/react-bootstrap';
import TableOnly from '../../scheme-management/Components/IndividualScheme/TableOnly';
import ExportButton from './components/ExportButton';
import { faRefresh, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import FilterOrders from './FIlterOrders';
import { getColumnsForCashifyOrders } from './utils';
import { useDispatch } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import DynamicModal from './components/EnterDetails';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { useNavigate } from 'react-router-dom';
import { updateToastInfo } from '../../../actions/settings';

const IMEIVerificationPage = () => {
  const [key, setKey] = useState('pending');
  const [openFilter, setOpenFilter] = useState(true);
  const [pendingData, setPendingData] = useState([]);
  const [verifiedData, setVerfiedData] = useState([]);
  const [id, setId] = useState({});
  const [tableData, setTableData] = useState([]);
  const [queryParam, setQueryParam] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateMode, setUpdateMode] = useState('');

  const navigate = useNavigate();

  const updateData = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const apiResp = await apiCall(
        apiCallConsts?.PUT_METHOD,
        '/internal/IMEI',
        {
          ...confirmationModalProps,
          status: updateMode,
          id: id.objectId,
        }
      );
      fetchOrder();
      setShowDeleteModal(false);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Success'),
          message: t(`Successfully ${updateMode}`),
        })
      );
      setUpdateMode('');
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Updated'),
          message: t(error.message),
        })
      );
    }
  };

  const handleFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleRefresh = () => {};
  const [showModal, setShowModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState({});

  const handleModalClose = () => setShowModal(false);

  const handleModalSuccess = async () => {
    await updateData('success');
    setShowModal(false);
  };

  const fields = [
    {
      key: 'transactionId',
      label: 'Transaction ID',
      placeholder: 'Enter Transaction ID',
      required: true,
      validation: (value) => value.trim().length > 5, // Example validation
    },
    {
      key: 'date',
      label: 'Date',
      placeholder: 'Select Date',
      type: 'date',
      required: true,
    },
  ];
  const onActionClick = (option, each) => {
    if (option == 'View') {
      navigate(`/oms/orders/${each?.objectId}`);
    }

    if (option == 'Mark as Verified') {
      console.log(each);
      setShowModal(true);
      setId(each);
    }
    if (option == 'unblock') {
      setUpdateMode('unblock');
      setId(each);
      setShowDeleteModal(true);
    }
    if (option == 'cancel') {
      setUpdateMode(() => 'cancel');
      setId(each);
      setShowDeleteModal(true);
    }
  };

  const dispatch = useDispatch();

  const fetchOrder = async () => {
    try {
      dispatch(updateSpinnerState(true));

      let status = 'success';
      if (key == 'pending') {
        status = 'pending';
      }

      const response = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/imei`,
        { ...queryParam, status }
      );

      if (key == 'pending') {
        setPendingData(response?.data || []);
      } else {
        setVerfiedData(response?.data || []);
      }
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Success'),
          message: t(`Successfully fetched`),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed Getting Order Data'),
          message: t(error.message),
        })
      );
    }
  };

  useEffect(() => {
    try {
      fetchOrder();
    } catch (error) {
      console.log(error);
    }
  }, [key, queryParam]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        width: '100%',
        height: '100vh',
        padding: '20px',
        backgroundColor: '#f8f9fa',
        boxSizing: 'border-box',
      }}
    >
      {/* Header */}
      <Row className="mb-2 align-items-center">
        <Col md={8}>
          <h4 className="fw-bold mb-1">
            Payments <span className="text-muted">&gt;</span> IMEI Serial No.
            Validation
          </h4>
          <p className="mb-0 text-muted">
            Use this space to verify sales by entering the IMEI or serial number
          </p>
        </Col>
        <Col md={4} className="text-end"></Col>
      </Row>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div></div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup className="gap-2">
            {/* {pageTitle ===
                          returnOrderStatus.REFUND_INITIATED + ' Orders' && ( */}
            {/* )} */}
            {/* <Button
            variant="primary"
           
          >
            Bulk Verify IMEI/Serial No.
          </Button> */}
            <Button
              variant={openFilter ? 'primary' : 'white'}
              onClick={handleFilter}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
              size="sm"
            >
              <FontAwesomeIcon icon={faSliders} />
              &nbsp;&nbsp;{t('Filter')}
            </Button>

            <Button
              variant="white"
              onClick={handleRefresh}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
              size="sm"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>

            <ExportButton
              data={key == 'pending' ? pendingData : verifiedData}
              columns={[
                { field: 'Order Number', key: 'orderNo' },
                { field: 'Item Line No', key: 'itemLineNo' },
                {
                  field: 'Order Date and Time',
                  key: 'createdAt',
                },
                { field: 'Product Title', key: 'productTitle' },
                { field: 'Internal Status', key: 'status' },
                { field: 'Customer Status', key: 'orderStatus' },
                { field: 'VSPrice', key: 'vsPrice' },
                { field: 'Store Name', key: 'storeCode' },
                { field: 'Region', key: 'zone' },
                { field: 'Store Name', key: 'storeCode' },
                { field: 'Ewaybill No', key: 'ewayBillNo' },
                { field: 'Product Type', key: 'size' },
                { field: 'Customer Name', key: 'customerName' },
                { field: 'Transaction ID', key: 'paymentType' },
                { field: 'Payment Status', key: 'paymentStatus' },
                { field: 'Price', key: 'vsPrice' },
                { field: 'GST', key: 'igst' },
                {
                  field: 'Expected Delivery Date',
                  key: 'expectedDeliveryDate',
                },
                { field: 'AWB Number', key: 'awbNumber' },
                { field: 'Bank Name', key: 'bankName' },
                { field: 'HSN Number', key: 'hsn_number' },
                { field: 'Quantity', key: 'quantity' },
                { field: 'MRP', key: 'mrp' },
                { field: 'Order Amount', key: 'orderAmount' },
              ]}
              fileName={'orderData'}
            />
          </ButtonGroup>
        </div>
      </div>

      {/* Tabs */}

      <FilterOrders
        data={tableData}
        setData={() => {}}
        openFilter={openFilter}
        pageType={''}
        filtersToShow={[
          'vanNo',
          'orderNo',
          'startDate',
          'endDate',
          'pgRefNo',
          'cashifyQuoteId',
          'serialNo',
        ]}
        setQueryParam={setQueryParam}
      />

      <DynamicModal
        show={showModal}
        handleClose={handleModalClose}
        onCancel={handleModalClose}
        onSuccess={handleModalSuccess}
        setConfirmationModalProps={setConfirmationModalProps}
        fields={fields}
      />

      <Tabs id="imei-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="pending" title="Pending Verification">
          <TableOnly
            id="BULKORDER COLUMN"
            data={pendingData}
            columns={getColumnsForCashifyOrders(onActionClick, true)}
          />
        </Tab>
        <Tab eventKey="verified" title="Successfully Verified">
          <TableOnly
            id="BULKORDER COLUMN"
            data={verifiedData}
            columns={getColumnsForCashifyOrders(onActionClick)}
          />
        </Tab>
      </Tabs>

      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={() => {
          updateData();
        }}
        closeModal={() => {
          setShowDeleteModal(false);
        }}
        modalBody={t(
          `Are you sure you want to change the status to ${updateMode}?`
        )}
        modalTitle={t(`${updateMode} Order(s)`)}
      />
    </div>
  );
};

export default IMEIVerificationPage;
