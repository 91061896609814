import React from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../utils/roles';
import { rolesConstants } from '../../../constants/roles';
import { apiCall } from '../../../middlewares/api';

const Cancelled = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);

  const bulkActionOptions = [
    {
      label: 'Stop Cancellation',
      value: 'stopCancellation',
    },
    {
      label: 'Reassign',
      value: 'assign',
    },
  ];
  const username = useSelector((state) => state?.user?.user?.username || '');

  if (
    isAdmin ||
    hasRole(roles, [rolesConstants.ADMIN, rolesConstants.SUPER_ADMIN])
  ) {
    bulkActionOptions.splice(0, 0, {
      label: 'Refund Initated',
      value: 'refundInitated',
    });
  }
  const uploadButtonProps = {
    url : 'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Refund+Initiated+Bulk+Sample.xlsx',
    text : 'Refund Initiated Orders',
    infoModalTitle : 'Refund Initiated Orders',
    targetStatus : "Refund Initiated",
    handleSubmit : async (data) => {

      try{
         const toCallData = data.map((each) => {
           return {
              orderNo : each?.OrderNo || each?.orderNo,
              itemLineNo : each?.ItemLineNo|| each?.itemLineNo,
              remarks : each?.Remarks
           }
         })
         await apiCall('put', `/internal/order/update/bulk`, {
          status : "Refund Initiated",
          data : toCallData , username
        });
      }catch(error)
      {
       throw new Error(error.message)
      }
    
      }
  }


  return (
    <div>
      <OrderPage
        type={orderStatus.cancelled}
        bulkActionOptions={bulkActionOptions}
        uploadButtonProps = {uploadButtonProps}
      />
    </div>
  );
};

export default Cancelled;
