import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setVehicleTypesInStore, setActiveVehicleId } from "../actions/vehicles";
import VehicleCard from "../components/planning/vehicleCard";
import VehicleOrdersTable from "../components/planning/vehicleOrdersTable";
import VehicleAlgoResultCard from "../components/planning/vehicleResultCard";
import { DefaultVehiclesList } from "../constants/vehicles";
import { returnDisplayVehiclesList } from "../utils/vehicles";
import WarningModal from "./components/WarningModal";
import { useNavigate } from "react-router-dom";
import { updateSpinnerState } from "../actions/spinner";
import { getOpenTripsFromServer, updateAllowForToday } from "../parse-functions/trips";
import { updateToastInfo } from "../actions/settings";
import { Routes } from "../router/routes";

const PlanningPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const storeInfo = useSelector((state) => state.user.warehouse || {});
    const isSuperAdmin = useSelector((state) => state.user.preferences.isSuperAdmin || false);
    const [showPendingModal,setShowPendingModal] = useState(false)
    const showWarningModal = useSelector(
        (state) => state.user.config.showWarningModal || ''
      );
    const [count,setCount] = useState(0)
    const vehiclesList = useSelector((state) => state.vehicles.vehiclesList || []);
    const activeVehicleId = useSelector((state) => state?.vehicles?.activeVehicleId || null);
    useEffect(() => {
        if (!Object.keys(vehiclesList).length) {
            dispatch(setVehicleTypesInStore(returnDisplayVehiclesList(DefaultVehiclesList)));
        }
        if (!activeVehicleId && Object.keys(vehiclesList).length) {
            dispatch(setActiveVehicleId(Object.keys(vehiclesList)[0]))
        }
    }, [vehiclesList]);


    const unblockWarehouse = async () => {
        try {
            const tempTrips = await updateAllowForToday({warehouseCode:storeInfo?.warehouseCode});
          
            if(tempTrips)
            {
              setShowPendingModal(false)
            }
            dispatch(updateSpinnerState(false));
          } catch (e) {
            dispatch(updateSpinnerState(false));
            dispatch(updateToastInfo({
              show: true,
              type: 'danger',
              title: t("Error in getting active Trips"),
              message: e.message
            }));
          }

    }
    const getAllTrips = async (requestProps) => {
        dispatch(updateSpinnerState(true));
        try {
          const tempTrips = await getOpenTripsFromServer({isSuperAdmin,warehouseCode:storeInfo?.warehouseCode, isCountOnly : true , startDate : new Date(new Date()?.getTime()-3*24*60*60*1000) ,endDate : new Date(new Date()?.getTime()-1*24*60*60*1000)});
        
          if(tempTrips?.isWarning)
          {
            setShowPendingModal(true)
            setCount(tempTrips.count)

          }
          dispatch(updateSpinnerState(false));
        } catch (e) {
          dispatch(updateSpinnerState(false));
          dispatch(updateToastInfo({
            show: true,
            type: 'danger',
            title: t("Error in getting active Trips"),
            message: e.message
          }));
        }
      };


    useEffect(() => {

        try{
        getAllTrips()
        }catch(error)
        {
            console.log(error)
        }
    },[])
    return (
        <>
        <div className="mt-1">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>{t("Home")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Plan")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{t("Plan Vehicles")}</h4>
                    <p className="mb-0">{t("Prepare temporary trip sheet here")}</p>
                </div>
            </div>
            <Row>
                <Col
                    md={3}
                    lg={3}
                    xl={3}
                    style={{
                        height: '83vh',
                        overflowY: 'scroll'
                    }}>
                    {Object.keys(vehiclesList).map((vehicleId, index) => <VehicleCard vehicle={vehiclesList[vehicleId]} index={index} isActive={activeVehicleId === vehicleId} />)}
                </Col>
                <Col md={9} lg={9} xl={9}>
                    <Row>
                        <Col>
                            <VehicleAlgoResultCard />
                        </Col>
                    </Row>
                    <Row style={{ height: '47vh' }}>
                        <VehicleOrdersTable vehicle={vehiclesList[activeVehicleId]} />
                    </Row>
                </Col>
            </Row>
        </div>
         <WarningModal show={showPendingModal && showWarningModal} handleClose={() => { setShowPendingModal(false)}} onCancel={()=>{ unblockWarehouse() }} onSuccess={()=> {navigate(Routes.Trips.openTrips.path+`?startDate=${new Date(new Date()?.getTime()-3*24*60*60*1000)}&endDate=${new Date(new Date()?.getTime()-1*24*60*60*1000)}`)}} details={
{
    "mainHeading": "Open Trips Detected",
    "warningText": `The system has detected that there are ${count} temposheets still open. Please close all open temposheets before attempting to create a new one. The system will not allow the creation of a new temposheets until all existing trips are closed.`,
    "cancelText": "Allow for today",
    "confirmationText": "I will close all temposheets and prevent this in the future.",
    "confirmationNeeded": "True",
    "confirmText": "View Open temposheets"
}

}/>
        </>
    )
}
export default PlanningPage