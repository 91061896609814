import React, { useEffect, useState } from 'react';
import { Badge, Button, Form } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import { useLocation, useNavigate } from 'react-router-dom';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import EditableDropDown from '../../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import AsyncDropdown from '../../../components/AsyncDropdown';
import {
  setCompanyAndCategoryDataInStore,
  // setProductListDropdownInStore,
} from '../../../actions/products';
import { getAllCompaniesAndCategoriesData } from '../../../parse-functions/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faBroom,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { setStoresInStore } from '../../../actions/oms/stores';
import { setProductListDropdownInStore } from '../../../actions/oms/allProducts';
// import { useFetchOMSProducts } from '../custom-hooks/useFetchOMSProducts';
// import { useFetchOMSCategoriesAndCompanies } from '../custom-hooks/useFetchOMSCategoryAndCompany';
import { useFetchStores } from '../custom-hooks/useFetchStores';
import { setPageNumberInStore } from '../../../actions/tablePage';

const FilterOrders = (props) => {
  const { t } = useTranslation();
  const {
    data,
    setData,
    openFilter,
    pageType,
    filtersToShow = [
      'vanNo',
      'orderNo',
      'storeName',
      'startDate',
      'endDate',
      'region',"productType"
    ],
    filterObject,
    setQueryParam,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const productList = useSelector(
    (state) => state?.allProducts?.productsListDropdown || {}
  );
  const productsListAsOptions = arrayToDropdownOptions(
    Object.keys(productList)
  );
  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const [isValidInput, setIsValidInput] = useState(true);
  const companies = useSelector((state) => state?.allProducts?.companies || {});
  const categories = useSelector(
    (state) => state?.allProducts?.categories || {}
  );
  const isSuperAdmin = useSelector(
    (state) => state?.user?.preferences?.isSuperAdmin || false
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const [vanNo, setVanNo] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [zone, setZone] = useState('');
  const [size, setSize] = useState('');
  const [pgRefNo, setPgRefNo] = useState('');
  const [serialNo, setSerialNo] = useState('');
  const [cashifyQuoteId, setCashifyQuoteId] = useState('');

  const dispatch = useDispatch();

  // useFetchOMSProducts();
  useFetchStores();
  // useFetchOMSCategoriesAndCompanies();
  useEffect(() => {
    const getProductsList = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const products = await apiCall(
          apiCallConsts.GET_METHOD,
          '/internal/serviceability/products'
        );
        dispatch(setProductListDropdownInStore(products));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Failed Getting Products data'),
            message: t(error.message),
          })
        );
      }
    };
    if (Object.keys(productList).length === 0) {
      getProductsList();
    }
  }, []);

  // handleSearchClicked()

  const handleSearchClicked = () => {
    const queryParams = new URLSearchParams(location.search);

    if (vanNo) {
      queryParams.set('vanNo', vanNo);
    }
    if (orderNo) {
      queryParams.set('orderNo', orderNo);
    }
    if (storeCode) {
      queryParams.set('storeCode', storeCode);
    }
    if (startDate) {
      queryParams.set('startDate', startDate);
    }
    if (endDate) {
      queryParams.set('endDate', endDate);
    }
    // if (zone) {
    queryParams.set('zone', zone);
    // }

    if (size) {
      queryParams.set('size', size);
    }
    if (serialNo) {
      queryParams.set('serialNo', serialNo);
    }
    if (cashifyQuoteId) {
      queryParams.set('cashifyQuoteId', cashifyQuoteId);
    }

    if (pgRefNo) {
      queryParams.set('pgRefNo', pgRefNo);
    }
    navigate(`${location.pathname}?${queryParams.toString()}`);

    if (setQueryParam) {
      setQueryParam((pre) => {
        return {
          ...pre,
          vanNo,
          orderNo,
          storeCode,
          startDate,
          endDate,
          zone,
          size,
          pgRefNo,
          serialNo,
          cashifyQuoteId,
        };
      });
    }
  };
  const debounce = (func, delay = 500) => {
    let timeoutId;
    return (...args) => {
      return new Promise((resolve) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          resolve(func(...args));
        }, delay);
      });
    };
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get('vanNo')) {
      setVanNo((each) => queryParams.get('vanNo'));
    }
    if (queryParams.get('orderNo')) {
      setOrderNo((each) => queryParams.get('orderNo'));
    }
    if (queryParams.get('startDate')) {
      setStartDate((each) => queryParams.get('startDate'));
    }
    if (queryParams.get('endDate')) {
      setEndDate((each) => queryParams.get('endDate'));
    }
    if (queryParams.get('zone')) {
      setZone((each) => queryParams.get('zone').split(','));
    }
    if (queryParams.get('storeCode')) {
      setStoreCode((each) => queryParams.get('storeCode'));
    }
    if (queryParams.get('status')) {
      setStatus((each) => queryParams.get('status'));
    }
    if (queryParams.get('status')) {
      setSize((each) => queryParams.get('size'));
    }

    if (queryParams.get('serialNo')) {
      setSerialNo(queryParams.get('serialNo'));
    }
    if (queryParams.get('cashifyQuoteId')) {
      setCashifyQuoteId((each) => queryParams.get('cashifyQuoteId'));
    }

    if (queryParams.get('pgRefNo')) {
      setPgRefNo((each) => queryParams.get('pgRefNo'));
    }
  }, []);
  const handleReset = () => {
    setVanNo((each) => '');
    setZone((each) => '');
    setStartDate((each) => '');
    setEndDate((each) => '');
    setStatus((each) => '');
    setStoreCode((each) => '');
    setOrderNo((each) => '');
    setSize((each) => '');
    setPgRefNo('');
    setCashifyQuoteId('');
    setSerialNo('');

    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('vanNo');
    queryParams.delete('orderNo');
    queryParams.delete('startDate');
    queryParams.delete('endDate');
    queryParams.delete('zone');
    queryParams.delete('storeCode');
    queryParams.delete('status');
    queryParams.delete('size');
    queryParams.delete('serialNo');
    queryParams.delete('pgRefNo');
    queryParams.delete('cashifyQuoteId');

    if (setQueryParam) {
      setQueryParam((pre) => {
        const queryParam = { ...pre };
        const keyToDelete = [
          'vanNo',
          'orderNo',
          'storeCode',
          'startDate',
          'endDate',
          'zone',
          'size',
          serialNo,
          pgRefNo,
          cashifyQuoteId,
        ];
        keyToDelete.map((key) => {
          delete queryParam[key];
        });
        return {
          ...queryParam,
        };
      });
    }
    navigate(`${location.pathname}?${queryParams.toString()}`);
    // handleSearchClicked();
  };
  const fetchOptions = debounce(async (inputValue) => {
    setIsLoadingOptions(true);
    try {
      const filteredOptions = productsListAsOptions.filter((product) =>
        product.value.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } finally {
      setIsLoadingOptions(false);
    }
  });

  const [storeCode, setStoreCode] = useState([]);
  const [status, setStatus] = useState([]);
  const [options, setOptions] = useState(
    returnWarehouseDropdownOptions(storeList, false)
  );
  const [orderNo, setOrderNo] = useState('');
  const [statusArray, setStatusArray] = useState([
    { label: 'Assigned', value: 'Assigned' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Awaiting Invoice', value: 'Awaiting Invoice' },
    { label: 'Invoiced', value: 'Invoiced' },
    { label: 'Ready For Pickup', value: 'Ready For Pickup' },
    { label: 'PickedUp', value: 'PickedUp' },
    { label: 'Delivered', value: 'Delivered' },
  ]);

  const sizeArray = [
    { label: 'Large', value: 'Large' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Small', value: 'Small' },
  ];

  // useEffect(() => {
  //   const fetchStore = async () => {
  //     try {
  //       dispatch(updateSpinnerState(true));
  //       const data = await apiCall('get', 'internal/store/all', {});
  //       console.log(returnWarehouseDropdownOptions(data, false));
  //       dispatch(setStoresInStore(data));
  //       setOptions(returnWarehouseDropdownOptions(data, false));
  //       dispatch(updateSpinnerState(false));
  //     } catch (error) {
  //       dispatch(updateSpinnerState(false));
  //       dispatch(
  //         updateToastInfo({
  //           show: true,
  //           type: 'danger',
  //           title: t('failed to fetch'),
  //           message: t(`${error.message}`),
  //         })
  //       );
  //     }
  //   };
  //   if (storeList.length === 0) {
  //     fetchStore();
  //   }
  // }, []);

  const filterObj = [
    { label: 'VAN NO', value: vanNo, func: setVanNo },
    { label: 'ORDER NO', value: orderNo, func: setOrderNo },
    {
      label: 'PRODUCT NAME',
      value: vanNo,
      func: setVanNo,
    },
    { label: 'STORE NAME', value: storeCode, func: setStoreCode },
    { label: 'START DATE', value: startDate, func: setStartDate },
    { label: 'END DATE', value: endDate, func: setEndDate },
    { label: 'REGION', value: zone, func: setZone },
  ];

  const handleEmpty = (fun) => {
    fun(() => '');
    handleSearchClicked();
  };
  return (
    <>
      <div
        className="table-settings mt-2 mb-4"
        style={{
          background: '#EAEDF2',

          maxHeight: openFilter ? '500px' : '0px',
          overflow: openFilter ? '' : 'hidden', // Ensure content is hidden when collapsed
          transition: 'max-height 0.7s ease-in-out', // Smooth transition when toggling
        }}
      >
        <div style={{ gap: 8, display: 'flex' }}>
          <Form.Group
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)', // Three columns
              gap: '20px', // Gap between elements
              width: '100%',
              backgroundColor: 'white',
              padding: 20,
            }}
          >
            {filtersToShow?.includes('vanNo') && (
              <div>
                <Form.Label>VAN NO.</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={vanNo}
                  onChange={(event) => {
                    setVanNo(event.target.value);
                  }}
                  placeholder="Enter the VAN no."
                />
              </div>
            )}

            {filtersToShow?.includes('orderNo') && (
              <div>
                <Form.Label>ORDER NO.</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={orderNo}
                  onChange={(event) => {
                    setOrderNo(event.target.value);
                  }}
                  placeholder="Enter the Order no."
                />
              </div>
            )}
        {filtersToShow?.includes('productType') &&    <div>
              <Form.Label>PRODUCT TYPE</Form.Label>
              <EditableDropDown
                id={'productType'}
                onChange={(e) => setSize(e.value)}
                style={{
                  height: 38,
                  width: '100%',
                }}
                value={size}
                options={sizeArray}
                placeholder={'Choose the Product Type'}
              />
            </div>}
            {filtersToShow?.includes('productName') && (
              <div>
                <Form.Label>PRODUCT NAME</Form.Label>
                <AsyncDropdown
                  onChange={(option) => setVanNo(option.value)}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  disabled={false}
                  fetchOptions={fetchOptions}
                  placeholder={t('Select field to Search by')}
                  isLoading={isLoadingOptions}
                />
              </div>
            )}

            {filtersToShow?.includes('storeName') &&
            (isAdmin || isSuperAdmin) ? (
              <div>
                <Form.Label>STORE NAME</Form.Label>
                <EditableDropDown
                  id={'StoreName'}
                  onChange={(e) => setStoreCode(e.value)}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={storeCode}
                  options={
                    storeList?.length > 0
                      ? returnWarehouseDropdownOptions(storeList, false)
                      : []
                  }
                  placeholder={'Enter the Store Name'}
                />
              </div>
            ) : (
              <></>
            )}

            {filtersToShow?.includes('region') && (isAdmin || isSuperAdmin) ? (
              <div>
                <Form.Label>Region</Form.Label>
                <EditableDropDown
                  id={'Region'}
                  onChange={(e) => {
                    const values = e.find((each) => each.value === 'All')
                      ? [
                          ...new Set(storeList?.map((store) => store.zone)),
                        ].filter(Boolean)
                      : e.find((option) => option.value === 'Delhi NCR')
                      ? ['Delhi', 'Haryana', 'UP']
                      : e.map((each) => each?.value);
                    setZone(values);
                  }}
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  value={zone}
                  isMultiSelect={true}
                  options={arrayToDropdownOptions(
                    storeList?.length > 0
                      ? [
                          'All',
                          'Delhi NCR',
                          ...new Set(storeList?.map((store) => store.zone)),
                        ].filter(Boolean)
                      : []
                  )}
                  optionColor={'rgba(240, 240, 240, 1)'}
                  placeholder={'Enter the region name'}
                />
              </div>
            ) : (
              <></>
            )}

            {filtersToShow?.includes('startDate') && (
              <div>
                <Form.Label>START DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="date"
                  value={startDate}
                  defaultValue={null}
                  onChange={(event) => {
                    setIsValidInput(true);
                    setStartDate(event.target.value);
                  }}
                  placeholder="Start Date"
                />
              </div>
            )}

            {filtersToShow?.includes('endDate') && (
              <div>
                <Form.Label>END DATE</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  value={endDate}
                  type="date"
                  defaultValue={null}
                  onChange={(event) => {
                    setEndDate(event.target.value);
                  }}
                  placeholder="End Date"
                />
              </div>
            )}

            {filtersToShow?.includes('cashifyQuoteId') && (
              <div>
                <Form.Label>CASHIFY QUOTE ID</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={cashifyQuoteId}
                  onChange={(event) => {
                    setCashifyQuoteId(event.target.value);
                  }}
                  placeholder="Enter the Cashify Quote Id"
                />
              </div>
            )}
            {filtersToShow?.includes('pgRefNo') && (
              <div>
                <Form.Label>PG REF NO</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={pgRefNo}
                  onChange={(event) => {
                    setPgRefNo(event.target.value);
                  }}
                  placeholder="Enter the PgRef No"
                />
              </div>
            )}
            {filtersToShow?.includes('serialNo') && (
              <div>
                <Form.Label>SERIAL NO</Form.Label>
                <Form.Control
                  style={{
                    height: 38,
                    width: '100%',
                  }}
                  isInvalid={!isValidInput}
                  required
                  type="text"
                  value={serialNo}
                  onChange={(event) => {
                    setSerialNo(event.target.value);
                  }}
                  placeholder="Enter serial No"
                />
              </div>
            )}

            <div
              style={{
                gridColumn: 'span 4', // Span across all columns
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <Button
                variant="white"
                className="fa-export"
                onClick={handleReset}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  background: '#EAEDF2',
                }}
              >
                <FontAwesomeIcon icon={faArrowRotateLeft} />
                &nbsp;&nbsp;{t('Reset')}
              </Button>

              <Button
                variant="white"
                className="fa-export"
                onClick={handleSearchClicked}
                style={{
                  width: '150px',
                  height: '38px',
                  borderRadius: 0,
                  border: '1px solid #262B40',
                }}
              >
                <FontAwesomeIcon icon={faSliders} />
                &nbsp;&nbsp;{t('Apply Filter')}
              </Button>
            </div>
          </Form.Group>
        </div>
      </div>

      {!openFilter &&
        (orderNo != '' ||
          zone != '' ||
          vanNo != '' ||
          storeCode != '' ||
          startDate != '' ||
          endDate != '') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className="gap-2"
              style={{
                display: 'flex',

                width: '100%',
                background: '#F5F8FB',

                padding: 20,
              }}
            >
              {filterObj.map((each) => {
                if (each.value != '') {
                  return (
                    <div>
                      <Form.Label style={{ color: '#262B4099' }}>
                        {each.label}
                      </Form.Label>
                      <Badge
                        variant="light"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '188px',
                          height: '41px',
                          background: 'white',
                          borderRadius: '2px',
                          cursor: 'pointer',
                          color: '#262B40',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '16.41px',
                        }}
                      >
                        {each.value}
                        <span
                          onClick={() => {
                            handleEmpty(each.func);
                          }}
                          style={{
                            color: '#262B40',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '16.41px',
                          }}
                        >
                          &times;
                        </span>
                      </Badge>
                    </div>
                  );
                }
              })}
            </div>
            <Button
              variant="primary"
              className="fa-export"
              onClick={handleReset}
              style={{
                width: '150px',
                height: '38px',
                borderRadius: 0,
              }}
            >
              <FontAwesomeIcon icon={faBroom} />
              &nbsp;&nbsp;{t('Clear')}
            </Button>
          </div>
        )}
    </>
  );
};

export default FilterOrders;
