import React, { useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from '@themesberg/react-bootstrap';
import OrderDetails from '../../pages/oms/orders/components/OrderDetails';

const ViewModal = ({ rowId }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Trigger Button */}
      <FontAwesomeIcon
        icon={faArrowRight}
        style={{ cursor: 'pointer' }}
        onClick={handleShow}
      />

      {/* Modal */}
      <Modal 
        show={show} 
        onHide={handleClose} 
        style={{
          position: 'fixed', // Position it independently of the parent
          top: 0,
          left: 0,
          width: '100vw', // Full width of the viewport
          height: '100vh', // Full height of the viewport
          zIndex: 1050, // Ensure it's on top of other content
          backgroundColor : "#F5F8FB",
          margin: 0,
        }}

        fullscreen
      >
     <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
  <Modal.Title>Order Details</Modal.Title>
  <div 
    style={{ 
      display: "flex", 
      alignItems: "center", 
      cursor: "pointer", 
      color: "red", 
      fontSize: "14px" 
    }} 
    onClick={handleClose}
  >
    <button
      type="button"
      className="btn-close"
      aria-label="Close"
      style={{ 
        marginRight: "5px", 
        backgroundColor: "transparent", 
        border: "none", 
        outline: "none" 
      }}
    ></button>
    <span style={{ fontWeight: "500", fontSize:"18px" }}>Close</span>
  </div>
</Modal.Header>



        <Modal.Body style={{

backgroundColor : "#F5F8FB",

        }}>
          {/* Pass rowId to the OrderDetails component */}
          <OrderDetails id={rowId} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewModal;

  
