import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from '@themesberg/react-bootstrap';
import { getOrderCount } from './utils';
import { returnCustomerAddressString } from '../../utils/table';
import NewTable from '../../components/common/NewTable';
import {
  faCheck,
  faCircleInfo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const TripEditModal = ({
  show,
  handleClose,
  tripDetails,
  onSubmit,
  handleSaveClick,
}) => {
  const [parcels, setParcels] = useState([]);
  const [parcelCalculation, setParcelCalculation] = useState([]);
  const [focusRowId, setFocusRowId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (tripDetails) {
      setParcels(tripDetails.parcels || []);
      if (tripDetails.trip?.parcelCalculation) {
        const parcelCalculation = JSON.parse(
          tripDetails.trip?.parcelCalculation
        );
        setParcelCalculation(
          Object.keys(parcelCalculation).map((item) => {
            const matchedParcels = tripDetails.parcels.filter((parcel) => {
              const keyName = `${parcel?.customerName?.toLowerCase()}-${
                parcel.mobileNo
              }`;
              return keyName === item;
            });
            const extraCharges = matchedParcels.reduce((sum, parcel) => {
              return sum + (parcel.voucherDetails?.invoiceAmount || 0);
            }, 0);
            const deliveryCost =
              parcelCalculation[item].deliveryCost ||
              (item.category !== 'gt'
                ? getOrderCount(parcelCalculation[item].count)
                : 1) * tripDetails.trip.perDeliveryRate;
            return {
              keyName: item,
              customerName: item.split('-')[0],
              mobileNo: item.split('-')[1],
              count: parcelCalculation[item].count,
              perDeliveryRate:
                parcelCalculation[item].perDeliveryRate ||
                tripDetails?.trip?.perDeliveryRate,
              deliveryCost,
              extraCharges,
              totalAmount: deliveryCost + extraCharges,
            };
          })
        );
      }
    }
  }, [tripDetails]);

  const renderToolTip = (props, row) => {
    const groupedInvoices = Array.from(
      new Set(
        parcels
          .map((parcel) => {
            const key = `${parcel.customerName.toLowerCase()}-${
              parcel.mobileNo
            }`;
            if (key === row.keyName) {
              return parcel.invoiceNo;
            }
          })
          .filter((invoice) => invoice !== undefined)
      )
    );

    return (
      <Tooltip id="button-tooltip" {...props}>
        {groupedInvoices.map((invoice, index) => (
          <div key={index}>Invoice No:{invoice}</div>
        ))}
      </Tooltip>
    );
  };

  const renderChallanToolTip = (props, parcels) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {parcels.map((parcel, index) => (
          <>
            <div key={index}>Voucher No: {parcel.voucherDetails?.voucher}</div>
            <div key={index}>
              Payment Type: {parcel.voucherDetails?.paymentType}
            </div>
            <div key={index}>
              Remarks: {parcel.voucherDetails?.remarks || ' '}
            </div>
          </>
        ))}
      </Tooltip>
    );
  };

  const handleInputChange = (e, keyName) => {
    const { value } = e.target;
    const currentCalculation = parcelCalculation.map((item) => {
      if (item.keyName === keyName) {
        return {
          ...item,
          perDeliveryRate: value,
          deliveryCost:
            (item.category !== 'gt' ? getOrderCount(item.count) : 1) * value,
        };
      }
      return item;
    });
    setParcelCalculation(currentCalculation);
  };

  const handleSubmit = () => {
    const data = parcelCalculation.map((item) => ({
      keyName: item.keyName,
      count: item.count,
      perDeliveryRate: item.perDeliveryRate,
      deliveryCost: item.deliveryCost,
      totalAmount: item.deliveryCost + item.extraCharges,
    }));
    const extraCharges = parcels.reduce(
      (acc, item) => acc + (item.voucherDetails?.invoiceAmount || 0),
      0
    );
    const totalAmount = data.reduce((acc, item) => acc + item.deliveryCost, 0);
    const totalCost = extraCharges + totalAmount;
    onSubmit({
      totalCost,
      data,
      vendorName: tripDetails.trip?.driverInfo?.vendorName,
      driverName: tripDetails.trip?.driverInfo?.driverName,
      totalQuantity:
        (tripDetails?.trip?.deliveryRateInfo?.deliveryQuantity || 0) +
        (tripDetails?.trip?.deliveryRateInfo?.package || 0) +
        (tripDetails?.trip?.deliveryRateInfo?.defectivePicked || 0),
      totalAmount,
      tripId: tripDetails.trip?.objectId,
      extraCharges,
    });
  };

  const columns = [
    {
      dataField: 'customerName',
      text: 'Customer Name',
      formatter: (cell, row) => {
        return (
          <div className="row d-flex">
            {returnCustomerAddressString(cell, `${row.mobileNo}`, 150, 35)}
          </div>
        );
      },
    },
    {
      dataField: 'keyName',
      text: 'Address',
      formatter: (cell, row) => {
        const address = parcels.find((item) => {
          const keyName = `${item?.customerName?.toLowerCase()}-${
            item.mobileNo
          }`;
          return keyName === cell;
        });
        let shippingAddress, shippingPincode;
        if (address) {
          shippingAddress = `${address?.shippingAddress1 || ''} ${
            address?.shippingAddress2 || ''
          } ${address?.shippingAddress3 || ''}`;
          shippingPincode = address?.shippingPincode || '';
        }
        return (
          <div>
            {returnCustomerAddressString(
              shippingAddress || 'No Address Found',
              shippingPincode,
              150,
              35
            )}
          </div>
        );
      },
    },
    {
      dataField: 'count',
      text: 'Delivered',
      formatter: (cell, row) => {
        return (
          <div className="d-flex gap-2">
            <div>{cell}</div>
            <div className="mb-1">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderToolTip(props, row)}
              >
                <div>
                  <FontAwesomeIcon icon={faCircleInfo} className="fs-6" />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'count',
      text: 'Quantity',
      formatter: (cell, row) => {
        return <div>{row.category !== 'gt' ? getOrderCount(cell) : 1}</div>;
      },
    },
    {
      dataField: 'perDeliveryRate',
      text: 'Per Delivery Rate ',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Form.Group>
            <Form.Control
              type="number"
              id={row.keyName}
              value={cell}
              onChange={(e) => formatExtraData.handleRowChange(e, row.keyName)}
              style={{ width: '100px' }}
              onFocus={() => formatExtraData.handleFocusChange(row?.keyName)}
              onClick={() => formatExtraData.handleFocusChange(row?.keyName)}
              autoFocus={row?.keyName === focusRowId}
              disabled={!handleSaveClick}
            />
          </Form.Group>
        );
      },
      formatExtraData: {
        handleRowChange: (e, value) => {
          handleInputChange(e, value);
        },
        handleFocusChange: (id) => {
          setFocusRowId(id);
        },
      },
    },
    {
      dataField: 'extraCharges',
      text: 'Extra Charges',
      formatter: (cell, row) => {
        const matchedParcels = parcels.filter((item) => {
          const keyName = `${item?.customerName?.toLowerCase()}-${
            item.mobileNo
          }`;
          return keyName === row.keyName && item.voucherDetails;
        });

        if (!matchedParcels || matchedParcels.length === 0) {
          return <div>-</div>;
        }
        return (
          <div className="d-flex gap-2">
            <div>{cell}</div>
            <div className="mb-1">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderChallanToolTip(props, matchedParcels)}
              >
                <div>
                  <FontAwesomeIcon icon={faCircleInfo} className="fs-6" />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'deliveryCost',
      text: 'Total Amount',
    },
  ];

  return (
    <Modal show={show} centered onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Trip Details - {tripDetails?.trip?.tripNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex gap-2">
          <Form.Group className="w-100">
            <Form.Label>Total Deliveries Count</Form.Label>
            <Form.Control
              type="text"
              value={
                (tripDetails?.trip?.deliveryRateInfo?.deliveryQuantity || 0) +
                (tripDetails?.trip?.deliveryRateInfo?.package || 0) +
                (tripDetails?.trip?.deliveryRateInfo?.defectivePicked || 0)
              }
              disabled
            />
          </Form.Group>
          <Form.Group className="w-100">
            <Form.Label>Total Cost</Form.Label>
            <Form.Control
              type="text"
              value={parcelCalculation.reduce(
                (acc, item) => acc + item.deliveryCost,
                0
              )}
              disabled
            />
          </Form.Group>
        </Form>
        <NewTable
          columns={columns}
          data={parcelCalculation}
          id="tripeditmodal"
        />
      </Modal.Body>
      {handleSaveClick && (
        <Modal.Footer>
          <Button
            className="text-gray"
            onClick={handleClose}
            style={{
              backgroundColor: '#F0F0F0',
              color: '#444444',
              border: 'None',
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
            &nbsp;&nbsp;
            {t('Cancel')}
          </Button>
          <Button
            style={{
              backgroundColor: '#444444',
              color: '#FFFFFF',
              border: 'None',
            }}
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faCheck} />
            &nbsp;&nbsp;
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default TripEditModal;
