import React, { useState } from "react";
import OrderReturnPage from "./components/OrderReturnPage";
import { returnOrderStatus } from "../../../constants/orderStatus";
import { useSelector } from "react-redux";
import { apiCall } from "../../../middlewares/api";

const RefundInitiated = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const username =useSelector((state) => state?.user?.user?.username || '');

  const uploadButtonProps = {
    url : 'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Refund+Bulk+Sample.xlsx',
    text : 'Refunded Bulk Orders',
    infoModalTitle : 'Refunded Orders',
    targetStatus : "To Be Invoiced",
    handleSubmit : async (data) => {

      try{
         const toCallData = data.map((each) => {
          return {
            pgRefNo : each?.["PGREF NO"],
            amount : each?.["TRANSACTION AMOUNT"],
            transcationStatus : each?.["TRANSACTION STATUS"],
             transactionNo :   each?.["TRANSACTION NO"]
         }
         })
         await apiCall('put', `/internal/order/update/bulk`, {
          status : "Refunded",
          data : toCallData,username
        });
      }catch(error)
      {
       throw new Error(error.message)
      }
    
      }
  }




  const bulkActionOptions = [
    {
      label: "Refund Completed",
      value: "refunded",
    },
  ];

  const defaultConfirmationProps = {
    refunded: [
      {
        type: "date",
        title: "Transaction Date",
        value: "",
      },
      {
        type: "text",
        title: "Amount",
        value: "",
      },
      {
        type: "text",
        title: "Transaction No.",
        value: "",
      },
      {
        type: "text",
        title: "Mode",
        value: "",
      },
      {
        type: "textArea",
        title: "Remarks",
        value: "",
      },
    ],
  };

  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  return (
    <>
      <OrderReturnPage
        type={returnOrderStatus.REFUND_INITIATED}
        hideBulkActions={!isAdmin}
        bulkActionOptions={isAdmin && bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps} uploadButtonProps={uploadButtonProps} 
      />
    </>
  );
};

export default RefundInitiated;
