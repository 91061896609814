import React from 'react';
import { getTripBadgeStatus } from './trips';
import { Badge } from '@themesberg/react-bootstrap';
import { getTableRowCheckBox } from './table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

export const vehicleAttendanceReportTableColumns = (headers) => {
  return headers?.map((keyName) => ({
    dataField: keyName,
    text: keyName,
    style: (cell) => ({
      backgroundColor: cell === 'P' ? '#caf5d5' : cell === 'A' ? '#f09693' : '',
    }),
  }));
};

export const monthlyVehicleDeliveryReportColumns = () => {
  return [
    { dataField: 'vehicleNumber', text: 'Vehicle Number' },
    { dataField: 'vehicleType', text: 'Vehicle Type' },
    { dataField: 'deliveryDate', text: 'Delivery Date' },
    { dataField: 'warehouseCode', text: 'Branch Name' },
    { dataField: 'vendorName', text: 'Vendor Name' },
    { dataField: 'products', text: 'Products' },
    { dataField: 'deliveryQuantity', text: 'Delivery Quantity' },
    { dataField: 'isDisplay', text: 'Is Display' },
    { dataField: 'branchDroppedAt', text: 'Branches' },
    { dataField: 'delivered', text: 'Delivered' },
    { dataField: 'pending', text: 'Pending' },
    { dataField: 'droppedathub', text: 'Dropped At Hub' },
    { dataField: 'returnok', text: 'Return OK' },
    { dataField: 'defectivepicked', text: 'Return Defective' },
  ];
};

export const vehiclePaymentsReportColumns = ({
  onRowSelect,
  handleViewClick,
}) => {
  const columns = [
    {
      dataField: 'objectId',
      text: 'Select',
      formatter: (cell, row) => {
        return getTableRowCheckBox(onRowSelect, cell, row?.isSelected, false);
      },
    },
    {
      dataField: 'date',
      text: 'Date',
    },
    {
      dataField: 'vehicleDetails',
      text: 'Vehicle Number',
      formatter: (cell, row) => {
        return (
          <>
            <div>{cell.vehicleNumber}</div>
            <div>{cell.vehicleType}</div>
          </>
        );
      },
    },
    {
      dataField: 'vehicleDetails.vendorName',
      text: 'Vendor',
      formatter: (cell, row) => {
        return cell;
      },
    },
    {
      dataField: 'attendance',
      text: 'Attendance',
      formatter: (cell, row) => {
        return (
          <>
            <div>Days Present: {`${row.totalPresent}/${row.totalDays}`}</div>
            <div>Trips: {row.totalTrips}</div>
            <div>Pending Trips: {row.pendingTrips}</div>
          </>
        );
      },
    },
    {
      dataField: 'vehicleDetails',
      text: 'Payment Details',
      formatter: (cell, row) => {
        return (
          <>
            <div>Type: {cell.paymentMode}</div>
            <div>Rate: {row.deliveryAmount.join(',')}</div>
          </>
        );
      },
    },
    {
      dataField: 'deliverySummary',
      text: 'Delivery Summary',
      formatter: (cell, row) => {
        return (
          <>
            <div>Delivered: {row.totalDeliveryQuantity}</div>
            <div>Delivery Attempted: {row.totalDeliveryAttempted}</div>
            <div>Dropped At Hub: {row.droppedAtHub}</div>
          </>
        );
      },
    },
    {
      dataField: '',
      text: 'Display/GT Summary',
      formatter: (cell, row) => {
        return (
          <>
            <div>Total Display: {row.totalDisplay}</div>
            <div>Display/GT Products: {row.totalDisplayProducts}</div>
          </>
        );
      },
    },
    // {
    //   dataField: 'deliveryAmount',
    //   text: 'RATE',
    //   formatter: (cell, row) => {
    //     return cell.map((item) => <div>{item}</div>);
    //   },
    // },
    // {
    //   dataField: 'totalDays',
    //   text: 'Total Days',
    // },
    // {
    //   dataField: 'totalPresent',
    //   text: 'Total Present',
    // },
    // {
    //   dataField: 'totalTrips',
    //   text: 'Total Trips',
    // },
    // {
    //   dataField: 'totalDeliveryAttempted',
    //   text: 'Delivery Attempted',
    // },
    // {
    //   dataField: 'totalDeliveryQuantity',
    //   text: 'Delivered',
    // },
    // {
    //   dataField: 'droppedAtHub',
    //   text: 'DroppedAtHub',
    // },
    // {
    //   dataField: 'totalIsDisplay',
    //   text: 'Total Display/GT',
    // },
    // {
    //   dataField: 'totalDisplayProducts',
    //   text: 'Total GT Products',
    // },

    {
      dataField: 'totalAmount',
      text: 'Total Amount',
      formatter: (cell, row) => {
        return <div>{cell.toFixed(2)}</div>;
      },
    },
    // {
    //   dataField: 'paymentStatus',
    //   text: 'Payment Status',
    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         {cell.unpaid > 0 && (
    //           <div>
    //             <Badge bg={getTripBadgeStatus('unpaid')} className="badge-lg">
    //               {'UNPAID'}
    //             </Badge>
    //           </div>
    //         )}
    //         {cell.paid > 0 && (
    //           <div>
    //             <Badge bg={getTripBadgeStatus('paid')} className="badge-lg">
    //               {'PAID'}
    //             </Badge>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: 'view',
      text: 'View',
      formatter: (cell, row) => {
        return (
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => handleViewClick(row.objectId)}
          />
        );
      },
    },
  ];

  return columns;
};
