import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@themesberg/react-bootstrap';
import TableOnly from '../../scheme-management/Components/IndividualScheme/TableOnly';
import { generateRandomId } from '../../../utils/string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faIdBadge,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

const AgentAssignmentModal = ({
  showModal,
  closeModal,
  modalTitle,
  agents = [],
  size = 'md',
  onAssignAgent,
}) => {
  const { t } = useTranslation();

  // Define the columns for the TableOnly component
  const columns = [
    {
      dataField: 'driverName',
      text: t('Agent Name'),
      // Add an icon and tick badge for recommended agents
      formatter: (cell, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Agent Name + Recommended Icon */}
          <span>
            {row.isRecommended && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: 'green', marginRight: '5px' }}
                size="l"
              />
            )}
            {cell}
          </span>
        </div>
      ),
    },
    {
      dataField: 'contactNumber',
      text: t('Contact Number'),
    },
    {
      dataField: 'distance',
      text: t('Distance'),
      formatter: (cell, row) => `${cell} km`,
    },
    {
      dataField: 'assignedOrders',
      text: t('Currently Assigned'),
    },
    {
      text: t('Assign'),
      formatter: (cell, row) => (
        <Button
          variant="primary"
          onClick={() => onAssignAgent(row)} // Assign agent callback
        >
          {t('Confirm')}
        </Button>
      ),
    },
  ];

  //AssignModal with distances

  return (
    <Modal
      id={generateRandomId()}
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size={size}
    >
      <Modal.Header>
        <Modal.Title className="h6">{t(modalTitle)}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <TableOnly
          id="assignAgentModal"
          data={agents}
          columns={columns}
          showRowBgColor={true}
          // Highlight all recommended rows with a light gray background
          rowStyle={(row) => ({
            backgroundColor: row.isRecommended ? '#d9edf7' : '', // Custom background color for recommended rows
            fontWeight: row.isRecommended ? 'bold' : 'normal', // Bold font for recommended rows
            border: row.isRecommended ? '1px solid #a6a6a6' : '', // Subtle border for recommended rows
          })}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AgentAssignmentModal;
