import React, { useState } from 'react';
import ApprovedChallanFilters from './Components/ApprovedChallanFilters';
import NewTable from '../../components/common/NewTable';
import { voucherApprovedColumns, VouchersSentColumns } from './utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import { getPaymentVouchers } from '../../parse-functions/payments';
import { hasRole } from '../../utils/roles';
import { rolesConstants } from '../../constants/roles';
import ImageOnlyModal from './Components/ImageOnlyModal';

const ApprovedChallans = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state?.user?.warehouse || {});
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const [vouchers, setVouchers] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);

  const handleApplyFilters = async (filters) => {
    // console.log(filters);
    let existFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined && filters[key] !== '') {
        existFilters[key] = filters[key];
      }
    });
    if (hasRole(roles, [rolesConstants.ADMIN])) {
      existFilters.isAdmin = true;
    } else {
      existFilters.createdByBranch = warehouse?.warehouseName;
    }
    //  console.log(existFilters);
    try {
      dispatch(updateSpinnerState(true));
      const response = await getPaymentVouchers({
        filters: {
          ...existFilters,
        },
      });
      //   console.log(response);
      setVouchers(response);
    } catch (error) {
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'failed!',
          message: error.message,
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const onSignatureClick = (url) => {
    // console.log(url);
    setModalImageUrl(url);
    setShowImageModal(true);
  };

  const onPrintClick = (id) => {
    dispatch(updateSpinnerState(true));
    try {
      window.open(`/voucher/${id}`, '_blank');
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('Voucher Printed Successfully.'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
    }
  };

  return (
    <>
      <h4>{'Approved payments'}</h4>
      <ApprovedChallanFilters
        onApplyFilter={handleApplyFilters}
        onReset={() => setVouchers([])}
      />
      <NewTable
        id={'ApprovedChallansTable'}
        data={vouchers || []}
        columns={voucherApprovedColumns(onSignatureClick, onPrintClick)}
      />
      {showImageModal && (
        <ImageOnlyModal
          showModal={showImageModal}
          closeModal={() => setShowImageModal(false)}
          imageUrl={modalImageUrl}
        />
      )}
    </>
  );
};

export default ApprovedChallans;
