import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap-table2-paginator';
import * as Paginator from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BreadCrumbComponent from '../../components/common/Breadcrumb';
import { vehiclePaymentsReportColumns } from '../../utils/vehicleAttendance';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Table from 'react-bootstrap-table-next';
import {
  customSizePerPage,
  customTotal,
} from '../../components/common/parcelStatusPages';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import ExcelDownloadReport from '../../components/common/excel/download';
import EmptyTable from '../../components/common/EmptyTableView';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinnerState } from '../../actions/spinner';
import { updateToastInfo } from '../../actions/settings';
import DateRangeSelector from '../../components/common/DateRangeSelector';
import DateMonthSelector from '../../components/common/dateMonthSelector';
import { getPaymentsReport } from '../../parse-functions/reports';
import { getVendorsListInRegion } from '../../parse-functions/vendors';
import { setVendorsListInStore } from '../../actions/vendors';
import { convertArrayToObj } from '../../utils/json';
import EditableDropDown from '../../components/common/EditableDropdown';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';

const PaymentReports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [vendorOptions, setVendorOptions] = useState({});
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const [initialPage, setInitialPage] = useState(1);
  const configData = useSelector((state) => state?.user?.config);
  const initialFormInput = {
    filterType: 'Month',
    vendor: '',
    vehicleNumber: '',
    zone: '',
  };
  const [formInput, setFormInput] = useState(initialFormInput);
  const [data, setData] = useState([]);

  const breadCrumbItems = [
    {
      title: t('Reports'),
      link: Routes.Reports.DailyDelivery.path,
    },
    {
      title: t('Payment Reports'),
      link: '',
    },
  ];

  const handleGetAvailableAnalyticsData = async (requestProps) => {
    dispatch(updateSpinnerState(true));
    try {
      const dataList = await getPaymentsReport(requestProps);
      setDataToDownload(dataList);
      setData(dataList);
      // use the data here and generate the table data and the
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('All data fetched'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error in getting active analytics data'),
          message: e.message,
        })
      );
    }
  };
  const handleFilterSubmit = (startDate, endDate) => {
    setInitialPage(1);
    handleGetAvailableAnalyticsData({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      ...formInput,
    });
  };

  const handlePageChange = (page) => {
    setInitialPage(page);
  };

  useEffect(() => {
    const fetchVendor = async () => {
      if (Object.keys(vendorsList).length > 0) {
        const vendorObj = {};
        Object.values(vendorsList).forEach(
          (item) => (vendorObj[item['vendorName']] = item['objectId'])
        );
        setVendorOptions(vendorObj);
        return;
      }
      dispatch(updateSpinnerState(true));
      try {
        const filters = {
          status: 'active',
          getAllVendors: true,
        };
        let vendors = await getVendorsListInRegion(filters);
        dispatch(
          setVendorsListInStore(
            convertArrayToObj(JSON.parse(JSON.stringify(vendors)), 'objectId')
          )
        );
        vendors = JSON.parse(JSON.stringify(vendors));
        const vendorObj = vendors?.reduce((acc, row) => {
          acc[row['vendorName']] = row['objectId'];
          return acc;
        }, {});
        setVendorOptions(vendorObj);
        dispatch(updateSpinnerState(false));
      } catch (e) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('Error'),
            message: t(e.message),
          })
        );
      }
    };
    fetchVendor();
  }, []);
  return (
    <ToolkitProvider
      exportCSV
      keyField="id"
      search={true}
      sort
      columns={vehiclePaymentsReportColumns()}
      data={data}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <Paginator.PaginationProvider
          pagination={Pagination({
            custom: true,
            showTotal: true,
            alwaysShowAllBtns: true,
            totalSize: data?.length,
            page: initialPage,
            onPageChange: handlePageChange,
            paginationTotalRenderer: customTotal,
            sizePerPageRenderer: customSizePerPage,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <BreadCrumbComponent items={breadCrumbItems} />
                  <h4>{t('Vehicle Payments Report')}</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '4px',
                      }}
                    >
                      <Button
                        variant={showFilterOptions ? 'primary' : 'white'}
                        onClick={() => {
                          setShowFilterOptions((prev) => !prev);
                        }}
                        style={{
                          width: '150px',
                          height: '38px',
                          borderRadius: 0,
                        }}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faSliders} />
                        &nbsp;&nbsp;{t('Filter')}
                      </Button>
                      <ButtonGroup>
                        <ExcelDownloadReport
                          data={dataToDownload || []}
                          fileName={'vehicle-payments'}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
              {showFilterOptions && (
                <>
                  <div
                    className="table-settings mt-4 mb-4 p-3"
                    style={{
                      background: 'white',
                      transition: 'max-height 0.7s ease-in-out',
                    }}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '20px',
                        width: '100%',
                        backgroundColor: 'white',
                      }}
                    >
                      <Form.Group>
                        <Form.Label>Date Type</Form.Label>
                        <EditableDropDown
                          onChange={(e) =>
                            setFormInput((prev) => ({
                              ...prev,
                              filterType: e.value,
                            }))
                          }
                          options={[
                            { label: 'Month', value: 'Month' },
                            { label: 'Day', value: 'Day' },
                          ]}
                          placeholder={'Select an Option'}
                          value={formInput['filterType']}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Vendor Name</Form.Label>
                        <EditableDropDown
                          id={'vendor'}
                          onChange={(e) =>
                            setFormInput((prev) => ({
                              ...prev,
                              vendor: e.value,
                            }))
                          }
                          backgroundColor="#eaedf2"
                          value={formInput['vendor']}
                          options={arrayToDropdownOptions(
                            Object.keys(vendorOptions)
                          )}
                          placeholder={'Select Vendor Name'}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Vehicle Number</Form.Label>
                        <Form.Control
                          style={{
                            height: 38,
                            width: '100%',
                            backgroundColor: '#eaedf2',
                          }}
                          type="text"
                          value={formInput['vehicleNumber']}
                          onChange={(e) => {
                            e.persist();
                            setFormInput((prev) => ({
                              ...prev,
                              vehicleNumber: e.target.value,
                            }));
                          }}
                          placeholder="Enter the Vehicle No."
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Zone</Form.Label>
                        <EditableDropDown
                          onChange={(e) =>
                            setFormInput((prev) => ({
                              ...prev,
                              zone: e.value,
                            }))
                          }
                          options={arrayToDropdownOptions(
                            Object.keys(configData?.zones)
                          )}
                          placeholder={'Select an Option'}
                          value={formInput['zone']}
                        />
                      </Form.Group>
                      <Form.Group className="d-flex align-items-end">
                        {formInput.filterType === 'Day' ? (
                          <DateRangeSelector onSubmit={handleFilterSubmit} />
                        ) : (
                          <DateMonthSelector onSubmit={handleFilterSubmit} />
                        )}
                      </Form.Group>
                    </div>
                    <div
                      style={{
                        gridColumn: 'span 3', // Span across all columns
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px',
                      }}
                    >
                      <Button
                        variant="white"
                        onClick={() => setFormInput(initialFormInput)}
                        style={{
                          width: '150px',
                          height: '38px',
                          borderRadius: 0,
                          background: '#EAEDF2',
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowRotateLeft} />
                        &nbsp;&nbsp;{t('Reset')}
                      </Button>
                    </div>
                  </div>
                </>
              )}
              <Card>
                <div className="table-responsive py-2">
                  <Table
                    noDataIndication={<EmptyTable />}
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-0"
                    classes="table-flush dataTable-table"
                  />

                  <Card.Footer>
                    <Row>
                      <Col xs={12} md={4} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            </>
          )}
        </Paginator.PaginationProvider>
      )}
    </ToolkitProvider>
  );
};
export default PaymentReports;
