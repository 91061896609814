import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../../actions/settings';
import { updateSpinnerState } from '../../../../actions/spinner';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import {
  getAllSchemes,
  updateSchemesStatus,
} from '../../../../parse-functions/sms/scheme';
import SchemeInfoModal from './SchemeInfoModal';
import { infoTable } from '../../../../utils/sms/infoTable';
import { useNavigate } from 'react-router-dom';
import BulkAddSchemeModal from '../BulkAddSchemeModal';
import { setSchemesDataInStore } from '../../../../actions/sms/schemes';
import TableOnly from '../IndividualScheme/TableOnly';
import {
  schemeTableRedesignColumns,
  ViewProductsTable,
} from '../../../../utils/sms/schemeTableRedesign';
import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import ExcelDownloadReport from '../../../../components/common/excel/download';
import {
  faBan,
  faRefresh,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadCrumbTitle from '../common/BreadCrumbTitle';
import { StyledSearchBar } from '../../../trips/styles';
import { setPageNumberInStore } from '../../../../actions/tablePage';
import { exportSchemeDataSummary } from '../../../../utils/sms/exportSchemeData';

const DraftSchemes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.user.user.username || {});

  const isBrandManager = useSelector(
    (state) => state?.user?.preferences?.isBrandManager || false
  );

  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );

  const isBrandVendor = useSelector(
    (state) => state?.user?.preferences?.isBrandVendor || false
  );

  const role = isAdmin
    ? 'isAdmin'
    : isBrandManager
    ? 'isBrandManager'
    : isBrandVendor
    ? 'isBrandVendor'
    : 'Unknown';

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateMode, setUpdateMode] = useState('');
  const [schemeData, setSchemeData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoData, setInfoData] = useState();
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [id, setId] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const schemes = useSelector((state) => state.schemes || {});

  const filteredData = schemeData.filter((row) => {
    return Object.values(row).some((value) =>
      value?.toString()?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const fetchDraftSchemes = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await getAllSchemes({
        status: ['draft'],
        role: role,
      });
      setSchemeData(response);
      dispatch(setSchemesDataInStore({ type: 'draft', data: response }));
    } catch (error) {
      console.log(error);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot fetch at the moment!',
          message: 'Please try again!',
        })
      );
    } finally {
      dispatch(updateSpinnerState(false));
    }
  };

  const onRefreshClick = async () => {
    await fetchDraftSchemes();
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSelectedRowUpdate = (actionId, row) => {
    switch (actionId) {
      case 'Accept': {
        setUpdateMode('Accept');
        setConfirmationModalProps([
          {
            headerIcon: <FontAwesomeIcon icon={faCircleCheck} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Reject': {
        setUpdateMode('Reject');
        setConfirmationModalProps([
          {
            type: 'textArea',
            title: 'Remarks',
            value: '',
            placeholder: 'Enter Remarks',
          },
          {
            headerIcon: <FontAwesomeIcon icon={faBan} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Negotiate': {
        setUpdateMode('Negotiate');
        setConfirmationModalProps([
          {
            type: 'textArea',
            title: 'Remarks',
            value: '',
            placeholder: 'Enter Remarks',
          },
          {
            headerIcon: <FontAwesomeIcon icon={faRotateLeft} />,
          },
        ]);
        setId(row.objectId);
        handleShowDeleteModal();
        break;
      }
      case 'Edit': {
        navigate(`/scheme/${row.objectId}/edit`);
        break;
      }
      case 'View': {
        dispatch(setSchemesDataInStore({ type: 'view', data: row }));
        navigate(`/scheme/${row.objectId}/details`);
        break;
      }
      case 'Action Timeline': {
        setInfoData(row.actionLog);
        setShowInfoModal(true);
      }
      default: {
        break;
      }
    }
  };
  const handleOnConfirmClick = async (data) => {
    try {
      dispatch(updateSpinnerState(true));
      await updateSchemesStatus(data);
      await fetchDraftSchemes();
      setId();
      setConfirmationModalProps([]);
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Action Successful`,
          message: '',
        })
      );
      setShowDeleteModal(false);
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Action cannot be done!',
          message: 'Please try again',
        })
      );
    }
  };
  const handleModalOnConfirmClick = () => {
    const selectedSchemeData = {
      schemeId: [id],
      userName: userName,
      status:
        updateMode === 'Accept'
          ? 'ongoing'
          : updateMode === 'Reject'
          ? 'rejected'
          : 'draft',
      actionType: updateMode === 'Negotiate' ? 'negotiation' : '',
      reason: confirmationModalProps[0]?.value,
    };
    handleOnConfirmClick(selectedSchemeData);
  };

  const handleMoreProductsClick = (id) => {
    setId(id);
    setShowProductsModal(true);
  };

  useEffect(() => {
    if (role !== 'Unknown' && schemes.draft.length === 0) {
      fetchDraftSchemes();
    } else if (schemes.draft.length > 0) {
      setSchemeData(schemes.draft);
    }
  }, [role]);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <BreadCrumbTitle breadcrumbs={[{ title: 'Draft Schemes' }]} />
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 gap-3">
          <StyledSearchBar
            width={'360.89px'}
            height={'46.78px'}
            type={'text'}
            value={searchTerm}
            placeholder={'Search value in the table'}
            onChange={(e) => {
              dispatch(
                setPageNumberInStore({ id: 'draftSchemesTable', page: 1 })
              );
              setSearchTerm(e.target.value);
            }}
          />
          <ButtonGroup className="gap-3">
            <Button
              onClick={onRefreshClick}
              variant="white"
              className="btn-export "
              size="md"
            >
              <FontAwesomeIcon icon={faRefresh} />
              &nbsp;&nbsp;{t('Refresh')}
            </Button>
            <ExcelDownloadReport
              data={exportSchemeDataSummary(schemeData) || []}
              fileName={`Draft Schemes`}
            />
          </ButtonGroup>
        </div>
      </div>
      <TableOnly
        data={filteredData || []}
        columns={schemeTableRedesignColumns({
          handleMoreProductsClick,
          options: [
            'Action Timeline',
            'View',
            'Edit',
            'Accept',
            'Reject',
            'Negotiate',
          ],
          handleSelectedRowUpdate,
        })}
        id={'draftSchemesTable'}
      />

      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
          setConfirmationModalProps([]);
          setId('');
        }}
        modalBody={t(`Are you sure you want to ${updateMode?.toLowerCase()}?`)}
        modalTitle={t(`${updateMode} Scheme(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
      <SchemeInfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        data={infoData}
        columns={infoTable()}
      />
      {showProductsModal && (
        <BulkAddSchemeModal
          data={
            schemeData?.filter((item) => item.objectId === id)[0]?.products ||
            []
          }
          showModal={showProductsModal}
          closeModal={() => setShowProductsModal(false)}
          columns={ViewProductsTable(
            schemeData?.filter((item) => item.objectId === id)[0]
          )}
        />
      )}
    </>
  );
};
export default DraftSchemes;
