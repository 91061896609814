import { faHome, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Form,
  Row,
} from '@themesberg/react-bootstrap';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../actions/settings';
import { updateSpinnerState } from '../../actions/spinner';
import { returnValidationErrorMessages } from '../../utils/validation';
import { getVendorsListInRegion } from '../../parse-functions/vendors';
import DropdownComponent from '../../components/common/EditableDropdown';
import {
  isValidVehicleNumber,
  returnVehicleTypesOptions,
  returnVendorDropdownOptions,
} from '../../utils/vehicles';
import { setVendorsListInStore } from '../../actions/vendors';
import { convertArrayToObj } from '../../utils/json';
import { addNewDriverVehicle } from '../../parse-functions/vehicles';
import { returnVehiclePaymentModeOptions } from './vehicleUtils';
import DatePicker from '../tripPayments/DatePicker';

const addVehicleSchema = Joi.object({
  vehicleNumber: Joi.string()
    .required()
    .custom(isValidVehicleNumber, 'Vehicle Number format is incorrect'),
  driverName: Joi.string(),
  vehicleType: Joi.string(),
  contactNumber: Joi.number().required().greater(1000000000).less(9999999999),
  vendorName: Joi.string().required(),
  vendorObjectId: Joi.string().required(),
  paymentMode: Joi.string().required(),
  paymentAmount: Joi.when('paymentMode', {
    is: Joi.valid('extraVehicle', 'monthlyFixed'),
    then: Joi.number().required().greater(1),
  }),
});

const editVehicleSchema = Joi.object({
  vehicleNumber: Joi.string()
    .required()
    .custom(isValidVehicleNumber, 'Vehicle Number format is incorrect'),
  driverName: Joi.string(),
  vehicleType: Joi.string(),
  contactNumber: Joi.number().required().greater(1000000000).less(9999999999),
  vendorName: Joi.string().required(),
  paymentMode: Joi.string().required(),
  paymentAmount: Joi.when('paymentMode', {
    is: Joi.valid('extraVehicle', 'monthlyFixed'),
    then: Joi.number().required().greater(0),
  }),
  fromDate: Joi.date(),
  toDate: Joi.date().min(Joi.ref('fromDate')).messages({
    toDate: '"toDate" must be greater than or equal to "fromDate"',
  }),
}).options({
  allowUnknown: true,
});

const AddVehicle = (props) => {
  const vehicleObject = {
    vehicleNumber: '',
    driverName: '',
    contactNumber: '',
    vehicleType: '',
    vendorName: '',
    paymentMode: '',
    paymentAmount: '',
    fromDate: '',
    toDate: '',
  };
  const {
    edit = false,
    vehicleInfo = vehicleObject,
    onVehicleEditConfirm,
  } = props;
  const { t } = useTranslation();
  const zone = useSelector((state) => state.user.warehouse.zone || '');
  const dispatch = useDispatch();
  const configData = useSelector((state) => state.user.config || {});
  const vendorsList = useSelector((state) => state?.vendors?.vendorsList || {});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(vehicleInfo);
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  useEffect(() => {
    if (isAdmin || (Object.keys(vendorsList) < 1 && zone)) {
      getAvailableVendorList();
    }
  }, [zone]);
  const onFormInputChange = (fieldName, value) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [fieldName]: value,
    }));
  };
  const handleFormSubmit = async () => {
    const schemaName = edit ? editVehicleSchema : addVehicleSchema;
    const { error: formErrors = {}, value: formValue } = schemaName.validate(
      formData,
      {
        abortEarly: false,
        allowUnknown: true,
      }
    );
    const { details = {} } = formErrors;
    if (details?.length > 0) {
      const validationErrorMessages = returnValidationErrorMessages(details);
      setFormErrors(validationErrorMessages);
    } else {
      dispatch(updateSpinnerState(true));
      try {
        if (edit) {
          onVehicleEditConfirm(formValue);
        } else {
          if (!formValue.zone) {
            formValue.zone = zone;
          }
          await addNewDriverVehicle(formValue);
        }
        if (!edit) {
          dispatch(
            updateToastInfo({
              show: true,
              type: 'success',
              title: t(`Vehicle Added'}`),
              message: t(`${formData.vehicleNumber} added successfully`),
            })
          );
        }

        setFormErrors({});
      } catch (error) {
        // inside error
        const { message, code } = error;
        setFormErrors(message);
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t(`Vehicle ${edit ? 'Edit' : 'Addition'} Failed`),
            message: t(
              `${formData.vehicleNumber} failed to ${edit ? 'edited' : 'added'}`
            ),
          })
        );
      }
      dispatch(updateSpinnerState(false));
    }
  };

  const getAvailableVendorList = async () => {
    dispatch(updateSpinnerState(true));
    try {
      const filters = {
        zone,
        status: 'active',
        getAllVendors: isAdmin && true,
      };
      const vendors = await getVendorsListInRegion(filters);
      dispatch(
        setVendorsListInStore(
          convertArrayToObj(JSON.parse(JSON.stringify(vendors)), 'objectId')
        )
      );
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t('Vehicle Vendors fetched'),
          message: t('All the Vehicle Vendors for the zone are listed here'),
        })
      );
    } catch (e) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Error'),
          message: t(e.message),
        })
      );
    }
  };
  return (
    <div className="mt-1">
      {!edit && (
        <div className="mb-4 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          <div className="d-block mb-4 mb-md-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: 'breadcrumb-dark breadcrumb-transparent',
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('Vehicle')}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t('Add')}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{t('Add New Vehicle')}</h4>
            <p className="mb-0">{t('Use this page to add a new vehicle')}</p>
          </div>
        </div>
      )}

      <Row className="mt-3 d-flex justify-content-center">
        <Col md={6}>
          <Alert variant="info">
            <FontAwesomeIcon icon={faTruck} />
            &nbsp;&nbsp;{t('Vehicle & Vendor Info')}
          </Alert>
          <Form.Group className="mb-3 mt-4">
            <Form.Label>{t('Vehicle Number')}</Form.Label>
            <Form.Control
              value={formData.vehicleNumber}
              required
              disabled={edit}
              isInvalid={formErrors['vehicleNumber']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('vehicleNumber', event.target.value);
              }}
            />
            {formErrors['vehicleNumber']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter vehicle number.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('Driver Name')}</Form.Label>
            <Form.Control
              value={formData.driverName}
              isInvalid={formErrors['driverName']?.length}
              type="text"
              onChange={(event) => {
                onFormInputChange('driverName', event.target.value);
              }}
            />
            {formErrors['driverName']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please enter driver Name.')}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('Driver Contact')}</Form.Label>
            <Form.Control
              value={formData.contactNumber}
              isInvalid={formErrors['contactNumber']?.length}
              type="number"
              onChange={(event) => {
                onFormInputChange('contactNumber', event.target.value);
              }}
            />
            {formErrors['contactNumber']?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors['contactNumber'])}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label
              className={
                formErrors['vehicleType']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Choose Vehicle Type')}
            </Form.Label>
            <DropdownComponent
              value={formData.vehicleType}
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  vehicleType: option.value,
                }));
              }}
              options={returnVehicleTypesOptions(configData?.vehicleTypes)}
              placeholder={t('Choose Vehicle Type')}
            />
          </Form.Group>
          {!(edit && !isAdmin) && (
            <Form.Group className="mb-3">
              <Form.Label
                className={
                  formErrors['vendorName']?.length > 0 ? 'text-danger' : ''
                }
              >
                {t('Choose Vendor Name')}
              </Form.Label>
              <DropdownComponent
                value={formData.vendorName}
                valueKey={'label'}
                onChange={(option) => {
                  setFormData((oldData) => ({
                    ...oldData,
                    vendorName: option.value?.vendorName,
                    vendorObjectId: option.value?.objectId,
                  }));
                }}
                options={returnVendorDropdownOptions(
                  Object.values(vendorsList)
                )}
                placeholder={t('Choose Vendor Name')}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label
              className={
                formErrors['paymentMode']?.length > 0 ? 'text-danger' : ''
              }
            >
              {t('Choose Payment Mode')}
            </Form.Label>
            <DropdownComponent
              value={formData.paymentMode}
              onChange={(option) => {
                setFormData((oldData) => ({
                  ...oldData,
                  paymentMode: option.value,
                }));
              }}
              options={returnVehiclePaymentModeOptions()}
              placeholder={t('Choose Payment Mode')}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Enter Payment Amount')}</Form.Label>
            <Form.Control
              value={formData.paymentAmount}
              isInvalid={formErrors['paymentAmount']?.length}
              type="number"
              onChange={(event) => {
                onFormInputChange('paymentAmount', event.target.value);
              }}
            />
            {formErrors['paymentAmount']?.length && (
              <Form.Control.Feedback type="invalid">
                {t(formErrors['paymentAmount'])}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('Zone')}</Form.Label>
            <Form.Select
              disabled={!isAdmin}
              onChange={(event) => {
                onFormInputChange('zone', event.target.value);
              }}
              value={formData.zone}
            >
              {isAdmin ? (
                <>
                  {Object.keys(configData?.zones).map((item) => (
                    <option>{item}</option>
                  ))}
                </>
              ) : (
                <option>{zone}</option>
              )}
            </Form.Select>
            {formErrors['zone']?.length && (
              <Form.Control.Feedback type="invalid">
                {t('Please choose a zone')}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {edit && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>{t(`Update Amount - From Date`)}</Form.Label>
                <DatePicker
                  value={formData.fromDate}
                  setValue={(date) => {
                    onFormInputChange('fromDate', date);
                  }}
                  isInvalid={formErrors.fromDate}
                />
                {formErrors['fromDate'] && (
                  <Form.Control.Feedback type="invalid">
                    {t('Please choose From Date')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t(`Update Amount - To Date`)}</Form.Label>
                <DatePicker
                  value={formData.toDate}
                  setValue={(date) => {
                    onFormInputChange('toDate', date);
                  }}
                  isInvalid={formErrors.toDate}
                />
                {formErrors.toDate && (
                  <Form.Control.Feedback type="invalid">
                    {t(formErrors.toDate)}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </>
          )}

          <Button
            style={{ width: '98%' }}
            className="mx-2 mt-4"
            variant="primary"
            onClick={handleFormSubmit}
          >
            {t(`${edit ? 'Edit' : 'Add'} Vehicle`)}
          </Button>
          <Button
            style={{ width: '98%' }}
            className="mx-2 mt-3"
            variant="outline"
          >
            {t('Cancel')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default AddVehicle;
