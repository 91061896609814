import React, { useEffect, useState } from 'react';
import OrderListPage from '../../../components/common/OrderListPage';
import { orderTableColumns } from '../orders/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateToastInfo } from '../../../actions/settings';
import { updateSpinnerState } from '../../../actions/spinner';
import { apiCall } from '../../../middlewares/api';
import { apiCallConsts } from '../../../constants/apiCallConsts';
import arrayToBreadCrumbs from '../../../utils/sms/arrayToBreadCrumbs';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/common/confirmationModal';
import { useNavigate } from 'react-router-dom';
import { orderStatus } from '../../../constants/orderStatus';
import OrderDetails from '../orders/components/OrderDetails';
import { Tab, Tabs } from '@themesberg/react-bootstrap';

const Pending = () => {
  const { t } = useTranslation();
  const type = 'Pending';
  const storeName = useSelector(
    (state) => state?.user?.preferences?.storeName || ''
  );
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadCrumbItems = arrayToBreadCrumbs([
    ['Customer Pickup'],
    ['Pending'],
  ]);

  const bulkActionOptions = [
    {
      label: 'Update',
      value: 'update',
    },
  ];

  const messageDisplayer = (type, title, message) => {
    dispatch(
      updateToastInfo({
        show: true,
        type: type,
        title: t(title),
        message: t(message),
      })
    );
  };

  const onRowSelect = (selected, objectId) => {
    if (selected) {
      setSelectedOrders((prev) => {
        const selectedItem = ordersData.find(
          (item) => item.objectId === objectId
        );
        return [...prev, selectedItem];
      });
    } else {
      setSelectedOrders((prev) =>
        prev.filter((item) => item.objectId !== objectId)
      );
    }
    setOrdersData((prev) =>
      prev.map((item) =>
        item.objectId === objectId ? { ...item, isSelected: selected } : item
      )
    );
  };

  const handleSelectedRowsUpdate = (actionId) => {
    if (selectedOrders.length > 0) {
      switch (actionId) {
        case 'update':
          setConfirmationModalProps([
            {
              type: 'text',
              title: 'Invoice No',
              value: '',
            },
            {
              type: 'text',
              title: 'Serial No',
              value: '',
            },
          ]);
          setShowDeleteModal(true);
          break;
        default:
          break;
      }
    }
  };

  const handleModalOnConfirmClick = async () => {
    const selectedOrdersData = {
      id: selectedOrders.map((order) => order.objectId),
      status: 'Completed',
      invoiceNo: confirmationModalProps[0].value,
      serialNo: confirmationModalProps[1].value,
    };
    try {
      dispatch(updateSpinnerState(true));
      await apiCall(
        apiCallConsts.PUT_METHOD,
        apiCallConsts.ORDER_UPDATE_URL,
        selectedOrdersData
      );
      await fetchOrders();
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: `Update Successful!`,
          message: '',
        })
      );
      setShowDeleteModal(false);
      setSelectedOrders([]);
      setConfirmationModalProps([]);
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: 'Cannot be updated!',
          message: error.message,
        })
      );
    }
  };

  const [activeTab,setActiveTab] = useState("Orders List")
  const [tabsToRender,setTabsToRender] = useState([])

  const [tabs, setTabs] = useState([
    { key: 'Orders List', title: 'Order Listing', content: 'orders' },
    ...tabsToRender.map((each) => ({
      key: `Details-${each.objectId}`,
      title: `Details - ${each.orderNo}`,
      content: 'details',
      objectId: each.objectId,
    })),
  ]);
  useEffect(() => {
    setTabs([
      { key: 'Orders List', title: 'Order List', content: 'orders' },
      ...tabsToRender.map((each) => ({
        key: `Details-${each.objectId}`,
        title: `Details - ${each.orderNo}`,
        content: 'details',
        objectId: each.objectId,
      })),
    ])

  },[tabsToRender])
  const handleTabClose = (key) => {
    const updatedTabs = tabsToRender.filter((tab) => key !== `Details-${tab.objectId}`)
    
    setTabsToRender(updatedTabs);

    if (activeTab === key && updatedTabs.length > 0) {
      setActiveTab(updatedTabs[0].key);
    }
  };



  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const res = await apiCall(
        apiCallConsts.GET_METHOD,
        `/internal/order?storeCode=${storeName}&isCustomerPickup=true&status=${type}`
      );
      setOrdersData(res);
      dispatch(updateSpinnerState(false));
      messageDisplayer('success', '', 'Orders fetched successfully');
    } catch (error) {
      dispatch(updateSpinnerState(false));
      messageDisplayer('danger', 'Failed to fetch Orders', error.message);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchOrders();
    }
    if (storeName) {
      fetchOrders();
    }
  }, [storeName, isAdmin]);

  return (
    <>
<Tabs
  activeKey={activeTab}
  onSelect={(key) => setActiveTab(key)}
  className="mb-3"
  style={{
    display: 'flex',
    flexWrap: 'nowrap', // Prevent wrapping of tabs
    gap: '10px', // Adds spacing between tabs
    overflowX: 'auto', // Enables horizontal scrolling
    scrollbarWidth: 'thin', // For Firefox, makes the scrollbar thinner
    marginTop:"20px",
    background:"white"
  }}
>
  {tabs.map((tab) => (
    <Tab
      key={tab.key}
      eventKey={tab.key}
      title={
        <span style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
          {tab.title}
          {tab.title !== 'Order List' && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleTabClose(tab.key);
              }}
              style={{
                border: 'none',
                background: 'none',
                color: 'red',
                cursor: 'pointer',
                marginLeft: '5px',
              }}
            >
              ✖
            </button>
          )}
        </span>
      }
      style={{
        flexShrink: 0, // Prevents the tab from shrinking
      }}
    >
      {tab.content === 'orders' && (
         <OrderListPage
         pageType={`${type} orders`}
         tableData={ordersData}
         tableColumns={orderTableColumns(
           onRowSelect,
           navigate,
           bulkActionOptions,
           isAdmin,
           true,setTabsToRender, setActiveTab
         )}
         handleRefresh={() => fetchOrders()}
         pageTitle={t(`${type} Orders`)}
         breadCrumbItems={breadCrumbItems}
         enableBulkActions={selectedOrders.length > 0}
         hideBulkActions={!bulkActionOptions}
         bulkActionOptions={bulkActionOptions}
         handleItemBulkUpdate={handleSelectedRowsUpdate}
       />
      )}
      {tab.content === 'details' && <OrderDetails id={tab.objectId} />}
    </Tab>
  ))}
</Tabs>

<style>
{`
    /* Add inline styles for scrollbars using CSS */ 
    .mb-3::-webkit-scrollbar {
      height: 6px;
    }
    .mb-3::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
    .mb-3::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
</style>
    
      <ConfirmationModal
        showModal={showDeleteModal}
        onConfirm={handleModalOnConfirmClick}
        closeModal={() => {
          setShowDeleteModal(false);
          setConfirmationModalProps([]);
        }}
        modalBody={t('Are you sure want to change the status to Completed?')}
        modalTitle={t(`Completed Order(s)`)}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps}
      />
    </>
  );
};

export default Pending;
