import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateLeft,
  faFilter,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Button, Form, Tabs, Tab, Collapse } from '@themesberg/react-bootstrap';
import EditableDropDown from '../../../components/common/EditableDropdown';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import TableOnly from '../../scheme-management/Components/IndividualScheme/TableOnly';
import { returnWarehouseDropdownOptions } from '../../../utils/warehouse';
import { arrayToDropdownOptions } from '../../../utils/sms/arrayToDropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from '../../../middlewares/api';
import { t } from 'i18next';
import { updateSpinnerState } from '../../../actions/spinner';
import { updateToastInfo } from '../../../actions/settings';
import { setStoresInStore } from '../../../actions/oms/stores';
import { returnUserReadableDate } from '../../../utils/datetime';
import ExportButton from '../orders/components/ExportButton';
import { delayedOrdersColumn, getColumns } from '../orders/utils';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const DelayedOrdersAnalytics = () => {
  const [storeFilter, setStoreFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState([]);
  const [delayFilter, setDelayFilter] = useState(2);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('analytics');
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const itemsPerPage = 10;
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  const storeList = useSelector((state) => state?.stores?.storeList || []);
  const dispatch = useDispatch();
  const fetchOrders = async () => {
    try {
      dispatch(updateSpinnerState(true));
      const response = await apiCall(
        'get',
        `/internal/dashboard/delayed?store=${storeFilter}&region=${regionFilter.join(
          ','
        )}&delay=${delayFilter}`
      );
      setOrders(response?.orders || []);
      dispatch(
        updateToastInfo({
          show: true,
          type: 'success',
          title: t(''),
          message: t('orders fetched successfully!'),
        })
      );
      dispatch(updateSpinnerState(false));
    } catch (error) {
      dispatch(updateSpinnerState(false));
      dispatch(
        updateToastInfo({
          show: true,
          type: 'danger',
          title: t('Failed to Fetch The Data!'),
          message: t(error.message),
        })
      );
    }
  };

  const handleApplyFilters = () => {
    fetchOrders();
  };

  const handleReset = () => {
    setStoreFilter('');
    setRegionFilter([]);
    setDelayFilter(2);
    fetchOrders();
  };

  useEffect(() => {
    const fetchStore = async () => {
      try {
        dispatch(updateSpinnerState(true));
        const data = await apiCall('get', 'internal/store/all', {});
        dispatch(setStoresInStore(data));
        console.log(returnWarehouseDropdownOptions(data, false));
        dispatch(updateSpinnerState(false));
      } catch (error) {
        dispatch(updateSpinnerState(false));
        dispatch(
          updateToastInfo({
            show: true,
            type: 'danger',
            title: t('failed to fetch'),
            message: t(`${error.message}`),
          })
        );
      }
    };
    if (storeList.length === 0) {
      fetchStore();
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, []);

  const analytics = orders.reduce(
    (acc, order) => {
      acc.stores[order.storeCode] = (acc.stores[order.storeCode] || 0) + 1;
      acc.regions[order.zone] = (acc.regions[order.zone] || 0) + 1;
      acc.status[order.status] = (acc.status[order.status] || 0) + 1;

      acc.overall += 1;
      return acc;
    },
    { stores: {}, regions: {}, overall: 0, status: {} }
  );

  const storeChartData = {
    labels: Object.keys(analytics.stores),
    datasets: [
      {
        label: 'Delayed Orders by Store',
        data: Object.values(analytics.stores),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const statesChartData = {
    labels: Object.keys(analytics.status),
    datasets: [
      {
        label: 'Delayed Orders by Store',
        data: Object.values(analytics.status),
        backgroundColor: Object.values(analytics.status).map((_, index) => {
          // Generate a random color for each bar
          const colors = [
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
          ];
          return colors[index % colors.length]; // Cycle through the colors array
        }),
        borderColor: Object.values(analytics.status).map((_, index) => {
          const colors = [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ];
          return colors[index % colors.length]; // Cycle through the border colors
        }),
        borderWidth: 1,
      },
    ],
  };
  const regionChartData = {
    labels: Object.keys(analytics.regions),
    datasets: [
      {
        label: 'Delayed Orders by Region',
        data: Object.values(analytics.regions),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
        ],
      },
    ],
  };

  const overallChartData = {
    labels: ['Delayed Orders', 'On-Time Orders'],
    datasets: [
      {
        label: 'Overall Orders',
        data: [analytics.overall, orders.length - analytics.overall],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  return (
    <div
      className="mt-2 mb-4"
      style={{ backgroundColor: '#F5F8FB', padding: '20px' }}
    >
      <div className="mb-4">
        <div className="d-flex justify-content-between align-content-center text-center p-2 bg-white">
          <div className="fw-bold fs-5 mt-1">
            {t('Delayed Orders Overview')}
          </div>
          <div
            style={{ width: '15%' }}
            className="d-flex justify-content-between align-content-center text-center"
          >
            <ExportButton
              style={{ marginInline: '10px' }}
              data={orders}
              columns={[
                { field: 'Order Number', key: 'orderNo' },
                { field: 'Item Line No', key: 'itemLineNo' },
                {
                  field: 'Order Date and Time',
                  key: 'createdAt',
                },
                { field: 'Product Title', key: 'productTitle' },
                { field: 'Internal Status', key: 'status' },
                { field: 'Customer Status', key: 'orderStatus' },
                { field: 'VSPrice', key: 'vsPrice' },
                { field: 'Store Name', key: 'storeCode' },
                { field: 'Region', key: 'zone' },
                { field: 'Store Name', key: 'storeCode' },
                { field: 'Ewaybill No', key: 'ewayBillNo' },
                { field: 'Product Type', key: 'size' },
                { field: 'Customer Name', key: 'customerName' },
                { field: 'Transaction ID', key: 'paymentType' },
                { field: 'Payment Status', key: 'paymentStatus' },
                { field: 'Price', key: 'vsPrice' },
                { field: 'GST', key: 'igst' },
                {
                  field: 'Expected Delivery Date',
                  key: 'expectedDeliveryDate',
                },
                { field: 'AWB Number', key: 'awbNumber' },
                { field: 'Bank Name', key: 'bankName' },
                { field: 'HSN Number', key: 'hsn_number' },
                { field: 'Quantity', key: 'quantity' },
                { field: 'MRP', key: 'mrp' },
                { field: 'Order Amount', key: 'orderAmount' },
              ]}
              fileName={'delayedOrders'}
            />
            <Button
              variant="primary"
              onClick={() => setShowFilters(!showFilters)}
              aria-controls="filter-collapse"
              aria-expanded={showFilters}
            >
              <FontAwesomeIcon icon={faSliders} />
              &nbsp;&nbsp;{t('Filter')}
            </Button>
          </div>
        </div>

        <Collapse in={showFilters}>
          <div
            id="filter-collapse"
            className="mt-3"
            style={{ backgroundColor: 'white', padding: 20 }}
          >
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <Form.Label>Store</Form.Label>
                  <EditableDropDown
                    id={'StoreName'}
                    onChange={(e) => setStoreFilter(e.value)}
                    style={{ height: 38, width: '100%' }}
                    value={storeFilter}
                    options={
                      storeList?.length > 0
                        ? returnWarehouseDropdownOptions(storeList, false)
                        : []
                    }
                    placeholder={'Enter the Store Name'}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Label>Region</Form.Label>
                  <EditableDropDown
                    id={'Region'}
                    onChange={(e) => {
                      const values = e.find((each) => each.value === 'all')
                        ? [
                            ...new Set(storeList?.map((store) => store.zone)),
                          ].filter(Boolean)
                        : e.map((each) => each?.value);
                      setRegionFilter(values);
                    }}
                    style={{ height: 38, width: '100%' }}
                    value={regionFilter}
                    isMultiSelect={true}
                    options={arrayToDropdownOptions(
                      storeList?.length > 0
                        ? [
                            'all',
                            ...new Set(storeList?.map((store) => store.zone)),
                          ].filter(Boolean)
                        : []
                    )}
                    optionColor={'rgba(240, 240, 240, 1)'}
                    placeholder={'Enter the region name'}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Label>Delay (Days)</Form.Label>
                  <Form.Control
                    type="number"
                    value={delayFilter}
                    onChange={(e) =>
                      setDelayFilter(Math.max(0, Number(e.target.value)))
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={handleReset}
                  variant="white"
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    background: '#EAEDF2',
                    marginInline: '10px',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRotateLeft} /> &nbsp;&nbsp;
                  Reset
                </Button>

                <Button
                  onClick={handleApplyFilters}
                  variant="white"
                  style={{
                    width: '150px',
                    height: '38px',
                    borderRadius: 0,
                    border: '1px solid #262B40',
                    marginInline: '10px',
                  }}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faSliders} /> &nbsp;&nbsp; Apply
                  Filters
                </Button>
              </div>
            </Form>
          </div>
        </Collapse>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={setActiveTab}
        className="mb-3"
        style={{ backgroundColor: 'white' }}
      >
        <Tab
          eventKey="analytics"
          title="Analytics"
          style={{ backgroundColor: '#F5F8FB' }}
        >
          <div className="row" style={{ backgroundColor: '#F5F8FB' }}>
            <div className="col-md-4">
              <Bar
                data={storeChartData}
                options={{ responsive: true, maintainAspectRatio: false }}
                style={{ backgroundColor: 'white', padding: '10px' }}
              />
            </div>
            <div className="col-md-4">
              <Pie
                data={regionChartData}
                style={{ backgroundColor: 'white', padding: '10px' }}
              />
            </div>
            <div className="col-md-4">
              <Bar
                data={statesChartData}
                options={{ responsive: true, maintainAspectRatio: false }}
                style={{ backgroundColor: 'white', padding: '10px' }}
              />
            </div>
          </div>
        </Tab>

        <Tab eventKey="table" title="Delayed Orders">
          <TableOnly
            id="OrderAnalytics"
            data={orders}
            columns={delayedOrdersColumn(navigate)}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default DelayedOrdersAnalytics;
