import React, { useState } from 'react';
import OrderPage from './components/OrderPage';
import { orderStatus } from '../../../constants/orderStatus';
import { useSelector } from 'react-redux';
import { convertExcelDateToJSDate } from './utils';
import { apiCall } from '../../../middlewares/api';

const PickedUp = () => {
  const isAdmin = useSelector(
    (state) => state?.user?.preferences?.isAdmin || false
  );
  const roles = useSelector((state) => state?.user?.preferences?.roles || []);
  const username = useSelector((state) => state?.user?.user?.username || '');

  const bulkActionOptions = [
    {
      label: 'Delivered',
      value: 'delivered',
    },
  ];

  const defaultConfirmationProps = {
    delivered: [
      {
        type: 'date',
        title: 'Delivered Date',
        value: '',
      },
      {
        type: 'dropdown',
        title: 'Self Pick Up',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
        value: 'no',
      },
    ],
  };

  const uploadButtonProps = {
    url : 'https://vs-system-templates.s3.ap-south-1.amazonaws.com/Delivered.xlsx',
    text : 'Delivered Bulk Orders',
    infoModalTitle : 'Delivered Orders',
    targetStatus : "Delivered",
    handleSubmit :  async (data) => {

      try{
         const toCallData = data.map((each) => {
          return {
            orderNo : each?.OrderNo || each?.orderNo,
            itemLineNo : each?.ItemLineNo || each?.itemLineNo,
            remarks : each?.Remarks,
            deliveredDate : convertExcelDateToJSDate(each?.DeliveredDate || each?.deliveredDate )
         }
         })
         await apiCall('put', `/internal/order/update/bulk`, {
          status : "Delivered",
          data : toCallData,username
        });
      }catch(error)
      {
       throw new Error(error.message)
      }
    
      }
  }


 

  const isAllowed =
    isAdmin || roles.includes('admin') || roles.includes('superAdmin');
  const [confirmationModalProps, setConfirmationModalProps] = useState([]);

  return (
    <div>
      <OrderPage
        type={orderStatus.pickedUp}
        bulkActionOptions={isAllowed && bulkActionOptions}
        defaultConfirmationProps={defaultConfirmationProps}
        confirmationModalProps={confirmationModalProps}
        setConfirmationModalProps={setConfirmationModalProps} uploadButtonProps={uploadButtonProps}
      />
    </div>
  );
};

export default PickedUp;
