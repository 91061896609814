import React, { useState, useEffect } from 'react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';

function AttentionBanner({ currentApplication }) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const config = useSelector(
    (state) => state.user.config.applicationBannerObj || ''
  );

  // Array of messages to display
  const messages = config[currentApplication?.value] || [];

  // Change message every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 10000); // 5000ms = 5 seconds

    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    <>
      {messages.length > 0 && (
        <Container
          fluid
          className="d-flex align-items-center border py-2 px-3"
          style={{
            backgroundColor: 'red', // Set the background color to red
            color: '#ffffff', // Set the text color to white
          }}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faExclamationCircle} />
            &nbsp;&nbsp;
            <strong>Attention:</strong>
          </div>
          <div
            className="w-100 ms-3"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              animation: 'scrollText 15s linear infinite',
              color: '#ffffff', // Ensure text color is white
            }}
          >
            {messages[currentMessageIndex]}
          </div>
        </Container>
      )}
    </>
  );
}

export default AttentionBanner;
