import React from 'react';
import { Button, Image, Modal } from '@themesberg/react-bootstrap';

const ImageOnlyModal = ({ showModal, closeModal, imageUrl }) => {
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModal}
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header className="d-flex justify-content-end">
        {/* <div className="d-flex justify-content-end"> */}
        <Button variant="close" aria-label="Close" onClick={closeModal} />
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center m-2">
          {imageUrl ? (
            <Image src={imageUrl} alt="Image" />
          ) : (
            <div>{'No Image Found'}</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageOnlyModal;
