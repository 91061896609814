import React from 'react';
import { arrayToDropdownOptions } from '../../utils/sms/arrayToDropdownOptions';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import EditableDropDown from '../../components/common/EditableDropdown';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const TripSaveModal = (props) => {
  const {
    show,
    handleClose,
    tripDetails,
    vendorsList,
    saveData,
    setSaveData,
    onSubmit,
    setShowEditModal,
  } = props;

  const { t } = useTranslation();
  return (
    <Modal show={show} centered onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Trip Details - {tripDetails?.trip?.tripNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex gap-2 justify-content-between mb-2">
          <Form.Group className="w-100">
            <Form.Label>Vehicle Number</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              type="text"
              value={tripDetails?.trip?.vehicleNumber}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="w-100">
            <Form.Label>Vendor Name</Form.Label>
            <EditableDropDown
              id={'vendor'}
              onChange={(e) =>
                setSaveData((prev) => ({
                  ...prev,
                  vendorName: e.value,
                }))
              }
              backgroundColor="#eaedf2"
              value={saveData['vendorName']}
              options={arrayToDropdownOptions(
                Object.values(vendorsList).map((item) => item.vendorName)
              )}
              placeholder={'Select Vendor Name'}
            />
          </Form.Group>
          <Form.Group className="w-100">
            <Form.Label>Driver Name</Form.Label>
            <Form.Control
              style={{
                height: 38,
                width: '100%',
                backgroundColor: '#eaedf2',
              }}
              type="text"
              value={saveData['driverName']}
              onChange={(e) => {
                e.persist();
                setSaveData((prev) => ({
                  ...prev,
                  driverName: e.target.value,
                }));
              }}
            />
          </Form.Group>
        </Form>
        <div>
          <h5 className="mb-2">Payment Details</h5>
          <div className="d-flex justify-content-between mb-1">
            <span>Payment Type</span>
            <span>{tripDetails?.trip?.driverInfo?.paymentMode}</span>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <span>Total Delivery Quantity</span>
            <span>{saveData.totalQuantity}</span>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <span>Trip Delivery Cost</span>
            <span>{saveData.totalAmount}</span>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <span>Extra Charges</span>
            <span>{saveData.extraCharges}</span>
          </div>
          {/* <div className="d-flex justify-content-between mb-2">
            <span>Payment Type</span>
            <span>{saveData.alreadyPaid}</span>
          </div> */}
          <div className="d-flex justify-content-between">
            <h5>Final Amount</h5>
            <span>{saveData.totalCost}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="text-gray"
          onClick={handleClose}
          style={{
            backgroundColor: '#F0F0F0',
            color: '#444444',
            border: 'None',
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
          &nbsp;&nbsp;
          {t('Cancel')}
        </Button>
        <Button
          style={{
            backgroundColor: '#444444',
            color: '#FFFFFF',
            border: 'None',
          }}
          onClick={() => {
            onSubmit(saveData);
            handleClose();
            setShowEditModal(false);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          &nbsp;&nbsp;
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TripSaveModal;
